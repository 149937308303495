import React from 'react';
import {Kitchen} from "../FabricCanvas"
import {Button, Col, Icon, Input, Row} from "react-materialize";
import {BaseEditor} from "./BaseEditor";
import ReactTooltip from "react-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {fabric} from 'fabric';

import * as FontFaceObserver from 'fontfaceobserver';

const fontFamilies = [
	{"Times New Roman": {name: "Times New Roman", needs_preload: false}},
	{"Arial": {name: "Arial", needs_preload: false}},
	{"Courier": {name: "Courier", needs_preload: false}},
	{"Sans Serif": {name: "Sans Serif", needs_preload: false}},
	{"Orbitron": {name: "Orbitron", needs_preload: true}},
	{"Pacifico": {name: "Pacifico", needs_preload: true}},
	{"Rammetto One": {name: "Rammetto One", needs_preload: true}},
	{"VT323": {name: "VT323", needs_preload: true}},
	{"Quicksand": {name: "Quicksand", needs_preload: true}},
	{"Inconsolata": {name: "Inconsolata", needs_preload: true}},
	{"Acme": {name: "Acme", needs_preload: true}}
];
let mapFontFamilies = fontFamilies.map((option,index) => {
	return (
		<option key={index} value={Object.keys(option)[0].name}>
			{Object.keys(option)[0]}
		</option>
	);
});
let fontSizes = [10,12,14,16,18,20,22,24,26,28,30];
let mapFontSizes = fontSizes.map((size) => {
	return (
		<option key={'k_'+size} value={size}>
			{size}
		</option>
	);
});
export const DEFAULT_TEXT = "Nuovo Testo";
export const DEFAULT_CURVED_TEXT = "Testo Curvato";

export class TextEditor extends BaseEditor {
	constructor(props) {
		super(props, ['i-text', 'curved-text']);
		this.modCurvedTxt = false;
		this.createCurved = this.createCurved.bind(this);
		this.isEditingCurved = this.isEditingCurved.bind(this);
	}

	onCreate() {
		if (this.props.joyrideRunning) {
			setTimeout(() => {
				this.props.addSteps([
					{
						title: 'Personalizza il tuo oggetto',
						text: "Rendi il tuo testo grassetto, corsivo, sottolineato, allineato come preferisci. Personalizza il colore o aggiungi un'ombra personalizzata. Quest'area è totalmente dedicata a questo.",
						selector: '#text-controls',
						position: 'left',
						allowClicksThruHole: false,
						style: {
							back: {
								display: 'none'
							}
						}

					}
				], () => this.props.joyride.next());
			}, 50);
		}

		Kitchen.Text().add(
			DEFAULT_TEXT, 'Arial', 20, '#000', 'left'
		);
	}
	createCurved() {
		Kitchen.Text().addCurved(
			DEFAULT_CURVED_TEXT, 'Arial', 20, '#000', 'left'
		);
	}

	isEditingCurved() {
		return this.state.editingObjects.every(obj => obj.type === 'curved-text')
	}

	onComponentMount(canvas) {
		// Subscribe to particular events for text
		let _this = this;
		let rep = null;
		fabric.util.addListener(canvas.upperCanvasEl, 'dblclick', function(e) {
			if (canvas.findTarget(e, false)) {
				const obj = canvas.findTarget(e, false);
				if (obj.type === 'curved-text') {
					rep = new fabric.IText(obj.text, {
						id: obj.id,
						fontFamily: obj.fontFamily,
						fontSize: obj.fontSize,
						left: obj.left,
						top: obj.top,
						fill: obj.fill,
						shadow: obj.shadow
					});
					canvas.remove(obj);
					canvas.add(rep);
					canvas.setActiveObject(rep);
					rep.enterEditing();
					for (let i = 0; i < String(rep.text).length; i++) rep.moveCursorRight(e);
					_this.modCurvedTxt = true;
					canvas.renderAll();
				}
			}
		});
		canvas.on({
			'text:editing:entered': (evt) => {
				let obj = evt.target;
				if (obj.get('type') === 'i-text') {
					if (obj.text === DEFAULT_TEXT) {
						obj.selectAll();
						obj.text = '';
						canvas.renderAll();
					}
				}
			},
			'text:editing:exited': (evt) => {
				let obj = evt.target;
				if (_this.modCurvedTxt && obj.type === 'i-text') {
					if (rep.text === '') {
						canvas.remove(rep).renderAll();
						return;
					}
					let curved = new fabric.CurvedText(rep.text, {
						id: rep.id,
						fontFamily: rep.fontFamily,
						fontSize: rep.fontSize,
						left: rep.left,
						top: rep.top,
						fill: rep.fill,
						shadow: rep.shadow
					});
					canvas.remove(rep);
					canvas.add(curved);
					_this.modCurvedTxt = false;
					BaseEditor.renderCanvasAndUpdate();
				}
			}
		});
	}

	onPaste(pasta) {
		// Pasta is a reference to the copied object.
		// Do not add it. Add a clone of it
		pasta.clone(cloned => {
			cloned.left = pasta.left + 10;
			cloned.top = pasta.top + 10;
			Kitchen.Text().addRaw(cloned);
		});
	}

	onDelete(deleted) {
		Kitchen.Text().remove(deleted.get('id'));
	}

	renderAddCurvedButton() {
		return <Button data-tip="Aggiungi Testo Curvato" data-for="tooltip-sidebar" className='defaultcolor editor-button add-button' waves='light'
		               onClick={this.createCurved}>
			<Icon className="editor-icon">control_point</Icon></Button>;
	}

	onRender() {
		let editingIds = [];
		this.state.editingObjects.forEach((obj) => { editingIds.push(obj.get('id'));  });
		let state = this.state;

		return (
			<div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide") + " nav"}>
				<p className="header-sidebar"><Icon className="icon-sidebar">text_fields</Icon> TESTO</p>
				{super.renderCloseButton()}
				{super.listenForDelete()}
				{super.listenForDeselect()}
				{
					<div className="default-editor bordable-default">
						{this.renderAddButton()}
						{this.renderAddCurvedButton()}
						{this.renderRemoveButton()}
						{this.renderCopyButton()}
						{this.renderPasteButton()}
						<ReactTooltip id="tooltip-sidebar"/>
					</div>
				}
				{
					this.state.editingObjects.length > 0 &&
					<div id="text-controls">
						<br/>
						<Row className="bordable-default paddingbottom20">
							<Input s={8} name="font-family" className="text-select" type="select" label="Font"
							       defaultValue={state.editingObjects[0].fontFamily}
							       onChange={(e, value) => {
								       let font = null;
								       fontFamilies.forEach((f) => {
									       if (value === Object.values(f)[0].name) {
										       font = Object.values(f)[0];
									       }
								       });
								       if (!font.needs_preload) {
									       state.editingObjects.forEach((obj) => {
										       obj.fontFamily = value;
									       });
									       BaseEditor.renderCanvasAndUpdate();
								       } else {
									       // Use preloader
									       let fontPreloader = new FontFaceObserver(value);
									       fontPreloader.load().then(() => {
										       state.editingObjects.forEach((obj) => {
											       obj.fontFamily = value;
										       });
										       BaseEditor.renderCanvasAndUpdate();
									       }).catch((e) => {
										       console.error('Font loading failed: ' + e);
									       });
								       }
							       }}>
								{mapFontFamilies}
							</Input>
							<Input s={4} name="font-size" className="text-select" type="select" label="Dimensione"
							       defaultValue={state.editingObjects[0].fontSize}
							       onChange={(e, value) => {
								       state.editingObjects.forEach((obj) => { obj.fontSize = value; });
								       BaseEditor.renderCanvasAndUpdate();
							       }}>
								{mapFontSizes}
							</Input>

							<Col s={8} className="filled-col">
								<Input name="bold" label="Grassetto" type="checkbox"
								       checked={state.editingObjects[0].fontWeight === "bold"}
								       className="filled-in"
								       onChange={(e, value) => {
									       state.editingObjects.forEach((obj) => { obj.fontWeight = value ? "bold" : "normal"; });
									       BaseEditor.renderCanvasAndUpdate();
								       }}/><br/>
								<Input name="italic" label="Corsivo" type="checkbox"
								       checked={state.editingObjects[0].fontStyle === "italic"}
								       className="filled-in"
								       onChange={(e, value) => {
									       state.editingObjects.forEach((obj) => { obj.fontStyle = value ? "italic" : "normal"; });
									       BaseEditor.renderCanvasAndUpdate();
								       }}/><br/>
								<Input name="groupDecoration" label="Sottolineato" type="checkbox"
								       checked={state.editingObjects[0].textDecoration === "underline"}
								       className="filled-in"
								       onChange={(e, value) => {
									       state.editingObjects.forEach((obj) => { obj.textDecoration = value ? "underline" : ""; });
									       BaseEditor.renderCanvasAndUpdate();
								       }}/><br/>
								{/*<Input name="groupDecoration" label="Linethrough" type="checkbox"
                       checked={state.editingObjects[0].textDecoration === "line-through"}
                       className="filled-in"
                       onChange={(e, value) => {
                         state.editingObjects.forEach((obj) => { obj.textDecoration = value ? "line-through" : ""; });
                         Kitchen.getCanvas().renderAll();
                         updatePrintPreview();
                       }}/><br/>
                <Input name="groupDecoration" label="Overline" type="checkbox"
                       checked={state.editingObjects[0].textDecoration === "overline"}
                       className="filled-in"
                       onChange={(e, value) => {
                         state.editingObjects.forEach((obj) => { obj.textDecoration = value ? "overline" : ""; });
                         Kitchen.getCanvas().renderAll();
                         updatePrintPreview();
                       }}/>*/}
							</Col>
						</Row>
						{
							!this.isEditingCurved() &&
							<Row className="bordable-default no-margin-row paddingbottom20">
								<Button data-tip="Allineamento a sinistra" data-for="tooltip-sidebar" onClick={() => {
									state.editingObjects.forEach((obj) => obj.textAlign = 'left');
									BaseEditor.renderCanvasAndUpdate();
								}} className="defaultcolor editor-button marginright10"><Icon
									className="editor-icon">format_align_left</Icon></Button>
								<Button data-tip="Allinea al centro" data-for="tooltip-sidebar" onClick={() => {
									state.editingObjects.forEach((obj) => obj.textAlign = 'center');
									BaseEditor.renderCanvasAndUpdate();
								}} className="defaultcolor editor-button marginright10"><Icon className="editor-icon">format_align_center</Icon></Button>
								<Button data-tip="Allinea a destra" data-for="tooltip-sidebar" onClick={() => {
									state.editingObjects.forEach((obj) => obj.textAlign = 'right');
									BaseEditor.renderCanvasAndUpdate();
								}} className="defaultcolor editor-button marginright10"><Icon
									className="editor-icon">format_align_right</Icon></Button>
							</Row>
						}
						{
							this.isEditingCurved() &&
							<div className="bordable-default paddingbottom20">
								<Row className="input-field-text no-margin-row">
									<Input className="input-text-sidebar" s={12} label="Testo" value={this.state.editingObjects[0].text} onChange={(e, val) => {
										this.state.editingObjects.forEach(obj => obj.set({
											text: val === '' ? 'Testo Curvato' : val
										}));
										BaseEditor.renderCanvasAndUpdate();
									}}/>
								</Row>
								<Row className="no-margin-row row-sliders">
									<Col s={6}>
										<label className="label-slider">Diametro</label>
										<Slider value={this.state.editingObjects[0].diameter} min={30} max={360} onChange={value => {
											this.state.editingObjects.forEach(obj => obj.set({
												diameter: value
											}));
											BaseEditor.renderCanvasAndUpdate();
										}}/>
									</Col>
									<Col s={6}>
										<label className="label-slider">Spaziatura</label>
										<Slider value={this.state.editingObjects[0].kerning} min={0} max={10} onChange={value => {
											this.state.editingObjects.forEach(obj => obj.set({
												kerning: value
											}));
											BaseEditor.renderCanvasAndUpdate();
										}}/>
									</Col>
								</Row>
								<Row className="no-margin-row">
									<Input name="italic" label="Verso il basso" type="checkbox"
									       checked={state.editingObjects[0].flipped}
									       className="filled-in"
									       onChange={(e, value) => {
										       state.editingObjects.forEach(obj => obj.set({
											       flipped: value
										       }));
										       BaseEditor.renderCanvasAndUpdate();
									       }}/>
								</Row>
							</div>
						}
						<Row className="bordable-default paddingbottom20 paddingtop10">
							<Col s={12}>
								{super.renderColorController(editingIds, "Colore Testo")}
							</Col>
						</Row>
						{super.renderOutlineControllers(false)}
						{super.renderShadowController(editingIds)}
					</div>
				}
			</div>
		);
	}
}
import React, {Component} from "react";
import PropTypes from "prop-types";
import {api, Status} from "../api";
import AppWrapper from "../components/AppWrapper";
import {Preloader} from "react-materialize";

class AccountReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      status: 0
    }
  }

  componentWillMount() {
    /* 
      Invoked before mounting
      Calling setState() here will not trigger an extra render
      Avoid side-effects and subscriptions here 
    */
  }

  componentDidMount() {
    /* 
      Invoked after mount, good for DOM management and AJAX calls
      Calling setState() here will trigger an extra render
      You can subscribe here but remember to unsub on unmount
    */
    let code = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    if (code !== '' && code !== 'reset') {
      api.reset_password(code)
        .then(res => {
          this.setState({
            status: res.status
          });
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    /*
      Invoked before a mounted component gets new props
      You can update the state here based on prop change
      This is not called during mounting
    */
    if (this.props !== nextProps) {
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* 
      Not called when forceUpdate() is used
      Returning false does not prevent child components 
      from re-rendering when their state changes.
      DO NOT execute performance heavy operations here!
    */
    return true;
  }

  componentWillUpdate(nextProps, nextState) {
    /*
      Invoked just before rendering with new props or state
      Use this as a preparation function
      This is not called before initial render
      You can't call setState() here, or dispatch a Redux action
    */
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      Invoked immediately after update occurs
      This is not called after initial render
      This is good place for DOM management and AJAX on each update
    */
  }

  componentWillUnmount() {
    /*
      Invoked just before unmounting occurs
      This is a destructor, so clean up here
    */
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    console.error(error, info);
    // You can also call your own log system here
  }

  render() {

    return (
      <AppWrapper>
        <div className="text-center">
          {
            this.state.status === 0 ? (
              <Preloader big className="centered"/>
            ) : (
              this.state.status === Status.NO_CONTENT ? (
                <p>La nuova password è stata inviata al suo indirizzo e-mail!</p>
              ) : (
              <h5 style={{color: 'red'}}><b>Richiesta di reset fallita! Riprovare (Errore: {this.state.status})</b></h5>
              )
            )
          }
        </div>
      </AppWrapper>
    );
  }
}

AccountReset.defaultProps = {};

AccountReset.propTypes = {};

export default AccountReset;
import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import {getLoginToken} from "../../routes/Login";
import {Button, Card, Icon, Input, Preloader} from "react-materialize";
import {api, Status} from "../../api";
import 'react-table/react-table.css'
import {PREVIEWS_HEIGHT, PREVIEWS_PERCENT, PREVIEWS_WIDTH} from "../../routes/App";
import {DOMAIN_REMOTE} from "../../index";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";

export class StickerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      stickers: [],
      filtered_stickers: [],
      error: '',
      searchQuery: ''
    };
    this.getStickers = this.getStickers.bind(this);
    this.removeSticker = this.removeSticker.bind(this);
  }

  getStickers() {
    api.get_stickers(getLoginToken())
      .then(res => {
        this.setState({
          status: res.status,
          stickers: res.models,
          filtered_stickers: res.models
        });
      })
      .catch(err => {
        this.setState({
          status: err.status || 500,
          error: err.error || err.message
        });
      });
  }

  componentDidMount() {
    this.getStickers();
  }

  removeSticker(sticker) {
    if (!window.confirm("Sei sicuro di voler rimuovere questo modello? L'azione è irreversibile")) return;

    api.remove_sticker(getLoginToken(), sticker.identifier)
      .then(res => {
        if (res.status === Status.OK) {
          toast.success(
            <div>
              <b>Rimozione modello riuscita!</b>
            </div>
          );
          this.getStickers();
        } else {
          toast.error(
            <div>
              <b>Rimozione modello fallita!</b><br/>
              {res.status} - {res.error || "Errore"}
            </div>
          );
        }
      });
  }

  render() {
    const preview = {
      w: PREVIEWS_WIDTH - (PREVIEWS_WIDTH * (PREVIEWS_PERCENT / 100)),
      h: PREVIEWS_HEIGHT - (PREVIEWS_HEIGHT * (PREVIEWS_PERCENT / 100))
    };

    const columns = [{
      Header: 'Anteprima Modello',
      accessor: 'image',
      Cell: props => (
        <img width={preview.w} height={preview.h} src={
          props.original.image ? (DOMAIN_REMOTE + '/' + props.original.image)
            : (DOMAIN_REMOTE + '/images/' + props.original.identifier + '.png')
        } alt={props.original.identifier}/>
      )
    },{
      Header: 'Codice Modello',
      accessor: 'identifier'
    },{
      Header: 'Nome',
      accessor: 'name'
    },{
      Header: 'Larghezza',
      accessor: 'width'
    },{
      Header: 'Altezza',
      accessor: 'height'
    },{
      Header: 'Diametro',
      accessor: 'diameter'
    },{
      Header: 'Operazioni',
      accessor: '',
      Cell: props => (
        <Button data-tip="Rimuovi" data-for="models-tooltip" className='redcolor editor-button table-button' waves='light' onClick={() => {
          this.removeSticker(props.original);
        }}>
          <Icon className="editor-icon">delete_forever</Icon>
        </Button>
      )
    }];

    return (
      <div className="sticker-list">
        <h5>Lista modelli</h5>
        <Input className="sticker-list-search" onChange={(e, val) => {
          let filtered = [];
          this.state.stickers.forEach(sticker => {
            if (sticker.name.indexOf(val) !== -1 || sticker.identifier.indexOf(val) !== -1) {
              filtered.push(sticker);
            }
          });
          this.setState({
            searchQuery: val,
            filtered_stickers: filtered
          });
        }} label="Ricerca per nome o codice"><Icon>search</Icon></Input>
        {
          this.state.status === 0 &&
            <Preloader big/>
        }
        {
          this.state.status === Status.OK &&
            <div className="sticker-table-container">
              <ReactTable
                data={this.state.filtered_stickers}
                columns={columns}
                style={{
                  height: "600px"
                }}
                defaultPageSize={40}/>
            </div>
        }
        {
          this.state.status !== Status.OK && this.state.status !== 0 &&
          <Card className="red" textClassName="white-text" title="ERROR">
            Errore nel recupero dei modelli (Status: {this.state.status}):
            {this.state.error}
          </Card>
        }
        <ReactTooltip id="models-tooltip"/>
      </div>
    );
  }
}

StickerList.defaultProps = {};

StickerList.propTypes = {};
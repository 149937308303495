import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Button, Preloader, Row} from "react-materialize";
import {config} from "../config/config";
import {DOMAIN_REMOTE} from "../index";
import AppWrapper from "../components/AppWrapper";
import {api, getQueryVariable, Status} from "../api";
import {toast} from "react-toastify";

class AccountActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wantsLogin: false,
      status: 0
    }
  }

  componentDidMount() {
    api.activate_account(getQueryVariable('code'))
      .then(res => {
        this.setState({
          status: res.status
        });
        if (res.status === Status.OK) {
          toast.success(
            <div>
              <b>Account attivato!</b>
            </div>
          )
        } else {
          toast.error(
            <div>
              <b>Account non attivato!</b><br/>
              {res.status} - {res.message}
            </div>
          )
        }
      });
  }

  render() {
    return (
      <AppWrapper>
        <div className="text-center">
          {
            this.state.status === 0 ? (
              <Preloader big className="centered-preloader"/>
            ) : (
              this.state.status === Status.OK ? (
                <React.Fragment>
                  <p><b>Il suo account è stato attivato!</b><br/>E' possibile effettuare il login</p>
                  <Button waves="light" className="actioncolor" onClick={() => this.setState({wantsLogin: true})}>
                    Login
                  </Button>
                </React.Fragment>
              ) : (
                <h5 style={{color: 'red'}}><b>Richiesta di attivazione fallita! Riprovare. (Errore: {this.state.status})</b></h5>
              )
            )
          }
        </div>
        {
          this.state.wantsLogin &&
            <Redirect to="/login"/>
        }
      </AppWrapper>
    )
  }
}

export default AccountActive;
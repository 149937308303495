import React from 'react';
import {Kitchen} from "../FabricCanvas"
import {BaseEditor, updatePrintPreview} from "./BaseEditor";
import {Button, Icon, Input, Row} from "react-materialize";
import 'rc-slider/assets/index.css';
import {BarcodeManager, BC_CONTAINER, DEFAULT_CODE_FORMAT} from "../../KitchenSink";

const Formats = [
	"EAN13",
	"EAN8",
	"CODE128",
	"CODE128A",
	"CODE128B",
	"CODE128C",
	"UPC",
	"CODE39",
	"ITF14",
	"ITF",
	"MSI",
	"MSI10",
	"MSI11",
	"MSI1010",
	"MSI1110",
	"pharmacode"
];

export class BarcodeEditor extends BaseEditor {
	constructor(props) {
		super(props, [], ['qr']);
		this.state.clipboard = null;
		this.state.currentFormat = DEFAULT_CODE_FORMAT;
		this.state.currentCode = null;
		this.state.titleText = '';
		this.state.formatType = DEFAULT_CODE_FORMAT;
		this.state.qrCodeText = '';
	}

	addBarcode(code, format) {
		return Kitchen.Barcode()._add(code, format);
	}

	onCreate(canvas) {
		return this.addBarcode('Codice 1234', DEFAULT_CODE_FORMAT);
	}

	onPaste(pasta) {
		pasta.clone(img => {
			img.left += 10;
			img.top += 10;
			img.my_type = pasta.my_type;
			switch (pasta.my_type) {
				case 'qr': Kitchen.QRCode().addRaw(img); break;
				case 'barcode': Kitchen.Barcode().addRaw(img); break;
			}
		});
	}

	onDelete(deleted) {
		switch (deleted.my_type) {
			case 'qr': Kitchen.QRCode().remove(deleted.get('id')); break;
			case 'barcode': Kitchen.Barcode().remove(deleted.get('id')); break;
		}
		updatePrintPreview();
	}

	onCopy(clipboard) {
		this.setState({
			clipboard: clipboard
		});
	}

	onRender() {
		let self = this;
		let state = this.state;

		return (
			<div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide")}>
				<p className="header-sidebar"><Icon className="icon-sidebar">code</Icon> CODICE A BARRE</p>
				{super.renderCloseButton()}
				{super.listenForDelete()}
				{super.listenForDeselect()}
				<div className="default-editor bordable-default">
					<Button disabled={!Kitchen.getCanvas().getSelection()} data-tip="Rimuovi" data-for="tooltip-sidebar" className='defaultcolor editor-button remove-button' waves='light' onClick={() => this.onDelete(Kitchen.getCanvas().getSelection())}>
						<Icon className="editor-icon">delete_forever</Icon>
					</Button>
					<Button disabled={!Kitchen.getCanvas().getSelection()} data-tip="Copia" data-for="tooltip-sidebar" className='defaultcolor editor-button copy-button' waves='light' onClick={() => this.onCopy(Kitchen.getCanvas().getSelection())}>
						<Icon className="editor-icon">content_copy</Icon>
					</Button>
					<Button disabled={!Kitchen.getCanvas().getSelection()} data-tip="Incolla" data-for="tooltip-sidebar" className='defaultcolor editor-button paste-button' waves='light' onClick={() => this.onPaste(this.state.clipboard)}>
						<Icon className="editor-icon">content_paste</Icon>
					</Button>
				</div>
				{
					<div id="barcode-controls">
						<Row className="transactiontextcodice input-field-bcformat">
							<Input defaultValue={state.titleText} s={12} onChange={(e, val) => {
								self.setState({
									titleText: val
								});
							}} label="Testo" className="padding0 text-select"/>
						</Row>
						<Row className="transactiontextcodice input-field-bcformat">
							<Input defaultValue={state.formatType} s={12} onChange={(e, val) => {
								self.setState({
									formatType: val
								});
							}} type="select" label="Formato" className="text-select">
								{
									Formats.map((format, index) => {
										return <option key={index}>{format}</option>
									})
								}
							</Input>
						</Row>
						<Row className="bordable-default paddingbottom20">
							<Button className="actioncolor"
							        onClick={() => Kitchen.Barcode()._add(state.titleText, state.formatType)}>
								GENERA
							</Button>
						</Row>
					</div>
				}
				<div className="barcode-container">
					<canvas className="bc-container" id={BC_CONTAINER}/>
					<span id="invalid" style={{display: 'none'}}>Dati non validi per questo tipo di codice!</span>
				</div>
				<p className="header-sidebar"><Icon className="icon-sidebar">code</Icon> CODICE QR</p>
				<div id="qrcode-controls">
					<Row className="transactiontextcodice input-field-bcformat">
						<Input defaultValue={state.titleText} s={12} onChange={(e, val) => {
							self.setState({
								qrCodeText: val
							});
						}} label="Testo" className="padding0 text-select"/>
					</Row>
					<Row className="bordable-default paddingbottom20">
						<Button disabled={state.qrCodeText === ''} className="actioncolor" onClick={() => {
							Kitchen.QRCode().add(state.qrCodeText);
						}}>GENERA</Button>
					</Row>
				</div>
			</div>
		);
	}
}
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      wantsLogin: false,
      wantsRegister: false
    }
  }

  render() {
    return (
      <Redirect to="/login"/>
    );
    /*
    <div>
        <h1>BEST APP EVER!</h1>
        <p>This is just a placeholder page. Please ignore me. Make me pretty one day pliz. Thanks</p>
        <br/>
        <a href="" onClick={() => this.setState({wantsLogin: true})}>CLICK HERE TO LOGIN</a>
        <br/>
        <a href="" onClick={() => this.setState({wantsRegister: true})}>CLICK HERE TO REGISTER</a>
        {
          this.state.wantsLogin &&
            <Redirect to="/login"/>
        }
        {
          this.state.wantsRegister &&
            <Redirect to="/signup"/>
        }
      </div>
     */
  }
}


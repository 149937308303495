import React, { Component } from "react";
import {Legend, PieChart} from "react-easy-chart";
import {api, Status} from "../../api";
import {Table} from "react-materialize";
import {DOMAIN_REMOTE} from "../../index";
import {PREVIEWS_HEIGHT, PREVIEWS_PERCENT, PREVIEWS_WIDTH} from "../../routes/App";
import toast from "react-toastify";

export class LabelStats extends Component {

  // How many labels I display in the table
  static MAX_LABEL_STATS = 6;

  constructor() {
    super();
    this.state = {
      labels: [] // Will hold most printed labels
    }
  }

  componentDidMount() {
    this.getStats();
  }

  getStats() {
    api.get_stickers(localStorage.getItem('loginToken')).then(res => {
      switch (res.status) {
        case Status.OK:
          let lbls = [];
          res.models.forEach(mdl => {
            if (mdl.prints && mdl.prints >= 0) {
              lbls.push(mdl);
            }
          });
          lbls.sort((a, b) => {
            return parseInt(b.prints) - parseInt(a.prints);
          });
          lbls = lbls.slice(0, LabelStats.MAX_LABEL_STATS);
          this.setState({
            labels: lbls
          });
          break;
        default:
          toast.error(
            <div>
              <b>RECUPERO STATISTICHE FALLITO!</b>
              <p>Il server non ha recuperato statistiche. Aggiornare per riprovare.</p>
            </div>
          );
      }
    });
  }

  render() {
    // Recover chart data if anything
    let chart_data = [];
    this.state.labels.forEach(label => {
      chart_data.push({
        key: label.identifier,
        value: label.prints
      });
    });

    return (
      <div className="label-stats">
        <h5>Statistiche di utilizzo <a href="" onClick={(e) => {
          e.preventDefault();
          this.getStats();
        }}>(aggiorna)</a></h5>
        <b>I modelli più stampati</b>
        <Table className="printed-models-stats">
          <thead>
          <tr>
            <th data-field="preview">Anteprima</th>
            <th data-field="model">Modello</th>
            <th data-field="name">Nome</th>
            <th data-field="prints">Numero di stampe</th>
          </tr>
          </thead>
          <tbody>
          {
            this.state.labels.map(label => {
              let imgPath = label.image ? (DOMAIN_REMOTE + '/' + label.image)
                : (DOMAIN_REMOTE + '/images/' + label.identifier + '.png');
              let w = PREVIEWS_WIDTH - (PREVIEWS_WIDTH * (PREVIEWS_PERCENT / 100));
              let h = PREVIEWS_HEIGHT - (PREVIEWS_HEIGHT * (PREVIEWS_PERCENT / 100));
              return (
                <tr>
                  <td><img width={w} height={h} src={imgPath} alt="Anteprima"/></td>
                  <td>{label.identifier}</td>
                  <td>{label.name}</td>
                  <td>{label.prints}</td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        {
          chart_data.length > 0 &&
          <div>
            <Legend data={chart_data} dataId={'key'} horizontal/>
            <PieChart
              size={300}
              innerHoleSize={150}
              data={chart_data}
            />
          </div>
        }
      </div>
    )
  }

}
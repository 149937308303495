import React, {Component} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import AppWrapper from "../components/AppWrapper";
import {Button} from "react-materialize";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      wantsBack: false
    }
  }

  componentWillMount() {
    /* 
      Invoked before mounting
      Calling setState() here will not trigger an extra render
      Avoid side-effects and subscriptions here 
    */
  }

  componentDidMount() {
    /* 
      Invoked after mount, good for DOM management and AJAX calls
      Calling setState() here will trigger an extra render
      You can subscribe here but remember to unsub on unmount
    */
  }

  componentWillReceiveProps(nextProps) {
    /*
      Invoked before a mounted component gets new props
      You can update the state here based on prop change
      This is not called during mounting
    */
    if (this.props !== nextProps) {
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* 
      Not called when forceUpdate() is used
      Returning false does not prevent child components 
      from re-rendering when their state changes.
      DO NOT execute performance heavy operations here!
    */
    return true;
  }

  componentWillUpdate(nextProps, nextState) {
    /*
      Invoked just before rendering with new props or state
      Use this as a preparation function
      This is not called before initial render
      You can't call setState() here, or dispatch a Redux action
    */
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      Invoked immediately after update occurs
      This is not called after initial render
      This is good place for DOM management and AJAX on each update
    */
  }

  componentWillUnmount() {
    /*
      Invoked just before unmounting occurs
      This is a destructor, so clean up here
    */
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    console.error(error, info);
    // You can also call your own log system here
  }

  render() {
    return (
      <AppWrapper>
        <div className="text-center">
          <h5>TRATTAMENTO DEI DATI PERSONALI</h5>
          <p>
            INFORMATIVA AI SENSI DELL'ART. 13 D.LGS 196/2003 - CODICE DELLA PRIVACY E DEL REGOLAMENTO UE 2016/679.

            APPTAC E APPLAB SONO PRODOTTI DI ROTOLIFICIO PUGLIESE SRL. QUEST'ULITMO, TITOLARE DEL TRATTAMENTO DEI DATI PERSONALI, RENDE NOTO CHE I SUOI DATI PERSONALI SARANNO TRATTATI IN MODO LECITO E CORRETTO E SARANNO UTILIZZATI ESCLUSIVAMENTE ED EVENTUALMENTE PER L'INVIO DI COMUNICAZIONI DI SERVIZIO E PER ATTIVITA' STRETTAMENTE COLLEGATE CON L'UTILIZZO DEI PREDETTI APPLICATIVI SOFTWARE. IN ALCUN CASO TALI DATI SARANNO CEDUTI A TERZI.
            IL CONFERIMENTO DEI DATI È NECESSARIO AL FINE DI IDENTIFICARE CIASCUN UTENTE.
            I DATI POTRANNO VENIRE A CONOSCENZA DEL PERSONALE DELL'AZIENDA PER GESTIRE LE RICHIESTE.
            IN QUALUNQUE MOMENTO POTRÀ AVERE CONFERMA DELL'ESISTENZA DI DATI PERSONALI CHE LA RIGUARDANO, CHIEDERNE L'AGGIONAMENTO, LA RETTIFICA O LA CANCELLAZIONE ED ESERCITARE TUTTI I DIRITTI RICONOSCIUTI DELLA LEGGE (ART. 7 CODICE PRIVACY).
            IL PERSONALE È A SUA DISPOSIZIONE PER OGNI CHIARIMENTO ED INFORMAZIONE.
          </p>
          <Button waves="light" className="actioncolor" onClick={() => {this.setState({ wantsBack: true }) }}>TORNA INDIETRO</Button>
        </div>
        {
          this.state.wantsBack &&
          <Redirect to="/"/>
        }
      </AppWrapper>
    );
  }
}

Privacy.defaultProps = {};

Privacy.propTypes = {};

export default Privacy
import React, {Component} from "react";
import PropTypes from "prop-types";
import Marquee from "react-smooth-marquee";
import {api, Status} from "../api";
import {getLoginToken} from "../routes/Login";

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: []
    };
    this.grabNews = this.grabNews.bind(this);
  }

  grabNews() {
    api.get_news(getLoginToken(), 1, this.props.maxNews)
      .then(res => {
        if (res.status === Status.OK) {
          this.setState({
            news: res.news
          });
        }
      });
  }

  componentDidMount() {
    this.grabNews();
  }

  render() {
    return (
      <div className="news-container">
        <Marquee velocity={this.props.velocity || 0.12}>
          {
            this.state.news.map((n) => {
              return (
                <React.Fragment>
                  {n.content}
                  <output style={{
                    whiteSpace: 'pre'
                  }}>        </output>
                </React.Fragment>
              )
            })
          }
        </Marquee>
      </div>
    );
  }
}

NewsContainer.defaultProps = {
  velocity: 0.12,
  maxNews: 3
};
NewsContainer.propTypes = {
  velocity: PropTypes.number,
  maxNews: PropTypes.number
};

export default NewsContainer
/*
MAIN CONFIG FILE
 */

export const APPTAC_VER = 'v1.1.1';
export const POOLOVER_VER = 'v1.1.1';
export const COLORPREMIUM_VER = 'v1.1.1';
export const ALEVAR_VER = 'v1.1.1';

const configs = {
	apptac: {
		/* Current Version */
		version: 'v1.1.0',
		/* PATH FOR APP LOGO (A logo featuring the name of the app, default AppLab) */
		app_logo: '/images/app-lab.png',
		/* PATH FOR COMPANY LOGO (Featuring the name of the company behind the app, default AppTac) */
		company_logo: '/images/apptac-logo.png',
		/* PATH FOR BRAND LOGO (Featuring the name of the main company behind the whole thing, default Rotolificio Pugliese) */
		brand_logo: '/images/rotolificio.png',
		/* FOOTER TITLE (String) */
		footer_title: 'Rotolificio Pugliese srl',
		/* FOOTER CONTENT (String) */
		footer_content: [ // Each line will be separated by a <br/>
			'Via Properzio Sesto n.40 76123 Andria (BT) Italy',
			'Ph. +39 0883 59 52 23 – Fax 0883 59 24 15'
		],
		/* CSS PATH (String) */
		css_path: '/styles/apptac-min.css',
		/* Welcome sentence (String) */
		welcome: [
			'Ciao {username}',
			`Benvenuto su APPLAB 2.0, il programma di stampa per etichette A4 che ti viene offerto GRATUITAMENTE da APPTAC, il marchio Leader in Italia nella produzione di etichette adesive. APPLAB ${APPTAC_VER}`
		],
		/* Login banner text (String) */
		login_banner: [
			'Benvenuto su APPLAB 2.0',
			`il programma di stampa per etichette A4 che ti viene offerto GRATUITAMENTE da APPTAC, il marchio Leader in Italia nella produzione di etichette adesive.`
		],
		/* Brand website link */
		brand_link: 'http://www.rotolificiopugliese.it/'
	},
	provapo: {
		/* Current Version */
		version: 'v1.1.0',
		/* PATH FOR APP LOGO (A logo featuring the name of the app, default AppLab) */
		app_logo: '/images/app-lab.png',
		/* PATH FOR COMPANY LOGO (Featuring the name of the company behind the app, default AppTac) */
		company_logo: '/images/poolover-logo.png',
		/* PATH FOR BRAND LOGO (Featuring the name of the main company behind the whole thing, default Rotolificio Pugliese) */
		brand_logo: '/images/poolover-logo.png',
		/* FOOTER TITLE (String) */
		footer_title: 'Pool Over',
		/* FOOTER CONTENT (String) */
		footer_content: [ // Each line will be separated by a <br/>
			'Via Bordighera 36 20142 - Milano',
			'Tel. 02 89 53 29 61 - Fax. 02 87 39 80 41'
		],
		/* CSS PATH (String) */
		css_path: '/styles/poolover-min.css',
		/* Welcome sentence (String) */
		welcome: [
			'Ciao {username}',
			`Benvenuto su PoolOver, il programma di stampa per etichette A4 che ti viene offerto da PoolOver, il Gruppo leader nella distribuzione per la cartoleria. PoolOver ${POOLOVER_VER}`
		],
		/* Login banner text (String) */
		login_banner: [
			'Benvenuto su PoolOver',
			`il programma di stampa per etichette A4 che ti viene offerto da PoolOver, il Gruppo leader nella distribuzione per la cartoleria.`
		],
		/* Brand website link */
		brand_link: 'https://www.poolover.it/'
	},
	colorpremium: {
		/* Current Version */
		version: 'v1.1.0',
		/* PATH FOR APP LOGO (A logo featuring the name of the app, default AppLab) */
		app_logo: '/images/app-lab.png',
		/* PATH FOR COMPANY LOGO (Featuring the name of the company behind the app, default AppTac) */
		company_logo: '/images/colorpremium-logo.png',
		/* PATH FOR BRAND LOGO (Featuring the name of the main company behind the whole thing, default Rotolificio Pugliese) */
		brand_logo: '/images/colorpremium-logo.png',
		/* FOOTER TITLE (String) */
		footer_title: 'Color Premium',
		/* FOOTER CONTENT (String) */
		footer_content: [ // Each line will be separated by a <br/>
			'Via Aldo Moro, 63 95045 - Misterbianco Catania',
			'Tel. 095 30 11 16 - Email. commerciale@ingrossogrillo.com'
		],
		/* CSS PATH (String) */
		css_path: '/styles/colorpremium-min.css',
		/* Welcome sentence (String) */
		welcome: [
			'Ciao {username}',
			`Benvenuto su ColorPremium, il programma di stampa per etichette A4 che ti viene offerto da Grillo Misterbianco, il Gruppo leader nella distribuzione per la cartoleria. ColorPremium ${POOLOVER_VER}`
		],
		/* Login banner text (String) */
		login_banner: [
			'Benvenuto su ColorPremium',
			`il programma di stampa per etichette A4 che ti viene offerto da Grillo Misterbianco, il Gruppo leader nella distribuzione per la cartoleria.`
		],
		/* Brand website link */
		brand_link: 'https://www.ingrossogrillo.com/'
	},
    alevar: {
        /* Current Version */
        version: 'v1.1.0',
        /* PATH FOR APP LOGO (A logo featuring the name of the app, default AppLab) */
        app_logo: '/images/alevar-logo.png',
        /* PATH FOR COMPANY LOGO (Featuring the name of the company behind the app, default AppTac) */
        company_logo: '/images/alevar-logo.png',
        /* PATH FOR BRAND LOGO (Featuring the name of the main company behind the whole thing, default Rotolificio Pugliese) */
        brand_logo: '/images/alevar-logo.png',
        /* FOOTER TITLE (String) */
        footer_title: 'ALEVAR S.R.L.',
        /* FOOTER CONTENT (String) */
        footer_content: [ // Each line will be separated by a <br/>
            'Via Ticino, 2/4 – 21043 Castiglione Olona (VA)',
            'Tel. 0331/812752 - Email. alevar@alevar.it'
        ],
        /* CSS PATH (String) */
        css_path: '/styles/alevar-min.css',
        /* Welcome sentence (String) */
        welcome: [
            'Ciao {username}',
            `Benvenuto su ALEVAR EDITOR, il programma di stampa per etichette A4 che ti viene offerto GRATUITAMENTE da ALEVAR.`
        ],
        /* Login banner text (String) */
        login_banner: [
            'Benvenuto su ALEVAR EDITOR',
            `il programma di stampa per etichette A4 che ti viene offerto GRATUITAMENTE da ALEVAR`
        ],
        /* Brand website link */
        brand_link: 'https://alevar.it/'
    }
};

// Add client configs here and set config
export let config = configs.apptac;

export const assignConfig = function(hostname) {
	for (let key in configs) {
		if (configs.hasOwnProperty(key)) {
			if (hostname.includes(key)) {
				config = configs[key];
			}
		}
	}
};

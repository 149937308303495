import React, {Component} from 'react';
import KeyHandler, {KEYDOWN} from 'react-key-handler';
import {Button, Col, Icon, Input, Row} from 'react-materialize';
import {Kitchen} from "../FabricCanvas";
import {PrintDirector, Labels, JSON_Props, forEachLabel} from "../../routes/App";
import ReactTooltip from "react-tooltip";
import {CustomColorPicker} from "../CustomColorPicker";
import {toast} from "react-toastify";
import {EditorSlider} from "../EditorSlider";
import CanvasButtons from "../CanvasButtons";
import {fabric} from 'fabric';

let first_time_update = true;
export function removeFirstTime() {
	first_time_update = false;
}
export function getFirstTime() {
	return first_time_update;
}

export function getColorString(rgba) {
	return 'rgba('+ [rgba.r, rgba.g, rgba.b, rgba.a].join(',') +')';
}

export function getColorHex(rgba) {
	return "#" + new fabric.Color(rgba).toHex();
}

export function updatePrintPreview() {
	PrintDirector.getCanvasData().then((data) => {
		let canvasImage = data.image; // Most of the stuff happens here really
		Labels.forEach((row) => {
			row.forEach((label) => {
				if (label.active) {
					label.image = canvasImage;
					label.svg = null; // NOT SAVING SVG ANYMORE!
					label.json = Kitchen.getCanvas().toJSON(JSON_Props);
				}
			});
		});
		if (first_time_update) {
			forEachLabel((label) => {
				if (label.index !== 0)
					label.active = false;
			});
		}
		PrintDirector.printRender.setState({ printHtmlMin: PrintDirector.getPrintPreview().getMinifiedPrintHTML() });
		CanvasButtons.drawManagedGrid();
	});
}

let objects_backup = [];

export class BaseEditor extends Component {
	constructor(props, objectTypes, internalTypes) {
		super(props);
		this.objectTypes = objectTypes;
		this.internalTypes = internalTypes;
		this.newElement = this.create.bind(this);
		this.copyElement = this.copy.bind(this);
		this.pasteElement = this.paste.bind(this);
		this.removeElement = this.remove.bind(this);
		this.toggleEditingColor = this.toggleEditingColor.bind(this);
		this.toggleEditingShadowColor = this.toggleEditingShadowColor.bind(this);
		this.baseShadow = new fabric.Shadow({
			color: 'rgba(0,0,0,0.5)',
			offsetX: 1, offsetY: 1,
			blur: 0.7,
			affectStroke: true
		});
		this.state = {
			editingObjects: [],
			editingColor: false,
			editingShadowColor: false,
			clipboard: null
		};
		this.onObjectSelected = this.onObjectSelected.bind(this);
		this.onSelectionClear = this.onSelectionClear.bind(this);
		this.onSelectionCreate = this.onSelectionCreate.bind(this);

		this.onCreate = this.onCreate.bind(this);
		this.onCopy = this.onCopy.bind(this);
		this.onPaste = this.onPaste.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSelectionCleared = this.onSelectionCleared.bind(this);
		this.onSelectionCreated = this.onSelectionCreated.bind(this);

		this.onComponentMount = this.onComponentMount.bind(this);
		this.onComponentUnmount = this.onComponentUnmount.bind(this);

		this.onRender = this.onRender.bind(this);
	}

	componentWillUnmount() {
		objects_backup = this.state.editingObjects;
		this.onComponentUnmount(Kitchen.getCanvas());
	}

	componentDidMount() {
		this.setupFabricListeners();
		this.setState({
			editingObjects: objects_backup
		});
		this.onComponentMount(Kitchen.getCanvas());
	}

	onObjectSelected(canvas, object) {
		canvas.bringToFront(object);
		let fakeLabel = canvas.getObjectByAttribute('id', 'FAKE_LABEL');
		if (fakeLabel) canvas.bringToFront(fakeLabel);
		let whatToOpen = '';
		if (!object.my_type && this.getObjectTypes().indexOf(object.get('type')) !== -1) {
			whatToOpen = this.props.type;
		} else {
			switch (object.my_type) {
				case 'icon': whatToOpen = 'shape'; break;
				case 'qr':
				case 'barcode':
					whatToOpen = 'code';
					break;
			}
		}
		if (whatToOpen !== '') {
			this.props.openSidebar(whatToOpen);
			this.setState({
				editingObjects: [object],
				editingColor: false,
				editingShadowColor: false
			});
			this.onSelectionCreated([object]);
		}
	}
	onSelectionClear() {
		this.setState({
			editingObjects: []
		});
		this.onSelectionCleared();
	}
	onSelectionCreate(canvas) {
		let objects = [];
		let object = canvas.getSelection();
		if (this.getObjectTypes().indexOf(object.get('type')) !== -1
			|| this.getInternalTypes().indexOf(object.my_type) !== -1) {
			objects.push(object);
		}
		this.setState({
			editingObjects: objects,
			editingColor: false,
			editingShadowColor: false
		});
		this.onSelectionCreated(objects);
	}

	create() {
		Kitchen.getCanvas().discardSelection();
		let id = this.onCreate(Kitchen.getCanvas());
		updatePrintPreview();
		return id;
	}

	copy() {
		this.setState({
			clipboard: Kitchen.getCanvas().getSelection()
		});
		this.onCopy(Kitchen.getCanvas().getSelection());
	}

	paste() {
		const clipboard = this.state.clipboard;
		if (clipboard) {
			Kitchen.getCanvas().discardSelection();
			if (this.getObjectTypes().indexOf(clipboard.get('type')) !== -1
				|| this.getInternalTypes().indexOf(clipboard.my_type) !== -1) {
				this.onPaste(clipboard);
			}
			updatePrintPreview();
		}
	}

	remove() {
		let selection = Kitchen.getCanvas().getSelection();
		if (selection) {
			if (this.getObjectTypes().indexOf(selection.get('type')) !== -1
				|| this.getInternalTypes().indexOf(selection.my_type) !== -1) {
				this.onDelete(selection);
			}
			updatePrintPreview();
		}
	}

	getObjectTypes() { return this.objectTypes || []; }
	getInternalTypes() { return this.internalTypes || []; }

	onCreate(canvas) {}
	onCopy(clipboard) {}
	onPaste(pasta) {}
	onDelete(deleted) {}
	onSelectionCleared() {}
	onSelectionCreated(objects) {}
	onComponentMount(canvas) {}
	onComponentUnmount(canvas) {}
	onRender() {}

	toggleEditingColor() {
		this.setState({editingColor: !this.state.editingColor});
	}
	toggleEditingShadowColor() {
		this.setState({editingShadowColor: !this.state.editingShadowColor});
	}

	setupFabricListeners() {
		let canvas = Kitchen.getCanvas();
		// Listen for events here
		canvas.on({
			'object:selected': (event) => this.onObjectSelected(canvas, event.target),
			'selection:cleared': () => this.onSelectionClear(),
			'selection:created': () => this.onSelectionCreate(canvas)
		});
	}

	listenForDelete() {
		return (
			<React.Fragment>
				<KeyHandler keyEventName={KEYDOWN} keyValue={'Backspace'} onKeyHandle={this.removeElement}/>
				<KeyHandler keyEventName={KEYDOWN} keyValue={'Delete'} onKeyHandle={this.removeElement}/>
			</React.Fragment>
		);
	}
	listenForDeselect() {
		return (
			<KeyHandler keyEventName={KEYDOWN} keyValue={'Escape'} onKeyHandle={() => Kitchen.getCanvas().discardSelection()}/>
		);
	}
	renderAddButton() {
		return <Button data-tip="Aggiungi" data-for="tooltip-sidebar" className='defaultcolor editor-button add-button' waves='light' onClick={this.newElement}>
			<Icon className="editor-icon">add</Icon></Button>;
	}
	renderRemoveButton() {
		return <Button disabled={this.state.editingObjects.length === 0} data-tip="Rimuovi" data-for="tooltip-sidebar" className='defaultcolor editor-button remove-button' waves='light' onClick={this.removeElement}>
			<Icon className="editor-icon">delete_forever</Icon></Button>;
	}
	renderCopyButton() {
		return <Button disabled={this.state.editingObjects.length === 0} data-tip="Copia" data-for="tooltip-sidebar" className='defaultcolor editor-button copy-button' waves='light' onClick={this.copyElement}>
			<Icon className="editor-icon">content_copy</Icon></Button>;
	}
	renderPasteButton() {
		return <Button disabled={this.state.editingObjects.length === 0} data-tip="Incolla" data-for="tooltip-sidebar" className='defaultcolor editor-button paste-button' waves='light' onClick={this.pasteElement}>
			<Icon className="editor-icon">content_paste</Icon></Button>;
	}

	static renderCanvasAndUpdate() {
		Kitchen.getCanvas().renderAll();
		updatePrintPreview();
	}

	renderDefault() {
		// Common to all editors
		return (
			<div className="default-editor bordable-default">
				{this.renderAddButton()}
				{this.renderRemoveButton()}
				{this.renderCopyButton()}
				{this.renderPasteButton()}
				<ReactTooltip id="tooltip-sidebar"/>
			</div>
		);
	}

	renderColorController(editingIds, label) {
		const targets = this.state.editingObjects;
		const t = targets[0];
		return (
			<React.Fragment>
				<Row className="filled-col no-margin-row">
					<CustomColorPicker label={label} color={t.fill}
					                   onColorChange={(col) => {
						                   t.set('fill', getColorString(col));
						                   BaseEditor.renderCanvasAndUpdate();
					                   }}/>
				</Row>
				<EditorSlider label="Opacità" editingObjects={targets} forEachObject={(obj, val) => {
					obj.setAlpha(val / 255);
				}} value={t.getAlpha() * 255}/>
			</React.Fragment>
		);
	}

	renderShadowController(editingIds) {
		const self = this;
		const targets = this.state.editingObjects;
		const t = targets[0];
		return (
			<div className="shadow-controller">
				<Row className="filled-col no-margin-row">
					<Input name="shadow-enable" label="Ombra" type="checkbox"
					       checked={t.getShadow() !== null}
					       className="filled-in"
					       onChange={(e, value) => {
					       	    t.setShadow(value ? self.baseShadow : null);
						        BaseEditor.renderCanvasAndUpdate();
					       }}
					/>
				</Row>
				{
					t.getShadow() !== null &&
					<React.Fragment>
						<Row className="">
							<Col s={12}>
								<CustomColorPicker label="Colore Ombra"
								                   color={t.getShadow()
									                   ? t.getShadow().color
									                   : '#000'}
								                   onColorChange={(col) => {
									                   col.a = t.getShadowAlpha();
									                   if (t.getShadow()) {
										                   t.getShadow().color = getColorString(col);
									                   }
								                   }}
								/>
							</Col>
						</Row>
						<EditorSlider label="Opacità Ombra" editingObjects={targets} value={t.getShadowAlpha() * 255}
						              forEachObject={(obj, val) => {
							              obj.setShadowAlpha(val / 255)
						              }}/>
						<EditorSlider label="Sfocatura Ombra" editingObjects={targets} value={t.getShadow().blur}
						              forEachObject={(obj, val) => {
							              obj.getShadow().blur = val;
						              }} max={10}/>
						<EditorSlider label="Distanza Ombra" editingObjects={targets} value={t.getShadow().offsetX}
						              forEachObject={(obj, val) => {
							              obj.getShadow().offsetX = val;
							              obj.getShadow().offsetY = val;
						              }} max={20}/>
					</React.Fragment>
				}
			</div>
		);
	}

	renderCloseButton() {
		return (
			<Button className="actioncolor editor-button closeicon-sidebar"
			        onClick={() => this.props.closeSidebar()}><Icon>keyboard_arrow_right</Icon></Button>
		)
	}

	renderOutlineControllers(render_sliders) {
		const targets = this.state.editingObjects;
		const t = targets[0];
		return (
			<Row className="filled-col no-margin-row">
				<Input name="outline-enable" label="Mostra Contorno" type="checkbox"
				       checked={t.stroke !== null }
				       className="filled-in"
				       disabled={
					       t.get('type') === 'path-group' ||
					       t.get('type') === 'image'
				       }
				       onChange={(e, value) => {
					       t.set('stroke', value ? t.fill : null);
					       BaseEditor.renderCanvasAndUpdate();
				       }}/>
				{
					t.stroke !== null &&
					<React.Fragment>
						<Row className="no-margin-row">
							<Col s={12}>
								<CustomColorPicker label="Colore Contorno" color={t.stroke}
								                   onColorChange={(col) => {
									                   col.a = new fabric.Color(t.stroke).getAlpha();
									                   t.stroke = getColorString(col);
								                   }}/>
							</Col>
						</Row>
						{
							render_sliders &&
							<EditorSlider label="Spessore Contorno" editingObjects={targets}
							              value={t.strokeWidth}
							              forEachObject={(obj, val) => {
								              obj.set('strokeWidth', val);
							              }} min={1} max={10}/>
						}
						<EditorSlider label="Opacità Contorno" editingObjects={targets} value={t.getStrokeAlpha() * 255}
						              forEachObject={(obj, val) => {
							              obj.setStrokeAlpha(val / 255);
						              }}/>
					</React.Fragment>
				}
			</Row>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.onRender()}
			</React.Fragment>
		);
	}
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import {BaseEditor} from "./editors/BaseEditor";
import Slider from "rc-slider";
import {Col, Row} from "react-materialize";
import {fabric} from 'fabric';

export class EditorSlider extends Component {

  render() {
    return (
      <Row className="no-margin-row row-sliders">
        <Col s={12}>
          <label className="label-slider">{this.props.label}</label>
          <Slider value={this.props.value} min={this.props.min} max={this.props.max} onChange={value => {
            this.props.editingObjects.forEach(obj => this.props.forEachObject(obj, value));
            BaseEditor.renderCanvasAndUpdate();
          }}/>
        </Col>
      </Row>
    );
  }

}

EditorSlider.defaultProps = {
  label: "Slider",
  editingObjects: undefined,
  value: 0,
  min: 0,
  max: 255,
  forEachObject: undefined
};
EditorSlider.propTypes = {
  label: PropTypes.string,
  editingObjects: PropTypes.arrayOf(fabric.Object).isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  forEachObject: PropTypes.func.isRequired
};
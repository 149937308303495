import React, { Component } from 'react';
import {Button, Card, Col, Icon, Input, Preloader, Row} from "react-materialize";
import {Redirect} from 'react-router-dom';
import {api, Status} from "../api";
import {toast, ToastContainer} from "react-toastify";
import KeyHandler, { KEYUP } from 'react-key-handler';
import AppWrapper from "../components/AppWrapper";

export function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      region: '',
      createdUser: false,
      badRequest: false,
      message: '',
      error: '',
      wantsLogin: false,
      registering: false,
      checkedPrivacy: false,
      wantsPrivacy: false
    };
    this.italyRegions = [
      "",
      "Abruzzo",
      "Basilicata",
      "Calabria",
      "Campania",
      "Emilia-Romagna",
      "Friuli-Venezia Giulia",
      "Lazio",
      "Liguria",
      "Lombardia",
      "Marche",
      "Molise", // Non esiste
      "Piemonte",
      "Puglia",
      "Sardegna",
      "Sicilia",
      "Toscana",
      "Trentino-Alto Adige",
      "Umbria",
      "Valle d'Aosta",
      "Veneto"
    ];
    this.handleRegister = this.handleRegister.bind(this);
  }

  static popSuccess(message) {
    toast.success(
      <div>
        {message}
      </div>, {
        autoClose: 8000
      }
    )
  }
  static popError(error) {
    toast.error(
      <div>
        {error}
      </div>, {
        autoClose: 8000
      }
    )
  }

  handleRegister() {
    this.setState({registering: true});
    api.register(this.state.email, this.state.username, this.state.password, this.state.region)
    .then(response => {
      if (response.status) {
        switch (response.status) {
          case Status.CREATED:
            this.setState({
              registering: false,
              createdUser: true,
              message: response.message,
              error: ''
            });
            Register.popSuccess(
              <div>
                <b>REGISTRAZIONE EFFETTUATA!</b><br/>
                <p>Una mail di conferma è stata inviata al tuo indirizzo.</p>
              </div>
            );
            setTimeout(() => {
              this.setState({
                wantsLogin: true
              });
            }, 2000);
            break;
          case Status.BAD_REQUEST:
            this.setState({
              registering: false,
              createdUser: false,
              message: '',
              error: response.error
            });
            Register.popError(
              <div>
                <b>REGISTRAZIONE FALLITA!</b><br/>
                <p>Richiesta errata, riprovare.</p>
              </div>
            );
            break;
          case Status.CONFLICT:
            this.setState({
              registering: false,
              createdUser: false,
              message: '',
              error: response.error
            });
            Register.popError(
              <div>
                <b>REGISTRAZIONE FALLITA!</b><br/>
                <p>L'account è già presente sul sistema.</p>
              </div>
            );
            break;
          case Status.INTERNAL_SERVER_ERROR:
            this.setState({
              registering: false,
              createdUser: false,
              message: '',
              error: response.error
            });
            Register.popError(
              <div>
                <b>REGISTRAZIONE FALLITA!</b><br/>
                <p>Il server è offline o in manutenzione.</p>
              </div>
            );
            break;
          default:
            this.setState({
              registering: false,
              createdUser: false,
              message: '',
              error: 'Errore inaspettato! Status: ' + response.status
            });
            Register.popError(
              <div>
                <b>REGISTRAZIONE FALLITA!</b><br/>
                <p>Errore inaspettato. Status: {response.status}</p>
              </div>
            );
        }
        this.setState({ badRequest: false });
      } else {
        this.setState({
          registering: false,
          badRequest: true,
          createdUser: false,
          message: '',
          error: 'Richiesta fallita. Contattare l\'amministratore di sistema.'
        });
        Register.popError(
          <div>
            <b>REGISTRAZIONE FALLITA!</b><br/>
            <p>Richiesta fallita. Contattare l'amministratore di sistema.</p>
          </div>
        );
      }
    });
  }

  render() {
    return (
      <AppWrapper>
        <div className="testo-login text-center">
          Registrazione nuovo Utente
        </div>
        <div className="margintop20">
          <Input onChange={(e, val) => this.setState({ email: val })} className="input-login" type="email" label="Indirizzo Mail*" validate><Icon>mail</Icon></Input>
          <Input onChange={(e, val) => this.setState({ username: val })} className="input-login" label="Nome Utente*" validate><Icon>account_circle</Icon></Input>
          <Input onChange={(e, val) => this.setState({ password: val })} className="input-login" type="password" label="Password*" validate><Icon>lock</Icon></Input>
          <Input valid={this.state.password === this.state.confirmPassword}
                 onChange={(e, val) => this.setState({ confirmPassword: val })} className="input-login" type="password" label="Conferma Password*" validate><Icon>lock</Icon></Input>
          <Input onChange={(e, val) => this.setState({ region: val })} type="select" className="input-login" icon="location_on" label="Regione*" validate>
            {
              this.italyRegions.map(region => {
                return <option key={'key_'+region}>{region}</option>
              })
            }
          </Input>
          <Input type="checkbox" checked={this.state.checkedPrivacy} className="input-login filled-in"
                 label={
                   <React.Fragment>
                     Accetto la <a href="" onClick={(e) => { e.preventDefault(); this.setState({ wantsPrivacy: true }); }}>vigente normativa sulla privacy</a>.
                   </React.Fragment>
                 }
                 onChange={(e, val) => this.setState({ checkedPrivacy: val })}
          />
          <br/>
          <Row>
            <Col s={12}>
              <div className="text-center">
                <Button disabled={this.state.email === '' || !validateEmail(this.state.email) || this.state.password === ''
                || this.state.password !== this.state.confirmPassword
                || this.state.region === '' || !this.state.checkedPrivacy}
                      id="register-button" onClick={() => this.handleRegister()}>Registrati</Button>
              </div>
            </Col>
          </Row>
          <div className="text-center margintop72">
            <a className="font-size12" href="" id="login-link" onClick={() => this.setState({ wantsLogin: true })}>Login</a>
          </div>
            {
              this.state.wantsLogin &&
              <Redirect to="/login"/>
            }
            {
              this.state.wantsPrivacy &&
                <Redirect to="/privacy"/>
            }
        </div>
        <KeyHandler keyEventName={KEYUP} keyValue="Enter" onKeyHandle={() => this.handleRegister()}/>
      </AppWrapper>
    );
  }
}
import React, { Component } from 'react';
import {LABEL_DID_INIT} from "../routes/App";
import {Button, Col, Icon, Input, Preloader, ProgressBar, Row} from "react-materialize";
import {
  applyZoom, PAPER_HEIGHT_MM, PAPER_WIDTH_MM
} from "./PrintManager";
import {DOMAIN_REMOTE} from "../index";

export class PrintRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printHtml: <p/>,
      printHtmlMin: <p/>,
      visibleFinalPrint: false,
      displayPrintOverlay: false,
      printReady: false,
      printLink: '',
      editingAll: props.editAll,
      multipleSelect: props.multipleSelect,
      isMerging: false,
      mergeProgress: 0,
      mergePage: 0,
      mergePages: 0,
      model: null
    };
    this.downloadPDF = this.downloadPDF.bind(this);
    this.openPDF = this.openPDF.bind(this);
    this.reset = this.reset.bind(this);
    this.resetMergeState = this.resetMergeState.bind(this);
    this.getModelString = this.getModelString.bind(this);
    this.uploadSuccess = this.uploadSuccess.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editingAll: nextProps.editAll
    });
  }

  uploadSuccess(res) {
    this.setState({
      printReady: true,
      printLink: DOMAIN_REMOTE + JSON.parse(res).pdf_path.filename
    });
  }

  uploadFailure(err) {
    this.setState({
      printReady: false,
      printLink: '',
      displayPrintOverlay: false
    });
  }

  downloadPDF() {
    if (this.state.printLink !== '') {
      let link = document.createElement('a');
      link.href = this.state.printLink;
      link.download = 'etichette.pdf';
      link.dispatchEvent(new MouseEvent('click'));
      this.setState({
        displayPrintOverlay: false,
        printReady: false,
        printLink: false
      });
    }
  }

  openPDF() {
    if (this.state.printLink !== '') {
      window.open(this.state.printLink);
      this.setState({
        displayPrintOverlay: false,
        printReady: false,
        printLink: false
      });
    }
  }

  resetMergeState() {
    this.setState({
      isMerging: false,
      mergeProgress: 0,
      mergePage: 0
    });
  }

  reset() {
    this.setState({
      visibleFinalPrint: false,
      displayPrintOverlay: false,
      printReady: false,
      printLink: '',
      editingAll: this.props.editAll,
      multipleSelect: this.props.multipleSelect,
      isMerging: false,
      mergeProgress: 0,
      mergePage: 0,
      mergePages: 0
    });
  }

  getModelString() {
    const mdl = this.state.model;

    if (!mdl) return "";

    let str = "Modello: " + mdl.identifier + " ";
    if (mdl.width && mdl.height) {
      str += "(" + mdl.width + "x" + mdl.height + "mm)"
    } else {
      str += "(" + mdl.diameter + "mm)"
    }

    return str;
  }

  render() {
    return (
      <div>
        <div id="label-selector-div" className="no-print">
          <div className="inputs-paper">
            <Row>
              <Col className="input-multiselection marginright0" s={5}>
                <Input disabled={!LABEL_DID_INIT} label="SELEZIONE MULTIPLA" type="checkbox"
                       checked={this.state.multipleSelect}
                       className="filled-in no-print"
                       onChange={(e, value) => {
                         this.setState({
                           multipleSelect: value
                         });
                         if (!value) {
                           this.props.onEditOne();
                         }
                         this.props.toggleMultipleSelect(value);
                       }}/>
              </Col>
              <Col className="marginright0" s={5}>
                <Button disabled={!LABEL_DID_INIT || !this.state.multipleSelect}
                        className="button-apply-all actioncolor no-print"
                        onClick={() => {
                          this.props.onEditAll();
                        }}>APPLICA A TUTTI</Button>
              </Col>
            </Row>
          </div>
          <div class="no-print" id="label-selector" style={{
            width: applyZoom(PAPER_WIDTH_MM) + 'mm',
            height: applyZoom(PAPER_HEIGHT_MM) + 'mm'
          }}>
            <p className="model-name">{this.getModelString()}</p>
            {this.state.printHtmlMin}
          </div>
        </div>
        {
          this.state.displayPrintOverlay &&
            <div class="no-print" id="print-overlay">
              <div className="centered">
                {
                  !this.state.printReady &&
                    <React.Fragment>
                      <Preloader size="big"/><br/>
                      <h2>Stiamo preparando la stampa...</h2>
                      <br/>
                      Per ottenere un'ottima qualità di stampa, imposta la tua stampante in modalità "senza margini" e con zoom 100%
                      {
                        this.state.isMerging &&
                          <React.Fragment>
                            Stampa unione in corso! (Pagina {this.state.mergePage} di {this.state.mergePages})
                            <br/>
                            <Row>
                              <Col s={12}>
                                <ProgressBar progress={this.state.mergeProgress}/>
                              </Col>
                            </Row>
                          </React.Fragment>
                      }
                    </React.Fragment>
                }
                {
                  this.state.printReady &&
                    <React.Fragment>
                      <h2>Stampa pronta!</h2>
                      <br/>
                      Per ottenere un'ottima qualità di stampa, imposta la tua stampante in modalità "senza margini" e con zoom 100%
                      <Row className="margintop28">
                        <Col s={6}>
                          <Button className="actioncolor" waves="light" onClick={() => this.downloadPDF()}>SCARICA PDF</Button>
                        </Col>
                        <Col s={6}>
                          <Button className="actioncolor" waves="light" onClick={() => this.openPDF()}>APRI PDF</Button>
                        </Col>
                      </Row>
                      <Button className="actioncolor editor-button overlayclosebutton" waves="light" onClick={() => {
                        this.reset();
                      }}><Icon>close</Icon></Button>
                    </React.Fragment>
                }
              </div>
            </div>
        }
      </div>
    )
  }
}
import React, {Component} from "react";
import {Button, Icon, Input, Navbar, NavItem, Row} from "react-materialize";
import {removeLoginToken} from "./Login";
import {Redirect} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {api, Status} from "../api";
import {toast, ToastContainer} from "react-toastify";
import {DOMAIN_REMOTE} from "../index";
import {config} from "../config/config";
import ConfirmLink from "react-confirm-dialog";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wantsApp: false,
      wantsLogout: false,
      wantsAdmin: false,
      me: {},
      newPassword: '',
      confirmNewPassword: '',
      newUsername: ''
    }
  }

  componentDidMount() {
    // Retrieve information about the logged profile
    api.me(localStorage.getItem('loginToken'))
      .then(res => {
        if (res.status === Status.OK) {
          this.setState({
            me: res.account
          });
        } else {
          if (res.status === Status.FORBIDDEN) {
            this.setState({
              wantsLogout: true
            });
          } else {
            toast.error(
              <div>
                <b>ERRORE {res.status}</b><br/>
                <p>{res.error}</p>
              </div>
            );
          }
        }
      });
  }

  componentDidCatch(error, info) {
    toast.error(
      <div>
        <b>{error}</b><br/>
        <p>{info}</p>
      </div>
    );
  }

  updateProfile(toUpdate) {
    const token = localStorage.getItem('loginToken');
    if (toUpdate.password) {
      api.update_password(token, toUpdate.password)
        .then(res => {
          if (res.status === Status.OK) {
            toast.success(
              <div>
                <b>Update profilo effettuato!</b>
                <p>La password è stata modificata!</p>
              </div>
            );
            // Update me
            this.setState({
              me: res.data
            });
          } else {
            if (res.status === Status.FORBIDDEN) {
              this.setState({
                wantsLogout: true
              });
            } else {
              toast.error(
                <div>
                  <b>ERRORE {res.status}</b><br/>
                  <p>{res.error}</p>
                </div>
              );
            }
          }
        })
    } else {
      api.update_account(token, toUpdate)
        .then(res => {
          if (res.status === Status.OK) {
            toast.success(
              <div>
                <b>Update profilo effettuato!</b>
                <p>Il nome utente è stato modificato</p>
              </div>
            );
            // Update me
            this.setState({
              me: res.data
            });
          } else {
            if (res.status === Status.FORBIDDEN) {
              this.setState({
                wantsLogout: true
              });
            } else {
              toast.error(
                <div>
                  <b>ERRORE {res.status}</b><br/>
                  <p>{res.error}</p>
                </div>
              );
            }
          }
        });
    }
  }

  logout() {
    localStorage.removeItem('username');
    removeLoginToken();
    this.setState({
      wantsLogout: true
    });
  }

  render() {
    return (
      <div className="profile-container no-print">
        <div className="profile-header">
          <Navbar href="" brand={
            <img className="logo" style={{maxHeight: 100, maxWidth: 100}} src={DOMAIN_REMOTE + config.app_logo} alt="AppTac Label Editor"/>
          } right>
            <NavItem onClick={() => this.setState({wantsApp: true})}>Torna alla app</NavItem>
            <ConfirmLink action={this.logout} confirmMessage="Sei sicuro di voler effettuare il logout?"
                         confirmText="Sì" cancelText="No">
              <NavItem data-tip="Logout" data-for="global-tooltip" href="" onClick={(e) => {
                e.preventDefault();
              }}>
                <Icon>exit_to_app</Icon>
              </NavItem>
            </ConfirmLink>
          </Navbar>
        </div>
        <div className="profile-body">
          <div className="profile-editor centered">
            <h4 style={{marginTop: 0}}>MODIFICA PROFILO</h4>
            <h5>Modifica Username</h5>
            <Row className="no-margin-row">
              <Input s={12} validate label="Nuovo Username" value={this.state.newUsername} placeholder={this.state.me ? this.state.me.username : ''}
                     onChange={(e, val) => this.setState({
                       newUsername: val
                     })}
              />
            </Row>
            <Button disabled={this.state.newUsername === ''}
                    className="actioncolor" waves="light"
                    onClick={() => {
                      this.updateProfile({
                        username: this.state.newUsername
                      });
                    }}
            >Aggiorna</Button>
            <h5>Modifica Password</h5>
            <Row className="no-margin-row">
              <Input s={12} type="password" label="Nuova password" value={this.state.newPassword} onChange={(e, val) => this.setState({
                newPassword: val
              })}/>
            </Row>
            <Row className="no-margin-row">
              <Input s={12} type="password" label="Conferma nuova password" value={this.state.confirmNewPassword} onChange={(e, val) => this.setState({
                confirmNewPassword: val
              })}/>
            </Row>
            <Button disabled={this.state.newPassword === '' || this.state.newPassword !== this.state.confirmNewPassword}
                    className="actioncolor" waves="light"
                    onClick={() => {
                      this.updateProfile({
                        password: this.state.newPassword
                      });
                    }}
            >Aggiorna</Button>
            {
              this.state.me && this.state.me.role === 'admin' &&
                <React.Fragment>
                  <br/><br/>
                  <Button className="actioncolor" waves="light"
                          onClick={() => {
                            this.setState({
                              wantsAdmin: true
                            });
                          }} icon="apps">PANNELLO ADMIN</Button>
                </React.Fragment>
            }
          </div>
        </div>
        {
          this.state.wantsApp &&
            <Redirect to="/app"/>
        }
        {
          this.state.wantsLogout &&
            <Redirect to="/login"/>
        }
        {
          this.state.wantsAdmin &&
          <Redirect to="/admin"/>
        }
        <ReactTooltip id="profile-tooltip"/>
        <ToastContainer autoClose={8000}/>
      </div>
    );
  }
}

MyProfile.defaultProps = {};

MyProfile.propTypes = {};

export default MyProfile;
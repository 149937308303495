import React, { Component } from 'react';
import { FabricCanvas } from "./FabricCanvas"

export class CanvasContainer extends Component {
  constructor() {
    super();
    this.fabricCanvas = null;
  }

  getFabricCanvas() {
    return this.fabricCanvas;
  }

  render() {
    return (
      <div className="no-print" id="canvas-container">
        <FabricCanvas ref={(fc) => this.fabricCanvas = fc}/>
        <canvas id="grid-canvas">
          Your browser does not support HTML5 canvas tag.
        </canvas>
        <canvas id="fabric-canvas">
          Your browser does not support HTML5 canvas tag.
        </canvas>
      </div>
    );
  }
}
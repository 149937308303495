/*
  MODELS REPOSITORY
  Every model belongs here. If you want to add a new model, add it here.
  A model is defined by:
  - Width / Height in pixels
  - Shape (An already defined fabric object to handle the shape)
 */
import React from 'react';
import {fabric} from 'fabric';

const DEFAULT_SHAPE_COLOR = '#FFF';

// Shape families
export const ShapeFamilies = {
  Rectangle: 'Rectangle',
  Circle: 'Circle'
};

// Label Types
export const LabelTypes = {
  AA: 'AA',
  CM: 'CM',
  RT: 'RT',
  SM: 'SM',
  SP: 'SP'
};
export const LabelFormats = [
  'Tutti', 'Angoli Arrotondati', 'Con Margini', 'Rotondi', 'Senza Margini', 'Speciali'
];
export const ALL_MODELS = LabelFormats[0];
export const LabelFormatsTable = {
  'Tutti': ALL_MODELS,
  'Angoli Arrotondati': LabelTypes.AA,
  'Con Margini': LabelTypes.CM,
  'Rotondi': LabelTypes.RT,
  'Senza Margini': LabelTypes.SM,
  'Speciali': LabelTypes.SP
};

// Map of shapes DO NOT MODIFY SHAPE NAMES PLEASE
export let LabelShapes = [
  {
    name: ShapeFamilies.Rectangle,
    kind: 'R',
    object: new fabric.Rect()
  },
  {
    name: ShapeFamilies.Circle,
    kind: 'C',
    object: new fabric.Circle()
  }
];


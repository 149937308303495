import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";
import {Col, Input, Row, Button} from "react-materialize";
import {DOMAIN_REMOTE, REST_POINT} from "../../index";
import {api, Status} from "../../api";
import ReactUploadFile from "react-upload-file"

export class StickerCreator extends Component {
	StickerKinds = ['R', 'C'];

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			isAdding: true,
			kind: this.StickerKinds[0],
			identifier: '',
			name: '',
			format: '',
			width: 0,
			height: 0,
			left: 0, // Left margin
			top: 0, // Top margin
			diameter: 0,
			intra_right: 0, // Internal right margin
			intra_bottom: 0, // Internal bottom margin
			diameter_inner: 0, // Inner diameter (for CDs)
			image: '', // Image associated with the sticker (if there is one)
			labelImage: '', // Label image associated with the sticker (there must be one if the sticker is special)
			preview: '', // Base64 of preview,
			previewLabelImage: '', // Base64 of label image preview
		};
		this.uploading = false;
		this.imageToUpload = null;
		this.labelImageToUpload = null;

		this.prepareRequest = this.prepareRequest.bind(this);
		this.addModel = this.addModel.bind(this);
		this.updateModel = this.updateModel.bind(this);
		this.checkInputs = this.checkInputs.bind(this);
		this.renderImagePreviewUpload = this.renderImagePreviewUpload.bind(this);
		this.renderAddButton = this.renderAddButton.bind(this);

		/*
		 * Image preview upload options
		 */
		this.ImagePreviewUploadOptions = {
			baseUrl: REST_POINT + '/image/sticker',
			timeout: 10000,
			fileFieldName: 'file',
			dataType: 'json',
			accept: 'image/png',
			multiple: false,
			requestHeaders: {
				Authorization: localStorage.getItem('loginToken')
			},
			beforeUpload: (files) => {
				if (!this.imageToUpload) return false;
				if (typeof files === 'string') return true;
				return files[0].size < 1024 * 1024 * 20;
			},
			uploading: () => {
				if (!this.uploading) this.uploading = true;
			},
			uploadSuccess: (res) => {
				this.setState({
					image: JSON.parse(res).message
				});
			},
			uploadError: (err) => {
				toast.error(
					<div>
						<b>UPLOAD IMMAGINE DI ANTEPRIMA FALLITO!</b>
						<p>{err.type} - {err.error}</p>
					</div>
				);
			},
			didChoose: (files) => {
				if (!files) return;
				let reader = new FileReader();
				reader.readAsDataURL(files[0]);
				reader.onloadend = () => {
					this.setState({
						preview: [reader.result]
					})
				};
				this.imageToUpload = files[0];
			}
		};
		/*
		 * Special label image upload options
		 */
		this.SpecialLabelImageUploadOptions = {
			baseUrl: REST_POINT + '/image/sticker',
			timeout: 10000,
			fileFieldName: 'file',
			dataType: 'json',
			accept: 'image/png',
			multiple: false,
			requestHeaders: {
				Authorization: localStorage.getItem('loginToken')
			},
			beforeUpload: (files) => {
				if (!this.labelImageToUpload) return false;
				if (typeof files === 'string') return true;
				return files[0].size < 1024 * 1024 * 20;
			},
			uploading: () => {
			},
			uploadSuccess: (res) => {
				this.setState({
					labelImage: JSON.parse(res).message
				});
			},
			uploadError: (err) => {
				toast.error(
					<div>
						<b>UPLOAD IMMAGINE ETICHETTA FALLITO!</b>
						<p>{err.type} - {err.error}</p>
					</div>
				);
			},
			didChoose: (files) => {
				if (!files) return;
				let reader = new FileReader();
				reader.readAsDataURL(files[0]);
				reader.onloadend = () => {
					this.setState({
						previewLabelImage: [reader.result]
					});
				};
				this.labelImageToUpload = files[0];
			}
		};
	}

	prepareRequest(request) {
		let reqBody = {
			kind: this.state.kind,
			identifier: this.state.identifier,
			name: this.state.name,
			// format: this.state.format,
			format: this.state.identifier.substring(0, 2),
			width: this.state.width,
			height: this.state.height,
			left: this.state.left,
			top: this.state.top,
			diameter: this.state.diameter,
			intra_right: this.state.intra_right,
			intra_bottom: this.state.intra_bottom,
			diameter_inner: this.state.diameter_inner,
			image: this.state.image ? this.state.image : '',
			labelImage: this.state.labelImage ? this.state.labelImage : ''
		};
		request(reqBody);
	}

	addModel() {
		this.prepareRequest(reqBody => {
			api.add_model(localStorage.getItem('loginToken'), reqBody)
				.then(res => {
					if (res.status === Status.CREATED) {
						toast.success(
							<div>
								<b>Modello creato!</b>
							</div>
						);
						this.updateModel();
					} else {
						toast.error(
							<div>
								<b>Modello non creato!</b>
								<p>{res.status} - {res.error}</p>
							</div>
						)
					}
				});
		});
	}

	updateModel() {
		this.prepareRequest(reqBody => {
			api.update_model(localStorage.getItem('loginToken'), reqBody)
				.then(res => {
					if (res.status === Status.OK) {
						toast.success(
							<div>
								<b>Modello aggiornato!</b>
							</div>
						)
					} else {
						toast.error(
							<div>
								<b>Modello non aggiornato!</b>
								<p>{res.status} - {res.error}</p>
							</div>
						)
					}
				});
		});
	}

	checkInputs() {
		let s = this.state;
		if (s.identifier === '') return false;
		if (s.name === '') return false;
		// if (s.format === '') return false;
		if (isNaN(s.width) || !isFinite(s.width)) return false;
		if (isNaN(s.height) || !isFinite(s.height)) return false;
		if (isNaN(s.left) || !isFinite(s.left)) return false;
		if (isNaN(s.top) || !isFinite(s.top)) return false;
		if (isNaN(s.diameter) || !isFinite(s.diameter)) return false;
		if (isNaN(s.intra_right) || !isFinite(s.intra_right)) return false;
		if (isNaN(s.intra_bottom) || !isFinite(s.intra_bottom)) return false;
		return !(isNaN(s.diameter_inner) || !isFinite(s.diameter_inner));
	}

	componentDidUpdate(prevProps, prevState) {
		/*
		  Invoked immediately after update occurs
		  This is not called after initial render
		  This is good place for DOM management and AJAX on each update
		*/
		// Check if I'm adding or updating
		if (prevState.identifier !== this.state.identifier) {
			// I did change identifier, time to check if this identifier exists
			api.get_sticker_by_identifier(localStorage.getItem('loginToken'), this.state.identifier)
				.then(res => {
					if (res.status === Status.OK) {
						// There is a sticker with this identifier, display info
						let mdl = res.model;
						this.setState({
							isAdding: false,
							kind: mdl.kind,
							name: mdl.name,
							format: mdl.format,
							width: mdl.width ? mdl.width : 0,
							height: mdl.height ? mdl.height : 0,
							left: mdl.left ? mdl.left : 0,
							top: mdl.top ? mdl.top : 0,
							diameter: mdl.diameter ? mdl.diameter : 0,
							intra_right: mdl.intra_right ? mdl.intra_right : 0,
							intra_bottom: mdl.intra_bottom ? mdl.intra_bottom : 0,
							diameter_inner: mdl.diameter_inner ? mdl.diameter_inner : 0,
							image: mdl.image ? mdl.image : ('images/' + mdl.identifier + '.png'),
							labelImage: mdl.labelImage ? mdl.labelImage : ''
						});
					} else if (res.status === Status.NOT_FOUND) {
						this.setState({
							isAdding: true
						});
					}
				});
		}
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({hasError: true});
		console.error(error, info);
		// You can also call your own log system here
	}

	renderImagePreviewUpload() {
		return (
			<React.Fragment>
				<div className="admindash-button-container">
					<ReactUploadFile inputName="ajax-upload-file-input-labelimage"
					                 options={this.SpecialLabelImageUploadOptions} chooseFileButton={
						<Button data-tip="Aggiungi immagine etichetta speciale (PNG)" data-for="upload-tooltip"
						        className='defaultcolor' style={{marginRight: 10}} waves='light' icon='star_border'/>
					}
					/>
					<ReactUploadFile inputName="ajax-upload-file-input-imagepreview"
					                 options={this.ImagePreviewUploadOptions} chooseFileButton={
						<Button data-tip="Aggiungi immagine di anteprima (PNG)" data-for="upload-tooltip"
						        className='defaultcolor' waves='light' icon='image'/>
					}
					/>
					{this.renderAddButton()}
				</div>
				{
					this.state.previewLabelImage &&
					<img className="admindash-preview-labelimage" src={this.state.previewLabelImage} alt=""/>
				}
				<ReactTooltip id="upload-tooltip"/>
			</React.Fragment>
		);
	}

	renderAddButton() {
		return (
			<Button className="actioncolor" style={{marginLeft: 10}} disabled={!this.checkInputs()} onClick={() => {
				this.state.isAdding ? this.addModel() : this.updateModel();
			}}>
				{this.state.isAdding ? "Aggiungi" : "Aggiorna"}
			</Button>
		);
	}

	render() {
		return (
			<div className="sticker-creator">
				<h5>Crea o aggiorna un modello</h5>
				<Row>
					<Col s={8}>
						<Row className="no-margin-row">
							<Input s={2} type="select" value={this.state.kind} label="Tipo" onChange={(e, val) => {
								this.setState({ kind: val });
							}}>
								{
									this.StickerKinds.map((kind, index) => {
										return <option key={index}>{kind}</option>
									})
								}
							</Input>
							<Input s={5} value={this.state.identifier} label="Codice modello*" onChange={(e, val) => {
								this.setState({ identifier: val });
							}}/>
							<Input s={5} value={this.state.name} label="Nome modello*" onChange={(e, val) => {
								this.setState({ name: val });
							}}/>
						</Row>
						{
							this.state.kind === 'R' &&
							<Row className="no-margin-row">
								<Input s={6} value={this.state.width === 0 ? '' : this.state.width} label="Larghezza (mm)*" onChange={(e, val) => {
									this.setState({ width: val });
								}}/>
								<Input s={6} value={this.state.height === 0 ? '' : this.state.height} label="Altezza (mm)*" onChange={(e, val) => {
									this.setState({ height: val });
								}}/>
							</Row>
						}
						{
							this.state.kind === 'C' &&
							<Row className="no-margin-row">
								<Input s={6} value={this.state.diameter === 0 ? '' : this.state.diameter} label="Diametro (mm)*" onChange={(e, val) => {
									this.setState({ diameter: val });
								}}/>
								<Input s={6} value={this.state.diameter_inner === 0 ? '' : this.state.diameter_inner} label="Diametro interno (mm)" onChange={(e, val) => {
									this.setState({ diameter_inner: val });
								}}/>
							</Row>
						}
						<Row className="no-margin-row">
							<Input s={6} value={this.state.left === 0 ? '' : this.state.left} label="Margine sinistro (mm)" onChange={(e, val) => {
								this.setState({ left: val });
							}}/>
							<Input s={6} value={this.state.top === 0 ? '' : this.state.top} label="Margine superiore (mm)" onChange={(e, val) => {
								this.setState({ top: val });
							}}/>
						</Row>
						<Row className="no-margin-row">
							<Input s={6} value={this.state.intra_right === 0 ? '' : this.state.intra_right} label="Margine interno orizzontale (mm)" onChange={(e, val) => {
								this.setState({ intra_right: val });
							}}/>
							<Input s={6} value={this.state.intra_bottom === 0 ? '' : this.state.intra_bottom} label="Margine interno verticale (mm)" onChange={(e, val) => {
								this.setState({ intra_bottom: val });
							}}/>
						</Row>
						{this.renderImagePreviewUpload()}
					</Col>
					<Col s={4}>
						<img style={{
							width: '50%',
							height: '50%'
						}} src={DOMAIN_REMOTE + '/' + this.state.image} alt=""/>
					</Col>
				</Row>
			</div>
		);
	}
}

StickerCreator.defaultProps = {};

StickerCreator.propTypes = {};

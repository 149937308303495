import React, { Component } from "react";
import {Button} from "react-materialize";
import {LABEL_DID_INIT} from "../routes/App";
import {DEFAULT_ZOOM, FabricCanvas, Kitchen} from "./FabricCanvas";
import {fabric} from 'fabric';

export let activeGrid = false;
export let gridSize = 35;
export let gridCanvas = null;

class CanvasButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showGrid: false
		};
		this.zoom = this.zoom.bind(this);
		this.zoomIn = this.zoomIn.bind(this);
		this.zoomOut = this.zoomOut.bind(this);
		this.resetZoom = this.resetZoom.bind(this);
		this.zoomOffset = 0.15;
	}

	zoom(value) {
		if (!LABEL_DID_INIT) return;
		let canvas = Kitchen.getCanvas();
		FabricCanvas.zoomCanvasMiddle(canvas, value);
	}
	zoomIn() {
		this.zoom(Kitchen.getCanvas().getZoom() + this.zoomOffset);
	}
	zoomOut() {
		this.zoom(Kitchen.getCanvas().getZoom() - this.zoomOffset);
	}
	resetZoom() {
		this.zoom(DEFAULT_ZOOM);
	}

	componentDidMount() {
		// Start the draw grid loop on its own
		gridCanvas = Kitchen.getCanvas();
		//gridCanvas.setWidth(Kitchen.getCanvas().getWidth());
		//gridCanvas.setHeight(Kitchen.getCanvas().getHeight());
	}

	static drawManagedGrid() {
		let canvasWidth = gridCanvas.width + 300, canvasHeight = gridCanvas.height + 300;
		gridCanvas.clearGrid();

		if (!activeGrid) {
			return;
		}

		for (let i = 0; i < (canvasHeight / gridSize); i++) {
			let line = new fabric.Line([i * gridSize, 0, i * gridSize, canvasHeight], {
				stroke: 'rgba(10, 10, 10, 0.1)',
				selectable: false,
				evented: false,
				id: '#grid-hor'
			});
			gridCanvas.add(line);
			line.bringForward(false);
		}
		for (let i = 0; i < (canvasWidth / gridSize); i++) {
			let line = new fabric.Line([0, i * gridSize, canvasWidth, i * gridSize], {
				stroke: 'rgba(10, 10, 10, 0.1)',
				selectable: false,
				evented: false,
				id: '#grid-vert'
			});
			gridCanvas.add(line);
			line.bringForward(false);
		}
	}

	render() {
		return (
			<div className="zoom-manager">
				<div>
					<Button waves="light" className="editor-button button-zoom" icon={this.state.showGrid ? "grid_off" : "grid_on"} onClick={
						() => this.setState({
							showGrid: !this.state.showGrid
						}, () => {
							activeGrid = !activeGrid;
							CanvasButtons.drawManagedGrid();
						})
					}/>
				</div>
				<div>
					<Button waves="light" className="editor-button button-zoom reset-zoom" icon="zoom_out_map" onClick={() => this.resetZoom()}/>
				</div>
				<div>
					<Button waves="light" className="editor-button button-zoom" icon="add" onClick={() => this.zoomIn()}/>
				</div>
				<div>
					<Button waves="light" className="editor-button button-zoom" icon="remove" onClick={() => this.zoomOut()}/>
				</div>
			</div>
		)
	}
}

export default CanvasButtons;
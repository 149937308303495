import React, { Component } from 'react';
import '../App.css';
import '../styles/react-joyride.css';

import '../styles/apptac.css';

import {NavBar} from '../components/NavBar';
import {CanvasContainer} from "../components/CanvasContainer"
import {LabelBuilder} from "../components/LabelBuilder"
import {PAPER_HEIGHT_MM, PAPER_WIDTH_MM, Printer, PrintManager, sleep} from "../components/PrintManager";
import {Button, Card, Col, Icon, Input, Preloader, Row, Table,} from "react-materialize";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {LabelFormats, LabelShapes, LabelTypes, ALL_MODELS, LabelFormatsTable} from "../components/Models";
import {removeFirstTime, updatePrintPreview} from "../components/editors/BaseEditor";
import {Kitchen} from "../components/FabricCanvas";
import {
	current_username,
	getLoginToken,
	needs_tutorial,
	removeLoginToken,
	setTutorial
} from "./Login";
import {Redirect} from "react-router-dom";
import {api, Status} from "../api";
import {DOMAIN_REMOTE} from "../index";
import ReactTooltip from "react-tooltip";
import {PrintRender} from "../components/PrintRender";
import {toast} from "react-toastify";
import {config} from "../config/config";
import Joyride from 'react-joyride';
import {fabric} from 'fabric';
import ConfirmLink from "react-confirm-dialog";
import {decompressFromUTF16} from 'lz-string';

export let PrintDirector;
export const FAKE_OFFSET = 5;

// THESE VALUES ARE JUST FOR PREVIEWS...
export const PREVIEWS_WIDTH = 595, PREVIEWS_HEIGHT = 842; // IN PIXELS! 96 dpi
export const PREVIEWS_PERCENT = 87;

export let JSON_Props = ['selectable', 'evented', 'id', 'my_type', 'filters'];

export class Label {
	constructor(image, svg, json, index, active) {
		this._image = image;
		this._svg = svg;
		this._json = json;
		this._json_undo = json;
		this._index = index;
		this._active = active;
	}
	get image() {
		return this._image;
	}
	set image(value) {
		this._image = value;
	}
	get svg() {
		return this._svg;
	}
	set svg(value) {
		this._svg = value;
	}
	get json() {
		return this._json;
	}
	set json(value) {
		this._json = value;
	}
	get index() {
		return this._index;
	}
	set index(value) {
		this._index = value;
	}
	get active() {
		return this._active;
	}
	set active(value) {
		this._active = value;
	}
}

export let forEachLabel = function(behaviour) {
	Labels.forEach((row) => {
		row.forEach((label) => {
			behaviour(label);
		});
	});
};

class PaperInfo {
	constructor(model, format, left_margin, top_margin, int_right_margin, int_bottom_margin, base_width, base_height, num_col, num_rows, image) {
		this._model = model;
		this._format = format;
		this._left_margin = left_margin;
		this._top_margin = top_margin;
		this._int_right_margin = int_right_margin;
		this._int_bottom_margin = int_bottom_margin;
		this._base_width = base_width;
		this._base_height = base_height;
		this._num_col = num_col;
		this._num_rows = num_rows;
		this._image = image;
	}

	get model() {
		return this._model;
	}

	set model(value) {
		this._model = value;
	}

	get format() {
		return this._format;
	}

	set format(value) {
		this._format = value;
	}

	get left_margin() {
		return this._left_margin;
	}

	set left_margin(value) {
		this._left_margin = value;
	}

	get top_margin() {
		return this._top_margin;
	}

	set top_margin(value) {
		this._top_margin = value;
	}

	get int_right_margin() {
		return this._int_right_margin;
	}

	set int_right_margin(value) {
		this._int_right_margin = value;
	}

	get int_bottom_margin() {
		return this._int_bottom_margin;
	}

	set int_bottom_margin(value) {
		this._int_bottom_margin = value;
	}

	get base_width() {
		return this._base_width;
	}

	set base_width(value) {
		this._base_width = value;
	}

	get base_height() {
		return this._base_height;
	}

	set base_height(value) {
		this._base_height = value;
	}

	get num_col() {
		return this._num_col;
	}

	set num_col(value) {
		this._num_col = value;
	}

	get num_rows() {
		return this._num_rows;
	}

	set num_rows(value) {
		this._num_rows = value;
	}

	get image() {
		return this._image;
	}

	set image(value) {
		this._image = value;
	}
}

export let Labels = [];

class CheckedValue {
	constructor(value, valid) {
		this._value = value;
		this._valid = valid;
	}
	get value() {
		return this._value;
	}
	set value(value) {
		this._value = value;
	}
	get valid() {
		return this._valid;
	}
	set valid(value) {
		this._valid = value;
	}
}

export let LABEL_DID_INIT = false;

class App extends Component {
	constructor(props) {
		super(props);
		this.canvasContainer = null;
		this.refModal = null;
		this.state = {
			model: '',
			format: '',
			modelImage: '',
			labelImage: '',
			requiredInputs: {
				width: new CheckedValue('', false),
				height: new CheckedValue('', false),
				diameter: new CheckedValue('', false),
				shape: new CheckedValue(LabelShapes[0], true)
			},
			optionalInputs: {
				internalDiameter: new CheckedValue('', false),
				leftMargin: new CheckedValue('', false),
				topMargin: new CheckedValue('', false),
				internalRightMargin: new CheckedValue('', false),
				internalBottomMargin: new CheckedValue('', false)
			},
			editingAll: false,
			multipleSelect: false,
			wantsLogout: false,
			remoteLabels: {
				'AA': [],
				'CM': [],
				'RT': [],
				'SM': [],
				'SP': []
			},
			status: 0,
			error: '',
			searchQuery: '',
			currentFormat: ALL_MODELS,
			closeModal: false,

			// RESERVED FOR LOAD MODAL
			loadedLabels: [],
			totalLabels: 0,
			pages: 0,
			wantsToLoad: false,
			currentLoadPage: 1,
            isLoadingModel: false,

			printHtml: <p/>,
			printHtmlMin: <p/>,
			visibleFinalPrint: false,

			// Joyride related
			joyrideOverlay: true,
			joyrideRunning: needs_tutorial,
			stepIndex: 0,
			steps: []
		};
		this.paperInfo = new PaperInfo(0, 0, 0, 0, 0, 0, 0, 0, '');
		this.createLabel = this.createLabel.bind(this);

		// Edit all/one
		this.onEditAll = this.onEditAll.bind(this);
		this.onEditOne = this.onEditOne.bind(this);

		// Multiple select
		this.toggleMultipleSelect = this.toggleMultipleSelect.bind(this);

		this.setupLabels = this.setupLabels.bind(this);

		this.saveLabels = this.saveLabels.bind(this);
		this.getLoadedLabels = this.getLoadedLabels.bind(this);
		this.onPageClick = this.onPageClick.bind(this);

		this.joyrideCallback = this.joyrideCallback.bind(this);
		this.addSteps = this.addSteps.bind(this);
		this.pushGlobalSteps = this.pushGlobalSteps.bind(this);

		// Print Render
		this.printRender = null;
		// Label Builder
		this.labelBuilder = null;
		// Printer
		this.printer = null;

		// Joyride reference
		this.joyride = null;
	}

	pushGlobalSteps() {
		if (this.state.joyrideRunning) {
			this.addSteps([
				{
					title: 'La tua area di lavoro',
					text: "Qui è possibile visualizzare l'etichetta sulla quale si sta lavorando e il suo contenuto. E' anche possibile effettuare lo zoom",
					selector: '#fabric-canvas',
					position: 'right',
					allowClicksThruHole: true,
					style: {
						back: {
							display: 'none'
						}
					}
				},
				{
					title: 'Il tuo foglio di anteprima',
					text: "E' possibile scegliere quale etichetta modificare oppure applicare la selezione multipla per modificarne più di una.",
					selector: '.print-preview',
					position: 'left',
					allowClicksThruHole: true
				},
				{
					title: 'I tuoi strumenti per creare',
					text: "Questa è la lista di strumenti utilizzabili per creare la tua etichetta.\nE' possibile aggiungere testi, immagini, forme, codici a barre, personalizzare lo sfondo e molto altro. Prova a creare un nuovo testo cliccando sulla prima icona!",
					selector: 'li[data-tip="Testo"]',
					position: 'right',
					allowClicksThruHole: true,
					style: {
						back: {
							display: 'none'
						},
						button: {
							display: 'none'
						}
					}
				}
			], () => this.joyride.next());
		} else {
			if (this.joyride) this.joyride.reset();
		}
	}

	getLoadedLabels() {
		// Retrieve structures from myself
		api.me(localStorage.getItem('loginToken'))
			.then(res => {
				switch (res.status) {
					case Status.OK:
						let me = res.account;
						if (me === null) {
							this.setState({
								wantsLogout: true
							});
							removeLoginToken();
							return;
						}

						this.setState({
							status: res.status,
							wantsToLoad: true,
							loadedLabels: res.account.structures || [],
							totalLabels: (res.account.structures || []).length,
						});

						break;
					default:
						this.setState({
							status: res.status,
							error: res.error
						})
				}
			});
	}

	componentDidMount() {
		let app = this;
		if (!localStorage.getItem('wantsToLoad')) {
			console.log('App loaded! Getting stickers...');
			api.get_stickers(localStorage.getItem('loginToken')).then(res => {
				if (res.status) {
					if (res.status === Status.OK) {
						let rl = app.state.remoteLabels;
						for (let i = 0; i < res.models.length; i++) {
							let model = res.models[i];
                            (rl[model.format] || rl["SP"]).push(model);
						}
						// I update the app only once for obvious reasons
						app.setState({
							remoteLabels: rl
						}, () => {
							setTimeout(() => {
								app.addSteps({
									title: 'Crea una nuova etichetta',
									text: 'Inizia selezionando il modello base della tua etichetta',
									selector: '#first-label',
									position: 'left',
									allowClicksThruHole: true,
									style: {
										button: {
											display: 'none'
										}
									}
								});
							}, 50);
						});
					} else {
						if (res.status === Status.UNAUTHORIZED) {
							localStorage.removeItem('loginToken');
							app.setState({wantsLogout: true});
						}
					}
					app.setState({status: res.status});
					if (res.error)
						app.setState({error: res.error});

				} else {
					app.setState({status: Status.BAD_REQUEST, error: res.error});
				}
			});
			// Retrieve tutorial flag from myself structure
			api.me(localStorage.getItem('loginToken'))
				.then(res => {
					switch (res.status) {
						case Status.OK:
							let me = res.account;
							if (me === null) {
								this.setState({
									wantsLogout: true
								});
								removeLoginToken();
								return;
							}
							setTutorial(me.tutorial || false);
							app.setState({
								joyrideRunning: needs_tutorial
							});
							break;
						default:
							app.setState({
								status: res.status,
								error: res.error
							})
					}
				});

		} else {
			console.log('App loaded! Getting saved labels...');
			localStorage.removeItem('wantsToLoad');
			this.getLoadedLabels();
		}
	}

	setupLabels(iw, ih, canvas_image) {
		// KEEP IN MIND THAT IW AND IH ARE PARSED FLOATS AND THEY ARE MM!!!
		// EVERYTHING INSIDE OPTIONALINPUTS IS A STRING! PARSE IT TO A FLOAT!
		let opt = this.state.optionalInputs;
		let pw = PAPER_WIDTH_MM, ph = PAPER_HEIGHT_MM; // IN MM
		let w = iw, h = ih; // NOT MODIFIED IW AND IH IN MM

		// Left Margin is valid?
		if (opt.leftMargin.valid) {
			// Modify pw
			pw -= parseFloat(opt.leftMargin.value) * 2;
		}

		// Top Margin is valid?
		if (opt.topMargin.valid) {
			// Modify ph
			ph -= parseFloat(opt.topMargin.value) * 2;
		}

		// IRM is valid?
		if (opt.internalRightMargin.valid) {
			// Modify base width (mm)
			iw += parseFloat(opt.internalRightMargin.value);
		}

		// IBM is valid?
		if (opt.internalBottomMargin.valid) {
			// Modify base height (mm)
			ih += parseFloat(opt.internalBottomMargin.value);
		}

		// NOT USING THIS ANYMORE!
		/*
		const tolerate = (div) => {
		  const tolerance = 0.6;
		  if (div - Math.trunc(div) < tolerance) {
			// div is "tolerated" - use truncated value instead of rounding
			return Math.trunc(div);
		  } else {
			// div is not "tolerated" - use rounding
			return Math.round(div);
		  }
		};*/

		let width_div = pw / iw, height_div = ph / ih;
		let numCols = Math.round(width_div);
		let numRows = Math.round(height_div);

		// Create Labels
		Labels = new Array(numRows);
		Labels.fill(null);
		let index = 0;
		for (let i = 0; i < numRows; i++) {
			Labels[i] = new Array(numCols);
			for (let j = 0; j < numCols; j++) {
				Labels[i][j] = new Label(canvas_image, '', [], index);
				// This makes every label active!
				Labels[i][j].active = true;
				index++;
			}
		}

		// Store every single useful information inside paper info
		// Remember that EVERY SINGLE INFO INSIDE PAPER INFO IS IN MM!
		this.paperInfo.model = this.state.model;
		this.paperInfo.format = this.state.format;

		// Left Margin (if not valid I will calculate it myself)
		if (opt.leftMargin.valid) {
			// Just use the parsed stored value in mm
			this.paperInfo.left_margin = parseFloat(opt.leftMargin.value);
		} else {
			// Calculate the left margin based on numCols
			this.paperInfo.left_margin = (pw - (iw * numCols)) / 2;
		}

		// Top Margin (if not valid I will calculate it myself)
		if (opt.topMargin.valid) {
			// Just use the parsed stored value in mm
			this.paperInfo.top_margin = parseFloat(opt.topMargin.value);
		} else {
			// Calculate the top margin based on numRows
			this.paperInfo.top_margin = (ph - (ih * numRows)) / 2;
		}

		// Int. Right Margin (if not valid it is 0)
		if (opt.internalRightMargin.valid) {
			// Just use the parsed stored value in mm
			this.paperInfo.int_right_margin = parseFloat(opt.internalRightMargin.value);
		} else {
			this.paperInfo.int_right_margin = 0;
		}

		// Int. Bottom Margin (if not valid it is 0)
		if (opt.internalBottomMargin.valid) {
			// Just use the parsed stored value in mm
			this.paperInfo.int_bottom_margin = parseFloat(opt.internalBottomMargin.value);
		} else {
			this.paperInfo.int_bottom_margin = 0;
		}

		// Base width/height (use the non-modified one)
		this.paperInfo.base_width = w;
		this.paperInfo.base_height = h;

		// Number of columns and rows (not that useful but ok)
		this.paperInfo.num_col = numCols;
		this.paperInfo.num_rows = numRows;

		this.paperInfo.image = this.state.modelImage;
	}

	isShapeRectangular() {
		// RECTANGULAR FAMILY
		return this.state.requiredInputs.shape.value.name === "Rectangle";
	}
	isShapeCircular() {
		// CIRCULAR FAMILY
		return this.state.requiredInputs.shape.value.name === "Circle";
	}

	/*
	 MAIN FUNCTION!
	 This gets called when the user hits the "Create" button
	 It does several checks on input validity and prepares the main label
	 */
	createLabel() {
		// Grab required inputs and optional inputs
		let req = this.state.requiredInputs;
		// let opt = this.state.optionalInputs;

		// If the user didn't input any shape
		if (!req.shape.value || !req.shape.valid)
			return;

		// Valid values for shape
		if (this.isShapeRectangular()) {
			if (!req.width.valid || !req.height.valid)
				return;
		} else if (this.isShapeCircular()) {
			if (!req.diameter.valid)
				return;
		}

		// Get input width and input height.
		// If we are doing a circle shape, iw and ih are equal to the diameter
		let iw, ih, obj;
		if (this.isShapeRectangular()) {
			iw = req.width.value;
			ih = req.height.value;
		} else if (this.isShapeCircular()) {
			iw = req.diameter.value;
			ih = req.diameter.value;
		}
		obj = req.shape.value.object;

		// I define here the last part of this function so I can call it whenever I want
		const finishSetup = (app, object) => {
			// iw and ih are float values, so we parse them (again?)
			iw = parseFloat(iw);
			ih = parseFloat(ih);

			// Initialize the print manager global entity with the printer instance and iw/ih
			PrintDirector = new PrintManager(app.printRender, iw, ih, FAKE_OFFSET, app.paperInfo);

			// Set state without setState so it doesn't update!!!
			app.canvasContainer.getFabricCanvas().state = {
				realWidth: PrintDirector.getRealSize().width,
				realHeight: PrintDirector.getRealSize().height,
				fakeWidth: PrintDirector.getFakeSize().width,
				fakeHeight: PrintDirector.getFakeSize().height
			};

			// Create the canvas with our object (MAIN GATEWAY!!!)
			app.canvasContainer.getFabricCanvas().createCanvas(object,
				app.state.format === LabelTypes.AA ? 10 : 0);
			LABEL_DID_INIT = true;

			// Retrieve canvas image for the first time so I can setup labels
			return new Promise((resolve) => {
				PrintDirector.getCanvasData().then((data) => {
					app.setupLabels(iw, ih, data.image);

                    // UPDATE: Always start with a color of white
                    const canvas = app.canvasContainer.getFabricCanvas().canvas;
                    const label = canvas.getObjects()[0];
                    label.fill = "#FFF";
                    const fake_label = canvas.getObjectByAttribute('id', 'FAKE_LABEL');
                    if (fake_label) {
                        fake_label.stroke = "#FFF";
                    }

					updatePrintPreview();
					resolve();
				});
			})
		};

		// Returns a promise
		return new Promise((resolve) => {
			// If state.labelImage is defined, it means that this label is special
			if (this.state.labelImage && this.state.labelImage !== '') {
				// Build the label image url
				let imgUrl = DOMAIN_REMOTE + '/' + this.state.labelImage, app = this;
				fabric.Image.fromURL(imgUrl, img => {
					finishSetup(app, img).then(() => resolve());
				});
			} else {
				// Finish the setup in sync
				finishSetup(this, obj).then(() => resolve());
			}
		});
	}

	async setupListeners() {
		let self = this, dragging = false;
		await sleep(50);
		let selectables = document.getElementsByClassName('selectable');
		if (selectables.length > 0) {
			for (let i = 0; i < selectables.length; i++) {
				let sel = selectables[i];
				sel.draggable = true;
				sel.onclick = (e) => {
					self.handleLabelClick(e);
				};
				sel.ondragstart = (e) => {
					e.preventDefault();
					dragging = true;
					self.handleLabelClick(e);
				};
				sel.onmouseenter = (e) => {
					e.preventDefault();
					if (dragging && self.state.multipleSelect) {
						self.handleLabelClick(e);
					}
				};
				sel.onmouseup = (e) => {
					e.preventDefault();
					dragging = false;
				};
			}
		}
		this.canvasContainer.getFabricCanvas().listenMouseWheel();
		removeFirstTime();
	}

	// SAVE / LOAD FUNCTIONS
	saveLabels(name) {
		if (!LABEL_DID_INIT) return;
		let c = Kitchen.getCanvas();
		let oldZoom = c.getZoom();
		c.zoomToPoint(new fabric.Point(c.width / 2, c.height / 2), 1);
		let json_array = [];
		forEachLabel((label) => json_array.push({
			json: label.json,
			image: label.image
		}));
		api.save_labels(getLoginToken(), name, json_array, this.state.model)
			.then(res => {
				if (res.status) {
					switch (res.status) {
						case Status.CREATED:
							toast('Etichetta salvata correttamente!', {type: "success"});
							// Changes are saved
							window.warnUnsaved = false;
							break;
						default:
							toast('Errore nel salvataggio etichetta!', {type: "error"});
					}
				} else {
					toast('Errore nel salvataggio etichetta!', {type: "error"});
				}
				c.zoomToPoint(new fabric.Point(c.width / 2, c.height / 2), oldZoom);
			});
	}

	static deactivateLabel(label) {
		let numActives = 0;
		forEachLabel(label => {
			if (label.active) numActives++
		});
		if (numActives === 1) return;
		let selectables = document.getElementsByClassName('selectable');
		let element = null;
		for (let i = 0; i < selectables.length; i++)
			if (label.index === i)
				element = selectables[i];
		label.active = false;
		element.classList.remove('editing');
	}

	static deactivateLabels() {
		let selectables = document.getElementsByClassName('selectable');
		for (let i = 0; i < selectables.length; i++)
			selectables[i].classList.remove('editing');
		forEachLabel((label) => label.active = false);
	}

	static activateLabel(label, noupdate, multiple) {
		let selectables = document.getElementsByClassName('selectable');
		let element = null;
		for (let i = 0; i < selectables.length; i++)
			if (label.index === i)
				element = selectables[i];
		label.active = true;
		element.classList.add('editing');
		let json = multiple ? JSON.stringify(Kitchen.getCanvas().toJSON(JSON_Props)) : JSON.stringify(label.json);
		const canvas = Kitchen.getCanvas();
		canvas.loadFromJSON(JSON.parse(json), () => {
			canvas.forEachObject(obj => {
				if (obj.type === 'image' && obj.filters.length > 0) {
					obj.applyFilters(() => {
						if (!noupdate) updatePrintPreview();
						canvas.renderAll();
					});
				}
			});
			if (!noupdate) updatePrintPreview();
			canvas.renderAll();
		});
	}

	static activateAllLabels() {
		let selectables = document.getElementsByClassName('selectable');
		for (let i = 0; i < selectables.length; i++)
			selectables[i].classList.add('editing');
		let json = Kitchen.getCanvas().toJSON(JSON_Props);
		forEachLabel((label) => {
			label.active = true;
			label.json = json;
		});
		updatePrintPreview();
	}

	handleLabelClick(e) {
		let td = e.target;
		let img = td.childNodes[0];
		if (td && img) {
			let x = parseInt(img.id.split('-')[0]), y = parseInt(img.id.split('-')[1]);
			if (!isNaN(x) && !isNaN(y)) {
				// Gateway to label here
				let correspondingLabel = Labels[y][x];

				// If I'm not multiple selecting, then when I click load the json from the label I'm going to load
				// Otherwise, if I'm multiple selecting and I click load the json from canvas and not from label

				if (!this.state.multipleSelect) {
					App.deactivateLabels();
					App.activateLabel(correspondingLabel, false, false); // noupdate false multiple false
				} else {
					correspondingLabel.active ?
						App.deactivateLabel(correspondingLabel) :
						App.activateLabel(correspondingLabel, false, true); // noupdate false multiple true
				}

			}
		}
	}

	onEditAll() {
		if (!LABEL_DID_INIT) return;
		if (!this.state.editingAll) {
			App.activateAllLabels();
			this.setState({editingAll: true});
		}
	}
	onEditOne() {
		if (!LABEL_DID_INIT) return;
		if (this.state.editingAll) {
			App.deactivateLabels();
			App.activateLabel(Labels[0][0]);
			this.setState({editingAll: false});
		}
	}

	toggleMultipleSelect(value) {
		if (!LABEL_DID_INIT) return;
		this.setState({
			multipleSelect: value
		});
	}

	onPageClick(new_page) {
		this.setState({
			currentLoadPage: new_page
		});
		this.getLoadedLabels(new_page);
	}

	resetInputs() {
		this.state.requiredInputs = {
			width: new CheckedValue('', false),
			height: new CheckedValue('', false),
			diameter: new CheckedValue('', false),
			shape: new CheckedValue(LabelShapes[0], true)
		};
		this.state.optionalInputs = {
			internalDiameter: new CheckedValue('', false),
			leftMargin: new CheckedValue('', false),
			topMargin: new CheckedValue('', false),
			internalRightMargin: new CheckedValue('', false),
			internalBottomMargin: new CheckedValue('', false)
		};
	}

	renderModelsTable(type) {
		let app = this;
		let state = this.state;
		if (state.status === Status.OK) {
			let modelList = [];
			if (state.currentFormat === ALL_MODELS) {
				for (let format in state.remoteLabels) {
					if (state.remoteLabels.hasOwnProperty(format)) {
						for (let j = 0; j < state.remoteLabels[format].length; j++)
							modelList.push(state.remoteLabels[format][j]);
					}
				}
			} else {
				modelList = state.remoteLabels[type];
			}
			return (
				<Table>
					<thead>
					<tr>
						<th data-field="preview">Anteprima</th>
						<th data-field="model">Modello</th>
						<th data-field="name">Nome</th>
						<th data-field="width">Larghezza (mm)</th>
						<th data-field="height">Altezza (mm)</th>
						<th data-field="diameter">Diametro (mm)</th>
						<th data-field="use">{''}</th>
					</tr>
					</thead>
					<tbody>
					{
						modelList.map((label, index) => {
							if (state.searchQuery !== '') {
								if (label.name.toLowerCase().indexOf(state.searchQuery.toLowerCase()) === -1
									&& label.identifier.toLowerCase().indexOf(state.searchQuery.toLowerCase()) === -1)
									return null;
							}
							let imgPath = label.image ? (DOMAIN_REMOTE + '/' + label.image) : (DOMAIN_REMOTE + '/images/' + label.identifier + '.png');
							let bgImg = label.identifier === '' ? '' : imgPath;
							let w = PREVIEWS_WIDTH - (PREVIEWS_WIDTH * (PREVIEWS_PERCENT / 100));
							let h = PREVIEWS_HEIGHT - (PREVIEWS_HEIGHT * (PREVIEWS_PERCENT / 100));
							return (
								<tr id={index === 0 ? 'first-label' : ''} className="bordertable-gray" key={label.identifier}>
									<td width={w} height={h}>
										<img width={w} height={h} src={bgImg} alt="Anteprima"/>
									</td>
									<td>{label.identifier}</td>
									<td>{label.name}</td>
									<td>{label.width ? label.width : ''}</td>
									<td>{label.height ? label.height : ''}</td>
									<td>{label.diameter ? label.diameter : ''}</td>
									<td>
										<Button className="actioncolor" onClick={()=>{
											this.resetInputs();

											// Use this model!
											state.model = label.identifier;
											state.format = label.format;
											state.labelImage = label.labelImage ? label.labelImage : '';
											state.modelImage = label.image ? label.image : DOMAIN_REMOTE + '/images/' + label.identifier + '.png';
											state.requiredInputs.shape.valid = true;
											let shape = null;
											LabelShapes.forEach((ls) => {
												if (ls.kind === label.kind) {
													shape = ls;
												}
											});
											state.requiredInputs.shape.value = shape;
											if (label.width) {
												state.requiredInputs.width.valid = true;
												state.requiredInputs.width.value = label.width;
											}
											if (label.height) {
												state.requiredInputs.height.valid = true;
												state.requiredInputs.height.value = label.height;
											}
											if (label.diameter) {
												state.requiredInputs.diameter.valid = true;
												state.requiredInputs.diameter.value = label.diameter;
											}

											if (label.left) {
												state.optionalInputs.leftMargin.valid = true;
												state.optionalInputs.leftMargin.value = label.left;
											}
											if (label.top) {
												state.optionalInputs.topMargin.valid = true;
												state.optionalInputs.topMargin.value = label.top;
											}
											if (label.diameter_inner) {
												state.optionalInputs.internalDiameter.valid = true;
												state.optionalInputs.internalDiameter.value = label.diameter_inner;
											}
											if (label.intra_right) {
												state.optionalInputs.internalRightMargin.valid = true;
												state.optionalInputs.internalRightMargin.value = label.intra_right;
											}
											if (label.intra_bottom) {
												state.optionalInputs.internalBottomMargin.valid = true;
												state.optionalInputs.internalBottomMargin.value = label.intra_bottom;
											}
											app.createLabel().then(() => {
												PrintDirector.printRender.setState({
													model: label
												});
												this.setupListeners();
												this.refModal.close();
												this.pushGlobalSteps();
												window.warnUnsaved = false;
											}).catch(() => {
												console.error("Critical failure while loading label");
											});

										}}>Seleziona</Button>
									</td>
								</tr>
							);
						})
					}
					</tbody>
				</Table>
			);
		} else {
			if (this.state.status === 0) {
				return (
					<Preloader flashing size="big"/>
				);
			} else if (this.state.status === Status.INTERNAL_SERVER_ERROR) {
				return (
					<Card className="red" textClassName="white-text" title="ERROR">
						Errore Server (Status: 500)<br/>Il server è offline o in manutenzione
					</Card>
				)
			} else {
				return (
					<Card className="red" textClassName="white-text" title="ERROR">
						Errore inaspettato! (Status: {this.state.status})
					</Card>
				)
			}
		}
	}

	renderLoadModels() {
		let app = this;
		let state = this.state;
		if (state.status === Status.OK) {
			return (
				<div>
					{
						state.loadedLabels.length > 0 &&
						<Table>
							<thead>
							<tr>
								<th>Modello</th>
								<th>Nome</th>
								<th>{''}</th>
								<th>{''}</th>
							</tr>
							</thead>
							<tbody>
							{
								state.loadedLabels.map((label) => {
									if (state.searchQuery !== '') {
										if (label.name.toLowerCase().indexOf(state.searchQuery.toLowerCase()) === -1)
											return null;
									}
									return (
										<tr className="bordertable-gray" key={label._id}>
											<td>{label.identifier}</td>
											<td>{label.name}</td>
											<td>
												<Button className="actioncolor" onClick={()=>{
													this.resetInputs();
                                                    this.setState({
                                                        isLoadingModels: true
                                                    });

													// Use this model!
													state.model = label.identifier;

													api.get_structure(getLoginToken(), label._id)
														.then(({ structure }) => {

															api.get_sticker_by_identifier(getLoginToken(), state.model)
																.then(data => {
																	let res = data.model;
																	state.format = res.format;
																	state.modelImage = DOMAIN_REMOTE + '/images/' + res.identifier + '.png';
																	state.requiredInputs.shape.valid = true;
																	let shape = null;
																	LabelShapes.forEach((ls) => {
																		if (ls.kind === res.kind) {
																			shape = ls;
																		}
																	});
																	state.requiredInputs.shape.value = shape;
																	if (res.width) {
																		state.requiredInputs.width.valid = true;
																		state.requiredInputs.width.value = res.width;
																	}
																	if (res.height) {
																		state.requiredInputs.height.valid = true;
																		state.requiredInputs.height.value = res.height;
																	}
																	if (res.diameter) {
																		state.requiredInputs.diameter.valid = true;
																		state.requiredInputs.diameter.value = res.diameter;
																	}

																	if (res.left) {
																		state.optionalInputs.leftMargin.valid = true;
																		state.optionalInputs.leftMargin.value = res.left;
																	}
																	if (res.top) {
																		state.optionalInputs.topMargin.valid = true;
																		state.optionalInputs.topMargin.value = res.top;
																	}
																	if (res.diameter_inner) {
																		state.optionalInputs.internalDiameter.valid = true;
																		state.optionalInputs.internalDiameter.value = res.diameter_inner;
																	}
																	if (res.intra_right) {
																		state.optionalInputs.internalRightMargin.valid = true;
																		state.optionalInputs.internalRightMargin.value = res.intra_right;
																	}
																	if (res.intra_bottom) {
																		state.optionalInputs.internalBottomMargin.valid = true;
																		state.optionalInputs.internalBottomMargin.value = res.intra_bottom;
																	}
																	app.createLabel().then(() => {
																		label.content = JSON.parse(decompressFromUTF16(structure.content));
																		forEachLabel((l) => {
																			l.json = label.content[l.index].json;
																			l.image = label.content[l.index].image;
																		});
																		let canvas = Kitchen.getCanvas();
																		canvas.loadFromJSON(label.content[0].json, () => {
																			// First object is always the rect label!
																			const rectLabel = canvas.getObjects()[0];
																			Kitchen.updateRectLabel(rectLabel);

																			// Store old top and left values
																			const oldRectTop = rectLabel.top;
																			const oldRectLeft = rectLabel.left;

																			// Center object to obtain new coordinates
																			canvas.centerObject(rectLabel);

																			// Store new top and left values
																			const newRectTop = rectLabel.top;
																			const newRectLeft = rectLabel.left;

																			// For each label, calculate new top and lefts accordingly
																			forEachLabel(l => {
																				l.json.objects.forEach((object, index) => {
																					if (index === 0) {
																						// This is the rect label
																						object.left = newRectLeft;
																						object.top = newRectTop;
																					} else {
																						// This is any other object
																						const diff_x = object.left - oldRectLeft;
																						const diff_y = object.top - oldRectTop;
																						object.left = newRectLeft + diff_x;
																						object.top = newRectTop + diff_y;
																					}
																				});
																			});

																			// Finally, render it all
																			canvas.loadFromJSON(label.content[0].json, () => {});
																		});
																		PrintDirector.printRender.setState({
																			printHtmlMin: PrintDirector.getPrintPreview().getMinifiedPrintHTML(),
																			model: res
																		});
																	});
																	this.setupListeners();
																	this.refModal.close();
																	app.setState({
                                                                        closeModal: true,
                                                                        isLoadingModels: false
                                                                    });

																});
														})
												}}>Seleziona</Button>
											</td>
											<td>
												<ConfirmLink action={() => {
													api.remove_saved_labels(getLoginToken(), label._id)
														.then((res) => {
															if (!res.error) {
																toast.success(<b>Etichetta eliminata correttamente!</b>);
																this.getLoadedLabels();
															} else {
																toast.error(<b>Errore durante l'eliminazione dell'etichetta!</b>);
															}
														})
														.catch(() => {
															toast.error(<b>Errore durante l'eliminazione dell'etichetta!</b>);
														});
												}} confirmMessage="Sei sicuro di voler eliminare questa etichetta? L'azione è irreversibile!"
									             confirmText="Sì" cancelText="No">
													<Button className="editor-button red" data-tip="Elimina" data-for="global-tooltip">
														<Icon className="editor-icon">delete_forever</Icon>
													</Button>
												</ConfirmLink>
											</td>
										</tr>
									);
								})
							}
							</tbody>
						</Table>
					}
					{
						/*state.loadedLabels.length === 0 &&
						 <span>Pagina vuota</span>*/
					}
				</div>
			);
		} else {
			if (this.state.status === 0) {
				return (
					<Preloader flashing size="big"/>
				);
			} else if (this.state.status === Status.INTERNAL_SERVER_ERROR) {
				return (
					<Card className="red" textClassName="white-text" title="ERROR">
						Errore Server (Status: 500)<br/>Il server è offline o in manutenzione
					</Card>
				)
			} else {
				return (
					<Card className="red" textClassName="white-text" title="ERROR">
						Errore inaspettato! (Status: {this.state.status})
					</Card>
				)
			}
		}
	}

	joyrideCallback(state) {
		if (state.type === 'finished' || state.isTourSkipped) {
			this.setState({
				joyrideRunning: false
			});
			api.update_account(localStorage.getItem('loginToken'), {
				tutorial: false
			});
		}
	}

	addSteps(steps, cb) {
		let newSteps = steps;

		if (!Array.isArray(newSteps)) {
			newSteps = [newSteps];
		}

		if (!newSteps.length) {
			return;
		}

		// Force setState to be synchronous to keep step order.
		this.setState(currentState => {
			currentState.steps = currentState.steps.concat(newSteps);
			return currentState;
		}, cb);
	}

	render() {
		let self = this;
		let state = this.state;
		let currentUser = (current_username ?
			current_username.charAt(0).toUpperCase() + current_username.slice(1) : 'Utente');
		const welcome_phrase =
			<p className="welcome-phrase">
				{
					config.welcome.map((str, index) => {
						return (
							<React.Fragment>
								{str.replace('{username}', current_username)}
								{
									index !== config.welcome.length - 1 &&
									<React.Fragment>,<br/></React.Fragment>
								}
							</React.Fragment>
						)
					})
				}
			</p>;

		return (
			<div className="App">
				<header className="App-header">
					<NavBar onSave={this.saveLabels}
					        username={"Ciao, " + currentUser}
					        printRender={this.printRender}
					        printer={this.printer}
					        labelBuilder={this.labelBuilder}/>
				</header>
				<div className="App-body">
					<ReactTooltip id="global-tooltip"/>
					{ state.wantsLogout && <Redirect to="/login"/> }
					<div id="start-modal">
						{
							needs_tutorial &&
							<Joyride
								ref={r => this.joyride = r}
								callback={this.joyrideCallback}
								debug={false}
								locale={{
									back: (<span>Indietro</span>),
									close: (<span>Chiudi</span>),
									last: (<span>Chiudi</span>),
									next: (<span>Successivo</span>),
									skip: (<span>Salta</span>),
								}}
								autoRun={true}
								run={state.joyrideRunning}
								showOverlay={state.joyrideOverlay}
								showSkipButton={true}
								showStepsProgress={true}
								steps={state.steps}
								type="continuous"
								disableOverlay={true}
							/>
						}
						{
                            // Per chiunque stia guardando questo pezzo di codice chiedendosi che cazzo stia succedendo:
                            // Praticamente questo cliente boomer ha chiesto di mettere un modal che mostra una CTA verso il suo ecoomerce boomerone
                            // Niente di che
							!state.wantsToLoad && window.location.hostname.includes("colorpremium") &&
							<PureModal
								isOpen
								width="60%"
								header={"OFFERTA DEL GIORNO"}
								actions={""}
							>
								<div className="no-print" id="new-model">
									<div className="no-print" id="models-import">
										<Row>
											<Col s={6}>
												<img className="colla-img" src="https://www.ingrossogrillo.com/281339-large_default/incollatutto-attak-loctite-super-attak-3gr-24pz-art2048080.jpg" alt/>
											</Col>
											<Col s={6}>
												<h4>INCOLLATUTTO ATTAK LOCTITE SUPER ATTAK 3gr 24pz</h4>
												<Button className="actioncolor" onClick={() => window.open("https://www.ingrossogrillo.com/colle-nastri-e-adesivi/7387-190148-incollatutto-attak-loctite-super-attak-3gr-24pz-art2048080-0800463088962.html#/357-colore-3gr")}>ACQUISTA ORA</Button>
											</Col>
										</Row>
									</div>
								</div>
							</PureModal>
						}
						<PureModal
							isOpen
							ref={(ref) => {this.refModal = ref;}}
							width="60%"
							onClose={() => {
								this.setupListeners();
								if (this.state.steps.length === 0) {
									this.setState({
										joyrideRunning: false
									});
								}
								if (this.joyride) this.joyride.reset();
								return true;
							}}
							header={
								state.wantsToLoad ? "CARICA MODELLO" : "CREA NUOVA ETICHETTA"
							}
							id="new-label-modal"
							actions={""}>
							{welcome_phrase}
							{
								!this.state.wantsToLoad &&
								<div className="no-print" id="new-model">
									<div className="no-print" id="models-import">
										<Row>
											<Input s={6} onChange={(e, val) => {
												this.setState({searchQuery: val});
											}} label="Ricerca"/>
											<Input s={6} type="select" label="Formato Etichetta" onChange={(e, val) => {
												this.setState({currentFormat: val});
											}}>
												{
													LabelFormats.map((format, index) => {
														return (
															<option key={index} value={format}>
																{format}
															</option>
														)
													})
												}
											</Input>
										</Row>
										{
											self.renderModelsTable(LabelFormatsTable[this.state.currentFormat])
										}
									</div>
								</div>
							}
							{
								this.state.wantsToLoad &&
								<div className="no-print" id="new-model">
									<div className="no-print" id="models-import">
										<Row className="no-margin-row">
											<Input s={12} onChange={(e, val) => {
												this.setState({searchQuery: val});
											}} label="Ricerca"/>
										</Row>
										{
                                            !this.state.isLoadingModels
                                                ? self.renderLoadModels()
                                                : (
                                                    <>
                                                        <p>Solo un momento, sto caricando le tue etichette nel programma...</p>
                                                        <Preloader/>
                                                    </>
                                                )
                                        }
									</div>
								</div>
							}
						</PureModal>
					</div>
					<CanvasContainer joyride={this.joyride}
					                 joyrideRunning={state.joyrideRunning}
					                 addSteps={this.addSteps}
					                 ref={(cc) => this.canvasContainer = cc}/>
					<LabelBuilder joyride={this.joyride}
					              joyrideRunning={state.joyrideRunning}
					              addSteps={this.addSteps} ref={(r) => this.labelBuilder = r}
					              canvasManager={this.canvasContainer}/>
					<PrintRender joyride={this.joyride}
					             joyrideRunning={state.joyrideRunning}
					             addSteps={this.addSteps} ref={(r) => this.printRender = r}
					             multipleSelect={this.state.multipleSelect}
					             toggleMultipleSelect={this.toggleMultipleSelect}
					             editAll={this.state.editingAll} onEditAll={this.onEditAll}
					             onEditOne={this.onEditOne}/>
					<Printer ref={r => this.printer = r} printRender={this.printRender}/>
				</div>
				<footer className="footer-app">
					<span className="footer-label">Designed by </span><img alt="" className="footer-image-align" src={DOMAIN_REMOTE + config.company_logo}/>
				</footer>
			</div>
		);
	}
}

///*<Button className="actioncolor" modal="close" onClick={() => { this.setupListeners(); }}>Chiudi</Button>,*/
export default App;

import React, { Component } from "react";
import {api, Status} from "../../api";
import {Preloader} from "react-materialize";
import {Redirect} from "react-router-dom";

export class AdminComponent extends Component {

  constructor() {
    super();
    this.state = {
      status: 0,
      is_admin: false
    }
  }

  componentDidMount() {
    api.me(localStorage.getItem('loginToken'))
      .then(res => {
        this.setState({
          status: res.status,
          is_admin: res.status === Status.OK ? res.account.role === 'admin' : false
        });
      });
  }

  renderLoading() {
    if (this.state.status === 0) {
      return <Preloader big className="centered"/>
    } else if (!this.state.is_admin) {
      return <Redirect to={{
        pathname: "/app"
      }}/>
    }
  }

}
import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import {Kitchen} from "./FabricCanvas"
import {BackgroundColor} from "./BackgroundColor";
import {UploadFile} from "./UploadFile";

import {TextEditor} from "./editors/TextEditor";
import {ImageEditor} from "./editors/ImageEditor";
import {ShapeEditor} from "./editors/ShapeEditor";
import {BarcodeEditor} from "./editors/BarcodeEditor";

export class SidebarEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.open,
      currentEditorType: ''
    };
    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this)
  }

  openSidebar(type) {
    this.props.onSidebarOpen(type);
    this.setState({
      isOpen: true,
      currentEditorType: type
    });
  }

  closeSidebar() {
    this.setState({
      isOpen: false,
      currentEditorType: ''
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        isOpen: nextProps.open,
        currentEditorType: nextProps.type,
      });
    }
  }

  handleStateChange(state) {
    this.props.onStateChange(state);
    this.setState({
      isOpen: state.isOpen
    });
    if (!state.isOpen) {
      this.setState({
        currentEditorType: ''
      });
    }
  }

  render() {
    let self = this;
    let props = this.props;
    return (
      <div className="no-print" id="sidebar-outer-container">
        <Menu customBurgerIcon={false} itemListClassName="background-sidebar"
              isOpen={this.state.isOpen}
              onStateChange={(state) => {
                if (!state.isOpen) {
                  props.onSidebarClose();
                  self.closeSidebar();
                }
              }}
              right noOverlay>
        </Menu>
        {
          Kitchen && Kitchen.getCanvas() &&
            <React.Fragment>
              <TextEditor currentEditorType={this.state.currentEditorType}
                          className="editor" id="editor-text"
                          openSidebar={this.openSidebar}
                          closeSidebar={this.closeSidebar}
                          joyride={this.props.joyride}
                          joyrideRunning={this.props.joyrideRunning}
                          addSteps={this.props.addSteps}
                          type="text"/>
              <ImageEditor currentEditorType={this.state.currentEditorType}
                           className="editor" id="editor-image"
                           openSidebar={this.openSidebar}
                           closeSidebar={this.closeSidebar}
                           type="image"/>
              <ShapeEditor currentEditorType={this.state.currentEditorType}
                           className="editor" id="editor-shape"
                           openSidebar={this.openSidebar}
                           closeSidebar={this.closeSidebar}
                           type="shape"/>
              <BarcodeEditor currentEditorType={this.state.currentEditorType}
                             className="editor" id="editor-code"
                             openSidebar={this.openSidebar}
                             closeSidebar={this.closeSidebar}
                             type="code"/>
              <BackgroundColor currentEditorType={this.state.currentEditorType} className="editor"
                               closeSidebar={this.closeSidebar}
                               type="bg-color"/>
              <UploadFile currentEditorType={this.state.currentEditorType} className="editor"
                          closeSidebar={this.closeSidebar}
                          type="union-upload"/>
            </React.Fragment>
        }
      </div>
    );
  }
}
import React, {Component} from 'react';
import {DEFAULT_ZOOM, FLAG_TIMETRAVELING, Kitchen, ZOOMED_COORDS} from "./FabricCanvas";
import {forEachLabel, Label, Labels, PrintDirector} from "../routes/App";
import {DOMAIN_REMOTE, REST_POINT} from "../index";
import ReactDOMServer from 'react-dom/server';
import {toast} from "react-toastify";
import {DocumentParsedInfo} from "./UploadFile";
import ReactUploadFile from "react-upload-file";
import {getLoginToken} from "../routes/Login";
import {gridCanvas} from "./CanvasButtons";
import {fabric} from 'fabric';

// Un foglio A4 ha le seguenti dimensioni (in px)
export const PAPER_WIDTH = 794;
export const PAPER_HEIGHT = 1123;
// In mm
export const PAPER_WIDTH_MM = 210;
export const PAPER_HEIGHT_MM = 297;
export const DPI = 96;
export const ZOOM_PREVIEW_PERCENT = 30;

export function mm2px(mm) {
	return (mm * DPI) / 25.4;
}
export function px2mm(px) {
	return (px * 25.4) / DPI;
}
export function applyZoom(value) {
	return value - (value * ZOOM_PREVIEW_PERCENT) / 100;
}

class PrintPreview {
	constructor(paper_info) {
		this.paper_info = paper_info;
	}

	getFinalPrintHTML(label_list) {
		// Final print HTML non ha bisogno di nessuno zoom: uso direttamente i dati di paper_info hehe
		let baseWidth = this.paper_info.base_width;
		let baseHeight = this.paper_info.base_height;
		let marginWidth = this.paper_info.left_margin;
		let marginHeight = this.paper_info.top_margin;
		let internalBottomMargin = this.paper_info.int_bottom_margin;
		let internalRightMargin = this.paper_info.int_right_margin;
		let numRows = this.paper_info.num_rows, numCols = this.paper_info.num_col;
		let labels = label_list;

		console.clear();
		console.log("====== FINAL PRINT SPECS =======");
		console.log("Everything is in MM!");
		console.log("baseWidth: " + baseWidth);
		console.log("baseHeight: " + baseHeight);
		console.log("marginWidth: " + marginWidth);
		console.log("marginHeight: " + marginHeight);
		console.log("internalRightMargin: " + internalRightMargin);
		console.log("internalBottomMargin: " + internalBottomMargin);
		console.log("================================");

		// Definizione di margine come riga vuota della tabella
		const MARGIN = function(key) {
			return (
				<tr>
					<td class='margin' key={'lmargin_'+key} style={{
						width: marginWidth + 'mm',
						height: marginHeight + 'mm'
					}}/>
					{
						labels[0].map((e, index) => {
							return ([
								<td class='margin' key={'mcol_' + key + '_' + index}
								    style={{
									    width: baseWidth + 'mm',
									    height: marginHeight  + 'mm'
								    }}/>,
								(internalRightMargin > 0 && index !== (numCols - 1)) &&
								<td class='margin' id='print-margin-internal' key={'irm_' + key + '_' + index}
								    style={{
									    width: internalRightMargin  + 'mm',
									    height: marginHeight  + 'mm'
								    }}/>
							]);
						})
					}
					<td class='margin' key={'rmargin_'+key} style={{
						width: marginWidth + 'mm',
						height: marginHeight + 'mm'
					}}/>
				</tr>
			);
		};

		// Definizione di margine interno come riga vuota della tabella INTERNA
		const INTERNAL_MARGIN = function(key) {
			return (
				<tr>
					<td key={'ilmargin_'+key} id='print-margin-internal' style={{
						width: marginWidth + 'mm',
						height: internalBottomMargin + 'mm'
					}}/>
					{
						labels[0].map((e, index) => {
							return ([
								<td class='margin' key={'imcol' + key + '_' + index}
								    style={{
									    width: baseWidth + 'mm',
									    height: internalBottomMargin + 'mm'
								    }}
								/>,
								(internalRightMargin > 0 && index !== (numCols - 1)) &&
								<td class='margin' id='print-margin-internal' key={'irm_' + key + '_' + index}
								    style={{
									    width: internalRightMargin + 'mm',
									    height: internalBottomMargin + 'mm'
								    }}/>
							]);
						})
					}
					<td key={'irmargin_'+key} id='print-margin-internal'
					    style={{
						    width: marginWidth + 'mm',
						    height: internalBottomMargin + 'mm'
					    }}
					/>
				</tr>
			);
		};

		// Ritorna la tabella costruita
		return (
			<table id='print-table'>
				<tbody>
				{MARGIN('upper')}
				{
					labels.map((row, row_index) => {
						return ([
							<tr key={'tr_' + row_index}>
								<td id="print-margin" key={'td1_' + row_index}
								    style={{
									    width: marginWidth + 'mm',
									    height: baseHeight + 'mm'
								    }}
								/>
								{
									row.map(
										(label, col_index) => {
											return ([
												<td id='print-cell' key={'bcol_' + label.index}
												    style={{
													    width: baseWidth + 'mm',
													    height: baseHeight + 'mm'
												    }}>
													<img className="canvas-img" src={label.image}
													     style={{
														     width: baseWidth + 'mm',
														     height: baseHeight + 'mm'
													     }}
													/>
												</td>,
												(internalRightMargin > 0 && col_index !== (numCols - 1)) &&
												<td id='print-margin-internal' key={'irm_' + label.index}
												    style={{
													    width: internalRightMargin + 'mm',
													    height: baseHeight + 'mm'
												    }}/>
											]);
										}
									)
								}
								<td id='print-margin' key={'td2_' + row_index}
								    style={{
									    width: marginWidth + 'mm',
									    height: baseHeight + 'mm'
								    }}
								/>
							</tr>,
							(internalBottomMargin > 0 && row_index !== (numRows - 1)) &&
							INTERNAL_MARGIN(row_index) // Bottom margin
						])
					})
				}
				{MARGIN('lower')}
				</tbody>
			</table>
		);
	}

	getMinifiedPrintHTML() {
		// MinifiedPrintHTML ha bisogno di dati zoomati ovviamente. Prendi i dati da paper_info e applica lo zoom
		let baseWidth = applyZoom(this.paper_info.base_width);
		let baseHeight = applyZoom(this.paper_info.base_height);
		let marginWidth = applyZoom(this.paper_info.left_margin);
		let marginHeight = applyZoom(this.paper_info.top_margin);
		let internalBottomMargin = applyZoom(this.paper_info.int_bottom_margin);
		let internalRightMargin = applyZoom(this.paper_info.int_right_margin);
		let numRows = this.paper_info.num_rows, numCols = this.paper_info.num_col;
		let labels = Labels;

		// Definizione di margine come riga vuota della tabella
		const MARGIN = function(key) {
			return (
				<tr>
					<td class="margin" key={'lmargin_'+key} style={{
						width: marginWidth + 'mm',
						height: marginHeight + 'mm'
					}}/>
					{
						labels[0].map((e, index) => {
							return ([
								<td class="margin" key={'mcol_' + key + '_' + index}
								    style={{
									    width: baseWidth + 'mm',
									    height: marginHeight + 'mm'
								    }}/>,
								(internalRightMargin > 0 && index !== (numCols - 1)) &&
								<td class="margin" id="print-margin-internal" key={'irm_' + key + '_' + index}
								    style={{
									    width: internalRightMargin + 'mm',
									    height: marginHeight + 'mm'
								    }}/>
							]);
						})
					}
					<td class="margin" key={'rmargin_'+key} style={{
						width: marginWidth + 'mm',
						height: marginHeight + 'mm'
					}}/>
				</tr>
			);
		};

		// Definizione di margine interno come riga vuota della tabella INTERNA
		const INTERNAL_MARGIN = function(key) {
			return (
				<tr>
					<td key={'ilmargin_'+key} id="print-margin-internal"
					    style={{
						    width: marginWidth + 'mm',
						    height: internalBottomMargin + 'mm'
					    }}/>
					{
						labels[0].map((e, index) => {
							return ([
								<td class="margin" key={'imcol' + key + '_' + index}
								    style={{
									    width: baseWidth + 'mm',
									    height: internalBottomMargin + 'mm'
								    }}/>,
								(internalRightMargin > 0 && index !== (numCols - 1)) &&
								<td class="margin" id="print-margin-internal" key={'irm_' + key + '_' + index}
								    style={{
									    width: internalRightMargin + 'mm',
									    height: internalBottomMargin + 'mm'
								    }}/>
							]);
						})
					}
					<td key={'irmargin_'+key} id="print-margin-internal"
					    style={{
						    width: marginWidth + 'mm',
						    height: internalBottomMargin + 'mm'
					    }}/>
				</tr>
			);
		};

		let index_row = 0, index_col = 0;
		let bgImg = 'url(' + (this.paper_info.image.indexOf(DOMAIN_REMOTE) === -1 ? DOMAIN_REMOTE + '/' : '') + this.paper_info.image + ')';
		if (this.paper_info.format === 'SP') {
			bgImg = '';
		}

		// Ritorna la tabella costruita
		return (
			<table className="print-preview z-depth-2" style={{
				backgroundImage: bgImg,
				backgroundSize: 'contain'
			}}>
				<tbody>
				{MARGIN('upper')}
				{
					labels.map((row, i) => {
						index_row = i;
						return ([
							<tr key={'tr_' + i}>
								<td class="margin" key={'td1_' + i}
								    style={{
									    width: marginWidth + 'mm',
									    height: baseHeight + 'mm'
								    }}/>
								{
									row.map(
										(label, j) => {
											index_col = j;
											let isEditing = false;
											forEachLabel((l) => {
												if (label.index === l.index) {
													isEditing = label.active;
												}
											});
											return ([
												<td class={"selectable" + (isEditing ? " editing" : "")} key={'bcol_' + j}
												    style={{
													    width: baseWidth + 'mm',
													    height: baseHeight + 'mm'
												    }}>
													<img className={"canvas-img"} id={index_col.toString() + "-" + index_row.toString()}
													     style={{
														     position: 'relative',
														     zIndex: '-10',
														     width: baseWidth + 'mm',
														     height: baseHeight + 'mm'
													     }} src={label.image}/>
												</td>,
												(internalRightMargin > 0 && j !== (numCols - 1)) &&
												<td class="margin" id="print-margin-internal" key={'irm_' + label.index}
												    style={{
													    width: internalRightMargin + 'mm',
													    height: baseHeight + 'mm'
												    }}/>
											]);
										}
									)
								}
								<td class="margin" key={'td2_' + i}
								    style={{
									    width: marginWidth + 'mm',
									    height: baseHeight + 'mm'
								    }}/>
							</tr>,
							(internalBottomMargin > 0 && index_row !== (numRows - 1)) &&
							INTERNAL_MARGIN(i) // Bottom margin
						])
					})
				}
				{MARGIN('lower')}
				</tbody>
			</table>
		);
	}
}

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
export const Alphabet = [
	'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
];

export class Printer extends Component {
	constructor(props) {
		super(props);
		this.upload = null;
		this.uploadOptions = {
			baseUrl: REST_POINT + '/structure/pdf',
			fileFieldName: 'html-file',
			requestHeaders: {
				Authorization: getLoginToken()
			},
			uploadSuccess: (res) => {
				if (JSON.parse(res).pdf_path) {
					toast.success('Stampa riuscita!');
					this.props.printRender.uploadSuccess(res);
				} else {
					toast.error('Stampa fallita!');
					this.props.printRender.uploadFailure(res);
				}
			},
			uploadError: (err) => {
				toast.error('Stampa fallita!');
				this.props.printRender.uploadFailure(err);
			}
		};
		this.htmlFileURL = '';
	}

	static resolveMerge(original_labels, print_render) {
		// Now in order to proceed I can also have just a normal series to resolve
		// So I must find out if I need to actually merge a series
		const NUMBERS = '{1,2,3...}';
		const LETTERS = '{A,B,C...}';

		// I have parsed info from file, merge!
		let canvas = Kitchen.getCanvas(),
			rectLabel = Kitchen.getRectLabel();

		print_render.setState({
			isMerging: true
		});

		// Calculate how many rows and how many cols (I need it later)
		let numCols = original_labels[0].length,
			numRows = original_labels.length;

		// Calculate how many labels I have in 1 page
		let numLabels = 0;
		forEachLabel(l => numLabels++);
		if (numLabels <= 0) {
			return {
				html: null,
				error: 'Numero di etichette non valido'
			};
		}

		// How many pages I need to print in order to fit every piece of info
		let pagesRequired = Math.ceil(DocumentParsedInfo.length / numLabels);
		if (pagesRequired === 0) pagesRequired = 1;

		// tables array needs to be filled with all the tables I generate
		let tables = new Array(pagesRequired);
		tables.fill(null); // null means empty table page

		print_render.setState({
			mergePages: pagesRequired
		});

		// Get keys I need to check for
		let keys = DocumentParsedInfo.length > 0 ? Object.getOwnPropertyNames(DocumentParsedInfo[0]) : [];

		// Divide big DocumentParsedInfo array into smaller paged chunks to process
		let parsedInfo = [];
		DocumentParsedInfo.forEach(dpi => parsedInfo.push(JSON.parse(JSON.stringify(dpi))));
		let PagedDocumentInfo = []; // Just value
		while (parsedInfo.length > 0)
			PagedDocumentInfo.push(parsedInfo.splice(0, numLabels));
		if (PagedDocumentInfo.length === 0) {
			// It means that I'm not doing any kind of merge union, just use 1 page
			PagedDocumentInfo = new Array(1);
			PagedDocumentInfo[0] = [];
		}

		// If you want to start again from 1 on each page, place this inside the loop!
		let serie = 1;
		// If you want to start again from A on each page, place this inside the loop!
		let letterIndex = 0;

		const retrimCanvas = (currentLabels, row_index, col_index) => {
			canvas.loadFromJSON(currentLabels[row_index][col_index].json, () => {
				let oldColor = canvas.backgroundColor, oldZoom = canvas.getZoom();
				canvas.backgroundColor = '#FFF';
				canvas.bringToFront(canvas.getObjectByAttribute('id', 'FAKE_LABEL'));
				canvas.discardSelection();
				canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), 1);
				let realSize = PrintDirector.getRealSize();
				if (rectLabel instanceof fabric.Circle) {
					currentLabels[row_index][col_index].image = canvas.toDataURL({
						format: 'jpeg',
						quality: 1,
						multiplier: 3,
						left: rectLabel.get('left') - realSize.width / 2,
						top: rectLabel.get('top') - realSize.height / 2,
						width: realSize.width,
						height: realSize.height
					});
				} else {
					currentLabels[row_index][col_index].image = canvas.toDataURL({
						format: 'jpeg',
						quality: 1,
						multiplier: 3,
						left: rectLabel.get('left'),
						top: rectLabel.get('top'),
						width: realSize.width,
						height: realSize.height
					});
				}
				canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), oldZoom);
				canvas.backgroundColor = oldColor;
				canvas.renderAll();
			});
		};

		PagedDocumentInfo.forEach((data, page_index) => {
			print_render.setState({
				mergePage: page_index + 1
			});

			let ii = 0, currentLabels = [], progress = 0;
			for (let i = 0; i < numRows; i++) {
				currentLabels[i] = new Array(numCols);
				for (let j = 0; j < numCols; j++) {
					currentLabels[i][j] = new Label(original_labels[i][j].image, '',
						JSON.parse(JSON.stringify(original_labels[i][j].json)),
						ii);
					ii++;
				}
			}

			// For each page go to every label and search for good text to replace :)
			currentLabels.forEach((row, row_index) => {
				row.forEach((label, col_index) => {

					let didMerge = false; // Becomes true when I modify text

					label.json.objects.forEach((fabric_object, object_index, objects) => {
						if (fabric_object.type === 'i-text') {
							let oldText = fabric_object.text;
							keys.forEach(key => {
								// Actual merge logic
								if (label.index < data.length) {
									currentLabels[row_index][col_index].json.objects[object_index].text
										= fabric_object.text.replace('{' + key + '}', data[label.index][key]);
								}
							});
							currentLabels[row_index][col_index].json.objects[object_index].text
								= fabric_object.text.replace(NUMBERS, serie);
							currentLabels[row_index][col_index].json.objects[object_index].text
								= fabric_object.text.replace(LETTERS, Alphabet[letterIndex]);
							didMerge = oldText !== objects[object_index].text;
						}
					});

					if (didMerge) {
						// I did modify something so I need to load the json on canvas and trim image
						retrimCanvas(currentLabels, row_index, col_index);
						// I did modify so I need to update series
						serie++; // Numbers continue to go up
						letterIndex++; // Letter index continues to go up but if I reach alphabet's end I restart
						if (letterIndex >= Alphabet.length) letterIndex = 0;
					}

					progress++;
					print_render.setState({
						mergeProgress: (progress * 100) / numLabels
					})

				});
			});

			// Once the replacement is done I create a new table with this info
			tables[page_index] = PrintDirector.getPrintPreview().getFinalPrintHTML(currentLabels);
		});

		// Before finishing, check if tables array isn't empty
		if (tables.every(table => { return table === null })) {
			return {
				html: null,
				error: 'Numero di etichette non valido'
			};
		}

		canvas.loadFromJSON(original_labels[0][0].json, () => canvas.renderAll());

		// Finally generate the final html and resolve the promise
		return {
			html:
				<div>
					{
						tables.map(table => {
							return table;
						})
					}
				</div>
		};
	}

	async printDocument(finalHtml) {
		await sleep(250);

		// CALL THE SERVER TO GET THE PDF FROM HTML STATIC MARKUP
		const style = `
      html {
        zoom: 0.75;
      }
      td.margin {
        border-spacing: 0;
        padding: 0;
        text-align: center;
        border: 0 solid black;
      }
      * {
        margin: 0 !important;
        padding: 0 !important;
      }
      #print-table {
        margin: 0;
        padding: 0;
        border-spacing: 0;
        border-collapse: collapse;
      }
      #print-cell {
        border-spacing: 0;
        border-collapse: collapse;
        padding: 0;
        text-align: center;
      }
      #print-margin {
        border-spacing: 0;
        border-collapse: collapse;
        padding: 0;
      }
      #print-margin-internal {
        border-spacing: 0;
        border-collapse: collapse;
        padding: 0;
      }
      .canvas-img {
        vertical-align: bottom;
      }
    `;

		let callHtml =
			<html>
			<head>
				<title>PDF</title>
				<style>
					{style}
				</style>
			</head>
			<body>
			{finalHtml}
			</body>
			</html>;

		let htmlString = ReactDOMServer.renderToStaticMarkup(callHtml);

		let data = new Blob([htmlString], {type: 'text/html'});
		if (!this.htmlFileURL) {
			window.URL.revokeObjectURL(this.htmlFileURL);
		}
		this.htmlFileURL = window.URL.createObjectURL(data);

		this.upload.manuallyUploadFile([data]);

		await sleep(100);
	}

	render() {
		let _this = this;
		return (
			<ReactUploadFile
				ref={(upload) => {
					if (upload !== null && upload !== undefined) {
						_this.upload = upload
					}
				}} options={this.uploadOptions}
				chooseFileButton={<button style={{display: 'none'}}/>}/>
		)
	}
}

export class PrintManager {
	constructor(print_render, real_width_mm, real_height_mm, fake_offset, paper_info) {
		this.printRender = print_render;
		this.realWidth = mm2px(real_width_mm);
		this.realHeight = mm2px(real_height_mm);
		this.fakeOffset = fake_offset;
		this.paper_info = paper_info; // DEFINED INSIDE APP.JS
		this.printPreview = new PrintPreview(this.paper_info);
	}

	getRealSize() {
		return ({
			width: this.realWidth,
			height: this.realHeight
		});
	}

	getFakeSize() {
		return ({
			width: this.realWidth - this.fakeOffset,
			height: this.realHeight - this.fakeOffset
		});
	}

	getOptionals() {
		return this.optionals;
	}

	getCanvasData() {
		const customZoomToCenter = (canvas, value) => {
			let point = new fabric.Point(canvas.width / 2, canvas.height / 2);
			let before = point;
			point = fabric.util.transformPoint(point, fabric.util.invertTransform(canvas.viewportTransform));
			canvas.viewportTransform[0] = value;
			canvas.viewportTransform[3] = value;
			let after = fabric.util.transformPoint(point, canvas.viewportTransform);
			canvas.viewportTransform[4] += before.x - after.x;
			canvas.viewportTransform[5] += before.y - after.y;
			canvas.renderAll();
			canvas.getObjects().forEach(obj => {
				if (obj) obj.setCoords();
			});
		};

		return new Promise((resolve) => {
			FLAG_TIMETRAVELING(true);
			let label = Kitchen.getRectLabel(), canvas = Kitchen.getCanvas();
			let oldSelection = canvas.getSelection();
			let fakeLabel = canvas.getObjectByAttribute('id', 'FAKE_LABEL');
			if (fakeLabel) canvas.bringToFront(fakeLabel);
			canvas.discardSelection();
			canvas.renderAll();
			let oldColor = canvas.backgroundColor, oldZoom = canvas.getZoom();
			canvas.backgroundColor = '#FFF';
			gridCanvas.clearGrid();
			customZoomToCenter(canvas, DEFAULT_ZOOM);
			let url;
			if (label instanceof fabric.Circle) {
				url = canvas.toDataURL({
					format: 'jpeg',
					quality: 1,
					multiplier: 3,
					left: ZOOMED_COORDS.left - ZOOMED_COORDS.width / 2,
					top: ZOOMED_COORDS.top - ZOOMED_COORDS.height / 2,
					width: ZOOMED_COORDS.width,
					height: ZOOMED_COORDS.height
				});
			} else {
				url = canvas.toDataURL({
					format: 'jpeg',
					quality: 1,
					multiplier: 3,
					left: ZOOMED_COORDS.left,
					top: ZOOMED_COORDS.top,
					width: ZOOMED_COORDS.width,
					height: ZOOMED_COORDS.height
				});
			}
			customZoomToCenter(canvas, oldZoom);
			canvas.backgroundColor = oldColor;
			if (oldSelection) {
				if (oldSelection instanceof fabric.Group) {
					canvas.remove(oldSelection);
					let newGroup = new fabric.Group(oldSelection.getObjects());
					canvas.setActiveObject(newGroup);
				}
				else
					canvas.setActiveObject(oldSelection);
			}
			canvas.renderAll();

			FLAG_TIMETRAVELING(false);
			resolve({image: url});
		});
	}

	getPrintPreview() {
		return this.printPreview;
	}

}

import React from "react";
import * as JsBarcode from "jsbarcode"
import {updatePrintPreview} from "./components/editors/BaseEditor";
import {toast} from "react-toastify";
import QRCode from "qrcode";
import {fabric} from 'fabric';

fabric.CurvedText = fabric.util.createClass(fabric.Object, {
	type: 'curved-text',
	diameter: 250,
	kerning: 0,
	text: '',
	flipped: false,
	fill: '#000',
	fontFamily: 'Times New Roman',
	fontSize: 24, // in px
	fontWeight: 'normal',
	fontStyle: '', // "normal", "italic" or "oblique".
	cacheProperties: (
		'fill stroke strokeWidth strokeDashArray width height' +
		' strokeLineCap strokeLineJoin strokeMiterLimit fillRule backgroundColor'
	).split(' ').concat('diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth'),
	strokeStyle: null,
	strokeWidth: 0,

	initialize: function(text, options) {
		this.text = text.text || text;

		this.callSuper('initialize', options || text);
		this.set('lockUniScaling', true);

		// Draw curved text here initially too, while we need to know the width and height.
		var canvas = this.getCircularText();
		this._trimCanvas(canvas);
		this.set('width', canvas.width);
		this.set('height', canvas.height);
	},

	_getFontDeclaration: function()
	{
		return [
			// node-canvas needs "weight style", while browsers need "style weight"
			(fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
			(fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
			this.fontSize + 'px',
			(fabric.isLikelyNode ? ('"' + this.fontFamily + '"') : this.fontFamily)
		].join(' ');
	},

	_trimCanvas: function(canvas)
	{
		var ctx = canvas.getContext('2d');
		ctx.imageSmoothingEnabled = false;

		var w = canvas.width,
			h = canvas.height,
			pix = {x:[], y:[]}, n,
			imageData = ctx.getImageData(0,0,w,h),
			fn = function(a,b) { return a-b };

		for (var y = 0; y < h; y++) {
			for (var x = 0; x < w; x++) {
				if (imageData.data[((y * w + x) * 4)+3] > 0) {
					pix.x.push(x);
					pix.y.push(y);
				}
			}
		}
		pix.x.sort(fn);
		pix.y.sort(fn);
		n = pix.x.length-1;

		w = pix.x[n] - pix.x[0];
		h = pix.y[n] - pix.y[0];
		var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

		canvas.width = w;
		canvas.height = h;
		ctx.putImageData(cut, 0, 0);
	},

	// Source: http://jsfiddle.net/rbdszxjv/
	getCircularText: function()
	{
		var text = this.text,
			diameter = this.diameter,
			flipped = this.flipped,
			kerning = this.kerning,
			fill = this.fill,
			inwardFacing = true,
			startAngle = 0,
			canvas = fabric.util.createCanvasElement(),
			ctx = canvas.getContext('2d'),
			cw, // character-width
			x, // iterator
			clockwise = -1; // draw clockwise for aligned right. Else Anticlockwise

		if (flipped) {
			startAngle = 180;
			inwardFacing = false;
		}

		startAngle *= Math.PI / 180; // convert to radians

		// Calc heigt of text in selected font:
		var d = document.createElement('div');
		d.style.fontFamily = this.fontFamily;
		d.style.whiteSpace = 'nowrap';
		d.style.fontSize = this.fontSize + 'px';
		d.style.fontWeight = this.fontWeight;
		d.style.fontStyle = this.fontStyle;
		d.textContent = text;
		document.body.appendChild(d);
		var textHeight = d.offsetHeight;
		document.body.removeChild(d);

		canvas.width = canvas.height = diameter;
		ctx.font = this._getFontDeclaration();

		// Reverse letters for center inward.
		if (inwardFacing) {
			text = text.split('').reverse().join('')
		}
		// Setup letters and positioning
		ctx.translate(diameter / 2, diameter / 2); // Move to center
		startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
		ctx.textBaseline = 'middle'; // Ensure we draw in exact center
		ctx.textAlign = 'center'; // Ensure we draw in exact center

		// rotate 50% of total angle for center alignment
		for (x = 0; x < text.length; x++) {
			cw = ctx.measureText(text[x]).width;
			startAngle += ((cw + (x === text.length-1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
		}

		// Phew... now rotate into final start position
		ctx.rotate(startAngle);

		// Now for the fun bit: draw, rotate, and repeat
		for (x = 0; x < text.length; x++) {
			cw = ctx.measureText(text[x]).width; // half letter
			// rotate half letter
			ctx.rotate((cw/2) / (diameter / 2 - textHeight) * clockwise);
			// draw the character at "top" or "bottom"
			// depending on inward or outward facing

			// Stroke
			if (this.strokeStyle && this.strokeWidth) {
				ctx.strokeStyle = this.strokeStyle;
				ctx.lineWidth = this.strokeWidth;
				ctx.miterLimit = 2;
				ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
			}

			// Actual text
			ctx.fillStyle = fill;
			ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));

			ctx.rotate((cw/2 + kerning) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
		}
		return canvas;
	},

	_set: function(key, value) {
		switch(key) {
			case 'scaleX':
				this.fontSize *= value;
				this.diameter *= value;
				this.width *= value;
				this.scaleX = 1;
				if (this.width < 1) { this.width = 1; }
				break;

			case 'scaleY':
				this.height *= value;
				this.scaleY = 1;
				if (this.height < 1) { this.height = 1; }
				break;

			default:
				this.callSuper('_set', key, value);
				break;
		}
	},

	_render: function(ctx)
	{
		var canvas = this.getCircularText();
		this._trimCanvas(canvas);

		this.set('width', canvas.width);
		this.set('height', canvas.height);

		ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);

		this.setCoords();
	},

	toObject: function(propertiesToInclude) {
		return this.callSuper('toObject', ['text', 'diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth'].concat(propertiesToInclude));
	}
});
fabric.CurvedText.fromObject = function(object, callback, forceAsync) {
	return fabric.Object._fromObject('CurvedText', object, callback);
};
fabric.CurvedText.async = false;

fabric.Canvas.prototype.getObjectByAttribute = function(attr, name) {
	let object = null,
		objects = this.getObjects();
	for (let i = 0, len = this.size(); i < len; i++) {
		if (objects && objects[i] && objects[i][attr] && objects[i][attr] === name) {
			object = objects[i];
			break;
		}
	}
	return object;
};
fabric.Canvas.prototype.getObjectsByAttribute = function(attr, name) {
	let objects_found = [],
		objects = this.getObjects();
	for (let i = 0, len = this.size(); i < len; i++) {
		if (objects && objects[i] && objects[i][attr] && objects[i][attr] === name) {
			objects_found.push(objects[i]);
		}
	}
	return objects_found;
};
fabric.Canvas.prototype.getSelection = function() {
	// Return an array with length n where n is the number of objects selected
	let activeObject = this.getActiveObject();
	if (activeObject) {
		return activeObject;
	}
	return null;
};
fabric.Canvas.prototype.discardSelection = function() {
	let activeObject = this.getActiveObject();
	if (activeObject) {
		this.discardActiveObject(null);
	}
};
fabric.Canvas.prototype.clearGrid = function() {
	let hor_lines = this.getObjectsByAttribute('id', '#grid-hor');
	let ver_lines = this.getObjectsByAttribute('id', '#grid-vert');
	hor_lines.forEach(l => this.remove(l));
	ver_lines.forEach(l => this.remove(l));
};

// Apply config
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.padding = 2;
fabric.Object.prototype.getAlpha = function() {
	return new fabric.Color(this.fill).getAlpha();
};
fabric.Object.prototype.setAlpha = function(alpha) {
	this.fill = new fabric.Color(this.fill).setAlpha(alpha).toRgba();
};
fabric.Object.prototype.getShadow = function() {
	return this.get('shadow');
};
fabric.Object.prototype.getShadowAlpha = function() {
	if (!this.shadow) return 0;
	return new fabric.Color(this.shadow.color).getAlpha();
};
fabric.Object.prototype.setShadowAlpha = function(alpha) {
	if (!this.shadow) return;
	this.shadow.color = new fabric.Color(this.shadow.color).setAlpha(alpha).toRgba();
};
fabric.Object.prototype.getStrokeAlpha = function() {
	if (!this.stroke) return;
	return new fabric.Color(this.stroke).getAlpha();
};
fabric.Object.prototype.setStrokeAlpha = function(alpha) {
	if (!this.stroke) return;
	this.stroke = new fabric.Color(this.stroke).setAlpha(alpha).toRgba();
};
fabric.Object.prototype.getWidth = function() {
	return this.get('width');
};
fabric.Object.prototype.getHeight = function() {
	return this.get('height');
};

class IRefCounted {
	constructor() {
		this.refCount = 0;
	}

	getRefCount() {
		return this.refCount;
	}

	incrementRefCount() {
		this.refCount++;
	}
	decrementRefCount() {
		this.refCount--;
	}
}

class CanvasEditor extends IRefCounted {
	constructor(canvas, rectLabel, baseId) {
		super();
		this.canvas = canvas;
		this.rectLabel = rectLabel;
		this.baseId = baseId;
	}

	getRefCount() {
		return super.getRefCount();
	}
	getCanvas() {
		return this.canvas;
	}
	getRectLabel() {
		return this.rectLabel;
	}
	getBaseId() {
		return this.baseId;
	}

	addObject(object, notActive, noUpdate) {
		object.set('id', this.getBaseId() + this.getRefCount());
		// Used to center every object automatically
		this.canvas.centerObject(object);
		this.canvas.add(object);
		if (!notActive) this.canvas.setActiveObject(object);
		this.canvas.renderAll();
		super.incrementRefCount();
		if (!noUpdate) updatePrintPreview();
		return object;
	}

	removeObject(id) {
		let object = this.canvas.getObjectByAttribute('id', id);
		if (object === null) {
			console.error('Object to delete not found with ID: ' + id);
			return -1;
		} else {
			super.decrementRefCount();
			this.canvas.remove(object);
			this.canvas.renderAll();
			return object.id;
		}
	}

	addRaw(object) {
		return this.addObject(object);
	}

	setRectLabel(rectLabel) {
		this.rectLabel = rectLabel;
	}
}

class TextManager extends CanvasEditor {

	add(text, fontFamily, fontSize, color, align, noActive) {
		return super.addObject(new fabric.IText(text, {
			fontFamily: fontFamily,
			fontSize: fontSize,
			fill: color,
			textAlign: align
		}), noActive);
	}

	addCurved(text, fontFamily, fontSize, color, align) {
		return super.addObject(new fabric.CurvedText(text, {
			fontFamily: fontFamily,
			fontSize: fontSize,
			fill: color,
			textAlign: align
		}));
	}

	remove(id) {
		return super.removeObject(id);
	}
}

class ShapeManager extends CanvasEditor {

	addRect(width, height, color, rx, ry) {
		return super.addObject(new fabric.Rect({
			width: width,
			height: height,
			fill: color,
			rx: rx,
			ry: ry
		}));
	}

	addCircle(radius, color) {
		return super.addObject(new fabric.Circle({
			radius: radius,
			fill: color
		}));
	}

	addTriangle(width, height, color) {
		return super.addObject(new fabric.Triangle({
			width: width,
			height: height,
			fill: color
		}));
	}

	addIcon(svg_container, color) {
		let svgString = new XMLSerializer().serializeToString(svg_container);
		fabric.loadSVGFromString(svgString, (objects, options) => {
			let obj = fabric.util.groupSVGElements(objects, options);
			obj.scaleToWidth(100, false);
			obj.scaleToHeight(100, false);
			obj.set({
				fill: color
			});
			obj.my_type = 'icon';
			obj.toggle('objectCaching');
			super.addObject(obj);
		});
		return super.getBaseId() + super.getRefCount();
	}

	remove(id) {
		return super.removeObject(id);
	}
}

class ImageManager extends CanvasEditor {
	add(url) {
		return fabric.Image.fromURL(url, (img) => {
			let rectLabel = super.getRectLabel();
			if (img.getWidth() > rectLabel.getWidth()) img.scaleToWidth(rectLabel.getWidth(), false);
			if (img.getHeight() > rectLabel.getHeight()) img.scaleToHeight(rectLabel.getHeight(), false);
			return super.addObject(img);
		});
	}

	remove(id) {
		return super.removeObject(id);
	}
}

export const DEFAULT_CODE_FORMAT = "EAN13";
export const BC_CONTAINER = 'barcode-canvas';
export class BarcodeManager extends CanvasEditor {
	constructor(canvas, label, baseId) {
		super(canvas, label, baseId);
	}
	static generateBarcode(code, format) {
		try {
			const container = document.getElementById(`${BC_CONTAINER}`); // canvas
			JsBarcode(container, code, {
				fontOptions: "normal",
				format: format
			});
			return container.toDataURL("image/png");
		} catch (err) {
			toast.error(
				<div>
					<b>Il testo non rispetta il formato!</b>
					<p>Riprovare</p>
				</div>
			);
			return '';
		}
	}

	_add(code, format) {
		const base64 = BarcodeManager.generateBarcode(code, format);
		return fabric.Image.fromURL(base64, img => {
			let rectLabel = super.getRectLabel();
			if (img.getWidth() > rectLabel.getWidth()) img.scaleToWidth(rectLabel.getWidth(), false);
			if (img.getHeight() > rectLabel.getHeight()) img.scaleToHeight(rectLabel.getHeight(), false);
			img.my_type = 'barcode';
			return super.addObject(img);
		});
	}

	add(code) {
		return this._add(code, DEFAULT_CODE_FORMAT);
	}

	remove(id) {
		return super.removeObject(id);
	}
}

class QRCodeManager extends CanvasEditor {
	constructor(canvas, label, baseId) {
		super(canvas, label, baseId);
	}

	add(code) {
		return QRCode.toDataURL(code)
			.then(url => {
				return fabric.Image.fromURL(url, img => {
					let rectLabel = super.getRectLabel();
					if (img.getWidth() > rectLabel.getWidth()) img.scaleToWidth(rectLabel.getWidth(), false);
					if (img.getHeight() > rectLabel.getHeight()) img.scaleToHeight(rectLabel.getHeight(), false);
					img.my_type = 'qr';
					return super.addObject(img);
				});
			})
			.catch(err => {
				toast.error(
					<div>
						<b>{err}</b>
					</div>
				);
				return null;
			});
	}

	remove(id) {
		return super.removeObject(id);
	}
}

class KitchenSink extends CanvasEditor {
	constructor(canvas, label) {
		super(canvas, label);
		this.textManager = new TextManager(canvas, label, 1000);
		this.shapeManager= new ShapeManager(canvas, label, 2000);
		this.imageManager = new ImageManager(canvas, label, 3000);
		this.barcodeManager = new BarcodeManager(canvas, label, 4000);
		this.qrcodeManager = new QRCodeManager(canvas, label, 5000);
	}

	updateRectLabel(label) {
		super.setRectLabel(label);
		this.textManager.setRectLabel(label);
		this.shapeManager.setRectLabel(label);
		this.imageManager.setRectLabel(label);
		this.barcodeManager.setRectLabel(label);
		this.qrcodeManager.setRectLabel(label);
	}

	Text() {
		return this.textManager;
	}
	Shape() {
		return this.shapeManager;
	}
	Image() {
		return this.imageManager;
	}
	Barcode() {
		return this.barcodeManager;
	}
	QRCode() {
		return this.qrcodeManager;
	}

	getObjects() {
		return this.canvas.getObjects();
	}
}

export default KitchenSink;

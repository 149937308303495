import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactTable from "react-table";
import {current_username, getLoginToken} from "../../routes/Login";
import {api, Status} from "../../api";

import 'react-table/react-table.css'
import {DOMAIN_REMOTE} from "../../index";
import {Button, Icon, Input, Row} from "react-materialize";

export class NewsManager extends Component {
  constructor() {
    super();
    this.state = {
      news: [],
      content: '',
      status: 0
    };
    this.grabNews = this.grabNews.bind(this);
  }

  grabNews() {
    // Grab news
    api.get_news(getLoginToken())
      .then(res => {
        if (res.status === Status.OK) {
          this.setState({
            status: res.status,
            news: res.news
          });
        } else {
          toast.error("Errore nel recupero delle notizie [" + res.status + "]");
        }
      });
  }

  componentDidMount() {
    this.grabNews();
  }

  addNews() {
    if (this.state.content === '') return;

    api.add_news(getLoginToken(), this.state.content, current_username)
      .then(res => {
        if (res.status === Status.CREATED) {
          this.grabNews();
          toast.success("Notizia aggiunta con successo");
        } else {
          toast.error("Errore nell'aggiunta della notizia! [" + res.status + "]", {
            autoClose: 8000
          });
        }
      });
  }

  removeNews(id) {
    if (!window.confirm("Sei sicuro di voler rimuovere la notizia? L'azione è irreversibile")) return;

    api.remove_news(getLoginToken(), id)
      .then(res => {
        if (res.status === Status.OK) {
          this.grabNews();
          toast.success("Notizia eliminata!");
        } else {
          toast.error("Errore nell'eliminazione della notizia! [" + res.status + "]", {
            autoClose: 8000
          });
        }
      });
  }

  render() {
    const columns = [{
      Header: 'Creatore',
      accessor: 'createdBy'
    },{
      Header: 'Contenuto',
      accessor: 'content'
    },{
      Header: 'Azioni',
      accessor: '',
      Cell: props => (
        <Button data-tip="Rimuovi" data-for="models-tooltip" className='redcolor editor-button table-button' waves='light' onClick={() => {
          this.removeNews(props.original._id);
        }}>
          <Icon className="editor-icon">delete_forever</Icon>
        </Button>
      )
    }];
    return (
      <div className="news-manager">
        <h5>Aggiungi Notizia</h5>
        <b>Attenzione! La notizia non deve essere troppo lunga.</b>
        <Row>
          <Input s={5} className="news-input" label="Contenuto Notizia" onChange={(e, val) => {
            this.setState({
              content: val
            });
          }}/>
          <Button s={1} onClick={() => this.addNews()} className="editor-button margintop20 actioncolor" icon="add"/>
        </Row>
        <h5>Lista Notizie</h5>
        {
          this.state.status === Status.OK &&
          <div className="sticker-table-container">
            <ReactTable
              data={this.state.news}
              columns={columns}
              style={{
                height: "600px"
              }}
              defaultPageSize={40}/>
          </div>
        }
      </div>
    )
  }

}
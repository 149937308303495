import { REST_POINT } from "./index"
import * as axios from "axios";
import { compressToUTF16 } from "lz-string";

export const Status = {
	OK: 200,
	CREATED: 201,
	ACCEPTED: 202,
	NO_CONTENT: 204,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	CONFLICT: 409,
	INTERNAL_SERVER_ERROR: 500
};

export const getQueryVariable = function(variable) {
	let query = window.location.search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	console.err('Query variable %s not found', variable);
};

export class api {
	/*
	Login using username and password

	@param username Username
	@param password Password
	@returns:
	- 200 if call is successfull and login info correct
	- 401 if login info is wrong
	- 500 if server doesn't respond
	 */
	static login(username, password) {
		return axios.post(REST_POINT + '/account/login', {
			username: username,
			password: password
		}).then(response => {
			return {
				status: Status.OK,
				tutorial: response.data.tutorial,
				token: response.data.token
			}
		}).catch(error => {
			if (error.response) {
				// The request was made and the server responded with a status code
				return {
					status: error.response.status
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Register a new account in the system

	@param email Email address
	@param username Username
	@param password Password
	@param region Region where the user belongs to
	@returns:
	- 201 if user is created properly
	- 400 if input is bad
	- 409 if the user is already registered
	- 500 if the server doesn't respond
	 */
	static register(email, username, password, region) {
		return axios.post(REST_POINT + '/account/sign-up', {
			email: email,
			username: username,
			password: password,
			region: region,
			role: 'user'
		}).then(response => {
			return {
				status: Status.CREATED,
				message: response.data.message,
			}
		}).catch(error => {
			if (error.response) {
				// The request was made and the server responded with a status code
				return {
					status: error.response.status,
					error: error.response.data.error
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: 'Internal Server Error (500)'
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Activate an account
	 */
	static activate_account(code) {
		return axios.get(REST_POINT + '/account/active/' + code).then(res => {
			return {
				status: Status.OK,
				message: res.data.message
			}
		}).catch(err => {
			return {
				status: err.response.status,
				message: err.response.data.error
			}
		});
	}

	/*
	Grab every single sticker from remote db

	@returns:
	- 200 if call successful
	- 403 if token isn't valid anymore (unauthorized)
	 */
	static get_stickers(login_token) {
		return axios.get(REST_POINT + '/sticker', {headers: {Authorization: login_token}})
			.then(res => {
				return {
					status: Status.OK,
					models: res.data
				}
			}).catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					return {
						status: error.response.status,
						error: error.response.data.error
					}
				} else if (error.request) {
					// No response from server
					return {
						status: Status.INTERNAL_SERVER_ERROR,
						error: 'Internal Server Error (500)'
					}
				} else {
					// Something happened in setting up the request that triggered an error
					return {
						error: error.message,
					}
				}
			});
	}

	/*
	Remove a sticker by identifier from remote db (must be admin)

	@returns
	- 200 if call successful
	- 404 if sticker not found
	- 403 if token isn't valid anymore or if caller isn't admin (unauthorized)
	 */
	static remove_sticker(login_token, sticker_identifier) {
		return axios.delete(REST_POINT + '/sticker/' + sticker_identifier, {headers: {Authorization: login_token}})
			.then(res => {
				return {
					status: Status.OK,
					message: res.data.message
				}
			})
			.catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					return {
						status: error.response.status,
						error: error.response.data.message
					}
				} else if (error.request) {
					// No response from server
					return {
						status: Status.INTERNAL_SERVER_ERROR,
						error: 'Internal Server Error (500)'
					}
				} else {
					// Something happened in setting up the request that triggered an error
					return {
						status: Status.BAD_REQUEST,
						error: error.message,
					}
				}
			})
	}

	/*
	Save label structure to remote db

	@param name Name of structure
	@param content Array of label json
	@param identifier Model name
	@returns:
	- 201 if call successful
	- 400 if save error
	- 500 internal server error
	 */
	static save_labels(login_token, name, content, identifier) {
		return axios.post(REST_POINT + '/structure', {
			content: compressToUTF16(JSON.stringify(content)),
			name, identifier
		}, {
			headers: { Authorization: login_token }
		}).then(res => {
			return {
				status: res.status
			}
		}).catch(error => {
			if (error.response) {
				return {
					status: error.response.status,
					error: error.response.data.error
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: 'Internal Server Error (500)'
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Get a specific structure from remote db

	@param id structure id
	@returns:
	- 200 + call result
	- 400 bad request
	- 500 internal server error
	 */
	static get_structure(login_token, id) {
		return axios.get(REST_POINT + '/structure/' + id, {headers: {Authorization: login_token}}).then(res => ({
			status: res.status,
			structure: res.data
		})).catch(error => {
			if (error.response) {
				return {
					status: error.response.status,
					error: error.response.data.error
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: 'Internal Server Error (500)'
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Remove label structure from remote db

	@param id Id of structure
	@returns:
	- 201 if call successful
	- 400 if delete error
	- 500 internal server error
	 */
	static remove_saved_labels(login_token, structure_id) {
		return axios.delete(REST_POINT + '/structure/' + structure_id, {headers: {Authorization: login_token}})
			.then(res => {
				return {
					status: res.status
				}
			}).catch(error => {
				if (error.response) {
					return {
						status: error.response.status,
						error: error.response.data.error
					}
				} else if (error.request) {
					// No response from server
					return {
						status: Status.INTERNAL_SERVER_ERROR,
						error: 'Internal Server Error (500)'
					}
				} else {
					// Something happened in setting up the request that triggered an error
					return {
						error: error.message,
					}
				}
			});
	}

	static get_sticker_by_identifier(login_token, identifier) {
		return axios.get(REST_POINT + '/sticker/identifier/' + identifier, {headers: {Authorization: login_token}}, {
		}).then(res => {
			return {
				status: res.status,
				model: res.data
			}
		}).catch(error => {
			if (error.response) {
				return {
					status: error.response.status,
					error: error.response.data.error
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: 'Internal Server Error (500)'
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Upload a new XLS document to get JSON

	@param document XLS document to feed server
	@returns:
	- 201 + parsed JSON if call successful
	- 400 if bad request
	- 403 if unauthorized
	 */
	static upload_document(login_token, document) {
		let formData = new FormData();
		formData.append('file', document);
		return axios.post(REST_POINT + '/structure/union',
			formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: login_token
				}
			}).then(res => {
			return {
				status: res.status,
				parsed: res.data
			}
		}).catch(error => {
			if (error.response) {
				return {
					status: error.response.status,
					error: error.response.message
				}
			} else if (error.request) {
				// No response from server
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: 'Internal Server Error (500)'
				}
			} else {
				// Something happened in setting up the request that triggered an error
				return {
					error: error.message,
				}
			}
		});
	}

	/*
	Convert static html markup to PDF

	@param html Static html markup
	@returns
	- 200 + pdf path if call successful
	- 403 if unauthorized
	- 500 internal server error
	 */
	static html_to_pdf(login_token, html) {
		return axios.post(REST_POINT + '/structure/pdf', {
			html: html
		}, {headers: {Authorization: login_token}}).then(res => {
			return {
				status: res.status,
				pdf_path: res.data.pdf_path.filename
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.message
			}
		});
	}

	/*
	Set resetCode inside account entry in database
	Sends resetCode through mail specified in account

	@param username Account username
	@returns
	- 204 if mail got send and resetCode created
	- 400 if error occured
	- 401 if unauthorized
	*/
	static forgot_password(username) {
		return axios.post(REST_POINT + '/account/forgot', {
			username: username
		}).then(res => {
			return {
				status: res.status
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.message
			}
		});
	}

	/*
	Get info about the account by login token

	@returns
	- account json if login token valid
	- 403 if login token invalid
	 */
	static me(login_token) {
		return axios.get(REST_POINT + '/account/me', {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: Status.OK,
				account: res.data
			}
		}).catch(err => {
			if (err.response) {
				return {
					status: err.response.status,
					error: err.response.error
				}
			} else {
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: "Server offline o in manutenzione"
				}
			}
		})
	}

	/*
	Update info about user

	* @authentication This route requires JWT Authentication. If authentication fails it will return a 401 error.
	* @headerparam {String} authorization like standard JWT Authentication: `JWT .....`
	* @bodyparam {String} username is the unique name to perform login
	* @bodyparam {String} password is plain text secret password to perform login
	* @bodyparam {String} email is the address to receive communication from platform
	* @bodyparam {String} city is place where user live
	* @bodyparam {String} zipCode is unique code of city
	* @bodyparam {String} phoneNumber is number to communicate whit user
	* @bodyparam {String} vatNumber unique number of company
	* @bodyparam {String} company is name of company ACME
	* @returns
	* - account json if call successful
	* - 403 if login token invalid
	*/
	static update_account(login_token, info) {
		return axios.put(REST_POINT + '/account/me', info, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: Status.OK,
				account: res.data
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.message
			}
		})
	}

	/*
	Add a new model to the database
	 */
	static add_model(login_token, model) {
		return axios.post(REST_POINT + '/sticker', model, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: res.status,
				account: res.data
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.response.error
			}
		})
	}

	/*
	Update existing model
	 */
	static update_model(login_token, model) {
		return axios.put(REST_POINT + '/sticker/' + model.identifier, model, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: res.status,
				account: res.data
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.response.error
			}
		})
	}

	/*
	Get all the images associated to an account
	You can specify page and limit
	 */
	static get_images(login_token, page, limit) {
		return axios.get(REST_POINT + '/image', {
			headers: {Authorization: login_token}
		}, {
			params: {
				page: page,
				limit: limit
			}
		}).then(res => {
			return {
				status: res.status,
				images: res.data.docs,
				page: res.data.page,
				pages: res.data.pages,
				total: res.data.total
			}
		}).catch(err => {
			if (err.response) {
				return {
					status: err.response.status,
					error: err.response.error
				}
			} else {
				return {
					status: Status.INTERNAL_SERVER_ERROR,
					error: "Server offline o in manutenzione"
				}
			}
		});
	}

	/*
	Reset password given a code
	204 is returned if code is valid
	 */
	static reset_password(code) {
		return axios.get(REST_POINT + '/account/reset/' + code)
			.then(res => {
				return {
					status: res.status
				}
			})
			.catch(err => {
				return {
					status: err.response.status,
					error: err.response.error
				}
			});
	}

	/*
	Increment print count of model (by identifier)
	204 is returned if increment was done correctly
	 */
	static increment_print_count(login_token, identifier) {
		return axios.put(REST_POINT + '/sticker/print/' + identifier, {
		}, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: res.status
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.response.error
			}
		});
	}

	/*
	Update user password
	 */
	static update_password(login_token, new_password) {
		return axios.put(REST_POINT + '/account/password', {
			password: new_password
		}, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: Status.OK
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.response.error
			}
		});
	}

	/*
	Get all news
	 */
	static get_news(login_token, page, limit) {
		return axios.get(REST_POINT + '/news?page=' + parseInt(page) + '&limit=' + parseInt(limit), {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: res.status,
				news: res.data.docs,
				total: res.data.total,
				limit: res.data.limit,
				page: res.data.page,
				pages: res.data.pages
			}
		}).catch(err => {
			return {
				status: err.response ? err.response.status : 500,
				error: err.response ? err.response.error : "Internal Server Error"
			}
		});
	}

	/*
	Add news
	 */
	static add_news(login_token, content, createdBy) {
		return axios.post(REST_POINT + '/news', {
			content: content,
			createdBy: createdBy
		}, {
			headers: {Authorization: login_token}
		}).then(res => {
			return {
				status: res.status,
				message: res.data.message
			}
		}).catch(err => {
			return {
				status: err.response.status,
				error: err.response.error
			}
		});
	}

	/*
	Remove news by id
	 */
	static remove_news(login_token, _id) {
		return axios.delete(REST_POINT + '/news/' + _id, {headers: {Authorization: login_token}})
			.then(res => {
				return {
					status: Status.OK,
					message: res.data.message
				}
			})
			.catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					return {
						status: error.response.status,
						error: error.response.data.message
					}
				} else if (error.request) {
					// No response from server
					return {
						status: Status.INTERNAL_SERVER_ERROR,
						error: 'Internal Server Error (500)'
					}
				} else {
					// Something happened in setting up the request that triggered an error
					return {
						status: Status.BAD_REQUEST,
						error: error.message,
					}
				}
			})
	}
}

import React from "react";
import {config} from "../config/config";
import {AdminComponent} from "../components/admin/AdminComponent";
import {removeLoginToken} from "./Login";
import {Icon, Navbar, NavItem} from "react-materialize";
import {DOMAIN_REMOTE} from "../index";
import ReactTooltip from "react-tooltip";
import {ToastContainer} from "react-toastify";
import { slide as Menu } from 'react-burger-menu'
import {StickerCreator} from "../components/admin/StickerCreator";
import {LabelStats} from "../components/admin/LabelStats";
import {StickerList} from "../components/admin/StickerList";
import {NewsManager} from "../components/admin/NewsManager";

export class AdminDashboard extends AdminComponent {

  constructor(props) {
    super(props);
    this.state = {
      wantsApp: false,
      currentComponent: (
        <React.Fragment>
          <StickerCreator/>
        </React.Fragment>
      )
    };
    this.showTab = this.showTab.bind(this);
  }

  showTab(tab) {
    this.setState({
      currentComponent: tab
    })
  }

  render() {
    return (
      <div>
        <Navbar href="" brand={
          <img className="logo" style={{maxHeight: 100, maxWidth: 100}} src={DOMAIN_REMOTE + config.app_logo} alt="AppTac Label Editor - Admin Dashboard"/>
        } right>
          <NavItem onClick={() => this.setState({wantsApp: true})}>Torna alla app</NavItem>
          <NavItem data-tip="Logout" data-for="admin-tooltip" href="" onClick={() => {
            localStorage.removeItem('username');
            removeLoginToken();
          }}>
            <Icon>exit_to_app</Icon>
          </NavItem>
        </Navbar>
        <Menu customBurgerIcon={false} itemListClassName="admin-menu background-sidebar"
              isOpen={true}
              onStateChange={(state) => {
                console.log(state);
              }}
              left noOverlay>
          <a onClick={(e) => {
            e.preventDefault();
            this.showTab(
              <React.Fragment>
                <StickerCreator/>
              </React.Fragment>
            )
          }} className="" href="">
            <b>Crea/Aggiorna Modelli</b>
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            this.showTab(
              <React.Fragment>
                <StickerList/>
              </React.Fragment>
            )
          }} className="" href="">
            <b>Gestisci Modelli</b>
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            this.showTab(
              <React.Fragment>
                <LabelStats/>
              </React.Fragment>
            )
          }} className="" href="">
            <b>Visualizza Statistiche</b>
          </a>
          <a onClick={(e) => {
            e.preventDefault();
            this.showTab(
              <React.Fragment>
                <NewsManager/>
              </React.Fragment>
            )
          }} className="" href="">
            <b>Gestisci Notizie</b>
          </a>
        </Menu>
        {
          this.state.currentComponent
        }
        <ReactTooltip id="admin-tooltip"/>
        <ToastContainer autoClose={8000}/>
      </div>
    );
  }
}

AdminDashboard.defaultProps = {};

AdminDashboard.propTypes = {};
import React  from 'react';
import { Kitchen } from "../FabricCanvas"
import {BaseEditor} from "./BaseEditor";
import {Button, Col, Dropdown, Icon, NavItem, Row} from "react-materialize";
import ReactTooltip from "react-tooltip";

export class ShapeEditor extends BaseEditor {
	constructor(props) {
		super(props, ['rect', 'circle', 'triangle'], ['icon']);
		this.createRectangle = this.createRectangle.bind(this);
		this.createCircle = this.createCircle.bind(this);
		this.createTriangle = this.createTriangle.bind(this);
		this.createLine = this.createLine.bind(this);
		this.renderOutlineControllers = this.renderOutlineControllers.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
	}

	createRectangle() {
		let rect = Kitchen.Shape().addRect(
			40, 40, '#F00', 0, 0
		);
		super.create();
		Kitchen.getCanvas().setActiveObject(rect);
	}

	createCircle() {
		let circle = Kitchen.Shape().addCircle(
			20, '#0F0'
		);
		super.create();
		Kitchen.getCanvas().setActiveObject(circle);
	}

	createTriangle() {
		let triangle = Kitchen.Shape().addTriangle(
			40, 40, '#00F'
		);
		super.create();
		Kitchen.getCanvas().setActiveObject(triangle);
	}

	createLine() {
		let line = Kitchen.Shape().addRect(
			40, 1, '#000', 0, 0
		);
		super.create();
		Kitchen.getCanvas().setActiveObject(line);
	}

	onPaste(pasta) {
		// Pasta is a reference to the copied object.
		// Do not add it. Add a clone of it
		pasta.clone(cloned => {
			cloned.left = pasta.left + 10;
			cloned.top = pasta.top + 10;
			Kitchen.Shape().addRaw(cloned);
		});
	}

	onDelete(deleted) {
		Kitchen.Shape().removeObject(deleted.get('id'));
	}

	renderShapeCreators() {
		return (
			<div className="shape-creators">
				<div className="default-editor bordable-default">
					<Dropdown trigger={
						<Button data-tip="Aggiungi" data-for="tooltip-sidebar" className='defaultcolor editor-button add-button' waves='light'>
							<Icon className="editor-icon">add</Icon>
						</Button>
					}>
						<NavItem href="" onClick={(e) => {e.preventDefault(); this.createRectangle()}}>Rettangolo</NavItem>
						<NavItem href="" onClick={(e) => {e.preventDefault(); this.createCircle()}}>Cerchio</NavItem>
						<NavItem href="" onClick={(e) => {e.preventDefault(); this.createTriangle()}}>Triangolo</NavItem>
						<NavItem href="" onClick={(e) => {e.preventDefault(); this.createLine()}}>Linea</NavItem>
					</Dropdown>
					{super.renderRemoveButton()}
					{super.renderCopyButton()}
					{super.renderPasteButton()}
					<ReactTooltip id="tooltip-sidebar"/>
				</div>
			</div>
		);
	}

	renderIcon(icon) {
		return (
			<span className="icon-span">
        <button className="btn waves-effect waves-light editor-button actioncolor"
                onClick={() => {
	                Kitchen.Shape().addIcon(document.getElementById(icon.id), '#000');
                }}>
          <svg style={{verticalAlign: 'middle'}} id={icon.id} width="22" height="22" viewBox="0 0 32 32">
            <g transform={icon.transform ? icon.transform : ""}>
            {
	            icon.paths.map((path, index) => {
		            return (
			            <path key={index} d={path}/>
		            )
	            })
            }
            </g>
          </svg>
        </button>
      </span>
		);
	}

	onRender() {
		let editingIds = [];
		this.state.editingObjects.forEach((obj) => { editingIds.push(obj.get('id'));  });
		let icons = require('../../icons.json');

		return (
			<div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide")}>
				<p className="header-sidebar"><Icon className="icon-sidebar">widgets</Icon> FORME</p>
				{super.renderCloseButton()}
				{super.listenForDelete()}
				{super.listenForDeselect()}
				{this.renderShapeCreators()}
				{
					this.state.editingObjects.length > 0 &&
					<div id="shape-controls">
						<Row className="bordable-default paddingbottom20 paddingtop10">
							<Col s={12}>
								{super.renderColorController(editingIds, "Colore Forma")}
							</Col>
						</Row>
						<Row className="bordable-default no-margin-row">
							<Col s={12}>
								{super.renderOutlineControllers(true)}
							</Col>
							<Col s={12}>
								{super.renderShadowController(editingIds)}
							</Col>
						</Row>
					</div>
				}
				<p className="header-sidebar"><Icon className="icon-sidebar">widgets</Icon> ICONE</p>
				<div className="icons-container">
					{Object.values(icons).map(item => this.renderIcon(item))}
				</div>
			</div>
		);
	}
}
import React, { Component } from 'react';
import {CustomColorPicker} from "./CustomColorPicker";
import {Kitchen} from "./FabricCanvas"
import {Button, Icon} from "react-materialize";
import {BaseEditor, getColorString} from "./editors/BaseEditor";
import {fabric} from 'fabric';

export class BackgroundColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: '#FFF'
    };
    this.renderCloseButton = this.renderCloseButton.bind(this);
    this.changeColor = this.changeColor.bind(this);
  }

  renderCloseButton() {
    return (
      <Button className="actioncolor editor-button closeicon-sidebar"
              onClick={() => this.props.closeSidebar()}><Icon>close</Icon></Button>
    )
  }

  changeColor(col) {
    let canvas = Kitchen.getCanvas(), fake_label = canvas.getObjectByAttribute('id', 'FAKE_LABEL');

    let label = canvas.getObjects()[0];
    if (label instanceof fabric.Image) {
      // If rect label is an image, I need to apply the colorify filter
      if (label.filters.length > 0) {
        label.filters = [];
      }
      let blend = new fabric.Image.filters.Blend({
        color: col,
        mode: 'multiply',
        alpha: 1
      });
      label.filters.push(blend);
      label.applyFilters(() => {
        Kitchen.getCanvas().trigger('color:modified');
        BaseEditor.renderCanvasAndUpdate();
      });
    } else {
      // If rect label is not an image, just change fill attribute of rect label
      label.fill = col;
    }

    if (fake_label) fake_label.stroke = col;

    this.setState({
      bgColor: col
    })
  }

  render() {
    return (
      <div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide")}>
        <p className="header-sidebar"><Icon className="icon-sidebar">format_color_fill</Icon> COLORE SFONDO</p>
        {this.renderCloseButton()}
        <CustomColorPicker label="Seleziona il colore" color={this.state.bgColor}
                           onColorChange={(col) => this.changeColor(getColorString(col))}/>
      </div>
    );
  }
}
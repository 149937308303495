import React, { Component } from 'react';
import KitchenSink from "../KitchenSink";
import {FAKE_OFFSET, JSON_Props, Labels} from "../routes/App";
import KeyHandler, { KEYDOWN } from 'react-key-handler';
import {updatePrintPreview} from "./editors/BaseEditor";
import {activeGrid, gridSize} from "./CanvasButtons";
import {fabric} from 'fabric';

export let Kitchen;
export let IS_TIMETRAVELING = false;
export let FLAG_TIMETRAVELING = (tt) => {
	IS_TIMETRAVELING = tt;
};
Array.prototype.insert = function(index, item) {
	this.splice(index, 0, item);
};

export let ZOOMED_COORDS = {};
export let DEFAULT_ZOOM = 1;
export const MAX_ZOOM = 20;
export const MIN_ZOOM = 0.4;

export class FabricCanvas extends Component {
	constructor(props) {
		super(props);
		this.canvas = null;
		this.state = {
			fakeWidth: -1,
			fakeHeight: -1,
			realWidth: -1,
			realHeight: -1,
			zoom: 1
		};
		this.undoRedoManager = {
			canvasState: [],
			canvasStateIndex: 0,
			maxSteps: 10
		};
		this.onDragOver = this.onDragOver.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.pushNewState = this.pushNewState.bind(this);
		this.undo = this.undo.bind(this);
		this.redo = this.redo.bind(this);
		this.handleZoom = this.handleZoom.bind(this);
		this.handleGrid = this.handleGrid.bind(this);
	}

	getCanvas() {
		return this.canvas;
	}

	static zoomCanvasMiddle(canvas, value) {
		canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), value);
	}

	handleZoom(e) {
		let evt = window.event || e; // old IE support
		let delta = Math.max(-1, Math.min(1, (evt.wheelDelta || -evt.detail)));
		FabricCanvas.zoomCanvasMiddle(this.canvas, delta < 0 ? this.canvas.getZoom() * 1.1 : this.canvas.getZoom() / 1.1);
		this.setState({zoom: this.canvas.getZoom()});
	}

	handleGrid(target) {
		// This function is always called while moving an object on the canvas
		const TOLERANCE = 8;

		this.canvas.forEachObject((obj, index) => {
			if (!obj)
				return;
			if (index === 0)
				return; // Skip rect label
			if (obj === target)
				return;
			if (obj.id === "FAKE_LABEL")
				return;

			let deltaLeft = Math.abs(obj.left - target.left);
			let deltaTop = Math.abs(obj.top - target.top);

			// Align horizontally
			if (deltaLeft <= TOLERANCE) {
				target.set({
					left: obj.left
				});
			}
			// Align vertically
			if (deltaTop <= TOLERANCE) {
				target.set({
					top: obj.top
				});
			}
		});
	}

	handleSnaplines(target, grid) {
		if (Math.round(target.left / grid * 4) % 4 === 0 &&
			Math.round(target.top / grid * 4) % 4 === 0)
		{
			target.set({
				left: Math.round(target.left / grid) * grid,
				top: Math.round(target.top / grid) * grid
			}).setCoords();
		}
	}

	onDragOver(e) {
		e.preventDefault();
	}

	onDrop(e) {
		e.preventDefault();
		/*this.canvas.trigger('drop', {
			target: e.target
		})*/
	}

	pushNewState() {
		if (IS_TIMETRAVELING) return;
		let newLength = this.undoRedoManager.canvasState.insert(this.undoRedoManager.canvasStateIndex, JSON.stringify(this.canvas.toJSON(JSON_Props)));
		if (newLength > this.undoRedoManager.maxSteps) this.undoRedoManager.canvasState.pop();

		// Every time I push a new state in the queue, it means I did change the canvas.
		// This means that now I have things to save!
		window.warnUnsaved = true;
	}

	undo() {
		FLAG_TIMETRAVELING(true);
		let c = this.canvas;
		let oid = this.canvas.getSelection() ? this.canvas.getSelection().id : null;
		if (this.undoRedoManager.canvasStateIndex < this.undoRedoManager.canvasState.length - 1)
			this.undoRedoManager.canvasStateIndex++; // Go back in time
		c.loadFromJSON(this.undoRedoManager.canvasState[this.undoRedoManager.canvasStateIndex], () => {
			if (oid) {
				let obj = c.getObjectByAttribute('id', oid);
				if (obj) c.setActiveObject(obj);
			}
			updatePrintPreview();
			c.renderAll();
			FLAG_TIMETRAVELING(false);
		});
	}

	redo() {
		FLAG_TIMETRAVELING(true);
		let c = this.canvas;
		let oid = this.canvas.getSelection() ? this.canvas.getSelection().id : null;
		if (this.undoRedoManager.canvasStateIndex > 0)
			this.undoRedoManager.canvasStateIndex--; // Go past in time
		c.loadFromJSON(this.undoRedoManager.canvasState[this.undoRedoManager.canvasStateIndex], () => {
			if (oid) {
				let obj = c.getObjectByAttribute('id', oid);
				if (obj) c.setActiveObject(obj);
			}
			updatePrintPreview();
			c.renderAll();
			FLAG_TIMETRAVELING(false);
		})
	}

	componentDidMount() {
		// Create the canvas itself
		this.canvas = new fabric.Canvas('fabric-canvas', {
			width: window.innerWidth / 2,
			height: window.innerHeight - 100,
			backgroundColor: 'rgba(0,0,0,0.2)'
		});
		let upper_canvas = document.querySelector('.upper-canvas');
		upper_canvas.ondrop = this.onDrop;
		upper_canvas.ondragover = this.onDragOver;

		// Create the kitchen object
		Kitchen = new KitchenSink(this.canvas, null);

		this.canvas.selection = false; // disable group selection
	}

	listenMouseWheel() {
		// Start listening for mouse wheel
		let self = this;
		let mwevt =(/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";
		document.addEventListener(mwevt, (e) => {
			if (e.ctrlKey) {
				e.preventDefault();
				self.handleZoom(e);
			}
		});
		document.onkeypress = (e) => {
			// Ensure event is not null
			e = e || window.event;

			if ((e.which === 26 || e.keyCode === 26) && e.ctrlKey && !e.shiftKey) {
				// UNDO
				self.undo();
			}
			if ((e.which === 26 || e.keyCode === 26) && e.ctrlKey && e.shiftKey) {
				// REDO
				self.redo();
			}
		}
	}

	createCanvas(label_object, border_radius) {
		const isLabelRect = label_object instanceof fabric.Rect;
		const isLabelCircle = label_object instanceof fabric.Circle;
		const isLabelImage = label_object instanceof fabric.Image;

		// Create the label based on label_object
		if (this.canvas.getObjects().length > 0) {
			// Remove first item (always rectLabel) to avoid overlapping
			this.canvas.clear();
			this.canvas.renderAll();
		}
		this.label = label_object;
		if (isLabelRect)
		{
			this.label.set({
				width: this.state.realWidth,
				height: this.state.realHeight,
				fill: '#FFF',
				rx: border_radius,
				ry: border_radius
			});
		}
		if (isLabelImage) {
			// Breaking change in Fabric v2!
			this.label.scaleToWidth(this.state.realWidth, true);
			this.label.scaleToHeight(this.state.realHeight, true);
		}
		if (isLabelCircle) {
			this.label.set({
				originX: 'center',
				originY: 'center',
				radius: this.state.realWidth / 2 - 3
			});
		}
		this.label.set({
			selectable: false,
			evented: false
		});
		this.canvas.centerObject(this.label);
		this.canvas.add(this.label);
		Kitchen.updateRectLabel(this.label);
		if (!isLabelImage) {
			this.label.clone((clone) => {
				this.fakeLabel = clone;
				clone.set({
					id: 'FAKE_LABEL',
					width: this.state.fakeWidth,
					height: this.state.fakeHeight,
					stroke: this.label.get('fill'),
					strokeWidth: FAKE_OFFSET,
					hasBorder: true,
					fill: 'transparent',
					evented: false,
					selectable: false
				});
				if (label_object instanceof fabric.Circle) {
					this.fakeLabel.set('radius', this.label.get('radius'));
				}
				this.canvas.add(this.fakeLabel);
				this.canvas.centerObject(this.fakeLabel);
			});
		}
		let json = this.canvas.toJSON(JSON_Props);
		Labels.forEach((row) => {
			row.forEach((label) => {
				label.json = json;
			});
		});
		this.pushNewState();
		// Register update listeners
		this.canvas.on({
			'object:added': () => {
				this.pushNewState();
			},
			'object:removed': () => {
				this.pushNewState();
			},
			'color:modified': () => {
				this.pushNewState();
			},
			'object:modified': () => {
				this.pushNewState();
				updatePrintPreview();
			},
			'text:editing:exited': () => {
				this.pushNewState();
				updatePrintPreview();
			},
			'object:moving': (e) => {
				this.handleGrid(e.target);
				if (activeGrid) {
					this.handleSnaplines(e.target, gridSize);
				}
			}
		});
		let rl = this.label, canvas = this.canvas;
		let zoomedCoords = {
			left: rl.get('left'),
			top: rl.get('top'),
			width: isLabelImage ? rl.scaleX * rl.width : rl.width,
			height: isLabelImage ? rl.scaleY * rl.height : rl.height
		};
		/* THIS IS THE PIECE OF CODE THAT MADE THE APP WORK ON EVERY SINGLE RESOLUTION 1 DAY BEFORE OFFICIAL RELEASE */
		let adjustZoom = function(canvas) {
			let zoom = canvas.getZoom();
			let origPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);
			canvas.zoomToPoint(origPoint, zoom / 1.1);
			return rl.getBoundingRect();
		};
		while (zoomedCoords.left < 0) zoomedCoords = adjustZoom(canvas);
		while (zoomedCoords.top < 0) zoomedCoords = adjustZoom(canvas);
		/* PRAISE THIS CODE */
		ZOOMED_COORDS = zoomedCoords;
		DEFAULT_ZOOM = canvas.getZoom();
		this.canvas.renderAll();
	}

	render() {
		const SHIFT = 2;
		return (
			<React.Fragment>
				<KeyHandler keyEventName={KEYDOWN} keyValue="ArrowUp" onKeyHandle={(e) => {
					let active = Kitchen.getCanvas().getActiveObject();
					if (active) {
						e.preventDefault();
						active.set({
							top: active.top - SHIFT
						});
						active.setCoords();
						Kitchen.getCanvas().renderAll();
						updatePrintPreview();
					}
				}}/>
				<KeyHandler keyEventName={KEYDOWN} keyValue="ArrowDown" onKeyHandle={(e) => {
					let active = Kitchen.getCanvas().getActiveObject();
					if (active) {
						e.preventDefault();
						active.set({
							top: active.top + SHIFT
						});
						active.setCoords();
						Kitchen.getCanvas().renderAll();
						updatePrintPreview();
					}
				}}/>
				<KeyHandler keyEventName={KEYDOWN} keyValue="ArrowRight" onKeyHandle={(e) => {
					let active = Kitchen.getCanvas().getActiveObject();
					if (active) {
						e.preventDefault();
						active.set({
							left: active.left + SHIFT
						});
						active.setCoords();
						Kitchen.getCanvas().renderAll();
						updatePrintPreview();
					}
				}}/>
				<KeyHandler keyEventName={KEYDOWN} keyValue="ArrowLeft" onKeyHandle={(e) => {
					let active = Kitchen.getCanvas().getActiveObject();
					if (active) {
						e.preventDefault();
						active.set({
							left: active.left - SHIFT
						});
						active.setCoords();
						Kitchen.getCanvas().renderAll();
						updatePrintPreview();
					}
				}}/>
			</React.Fragment>
		);
	}
}

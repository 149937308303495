import React, { Component } from 'react';
import PropTypes from "prop-types";
import {BlockPicker} from "react-color";
import {BaseEditor, getColorHex} from "./editors/BaseEditor";
import {Kitchen} from "./FabricCanvas";

export const COLOR_PALETTE = [
  '#000000',
  '#FFFFFF',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#FF8000',
  '#D9E3F0',
  '#F47373',
  '#a11f42',
  '#40150a',
  '#697689',
  '#52c036',
  '#37D67A',
  '#2CCCE4',
  '#555555',
  '#f5f9bc',
  '#dce775',
  '#ff8a65',
  '#ba68c8',
  '#7c32b5',
  '#6bffe2',
  '#3c63e2',
  '#C0C0C0'
];

export class CustomColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      editingColor: false
    }
  }

  render() {
    return (
      <div className="margintop10">
        <label className="color-picker-label">{this.props.label}</label>
        <table className="tabletextcolor">
          <tbody>
          <tr>
            <td>
              <div style={{
                width: 30,
                height: 30,
                border: '1px solid',
                borderColor: '#FFF',
                backgroundColor: this.props.color,
                cursor: 'pointer'
              }} onClick={() => {
                this.setState({
                  editingColor: !this.state.editingColor
                });
              }}/>
            </td>
            <td>
              <label className="colorpicker-colorlabel labeltextcolor">{getColorHex(this.state.color).toUpperCase()}</label>
            </td>
          </tr>
          </tbody>
        </table>
        {
          this.state.editingColor &&
            <BlockPicker
              color={this.props.color} onChange={(color) => {
                this.setState({
                  color: color.hex,
                  editingColor: false
                });
                this.props.onColorChange(color.rgb);
                Kitchen.getCanvas().trigger('color:modified');
                BaseEditor.renderCanvasAndUpdate();
            }} colors={COLOR_PALETTE}/>
        }
      </div>
    );
  }
}

CustomColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired
};